import { th } from "date-fns/locale";
import jwtDecode from "jwt-decode";

const messages = [
    "High demand! Try again soon.",
    "Busy servers! We'll be back shortly.",
    "Slow service today? We're on it!",
    "Refresh soon! We're managing a surge.",
    "Lots of requests! Thanks for your patience.",
    "Delays! We're working hard, bear with us.",
    "High demand! We're got this, try again soon.",
    "Heavy load! We'll be back to speed soon.",
    "High demand = longer wait. Thanks for understanding!",
    "Running a bit slow! We appreciate your patience."
];
// check need to be implemented response.status > 299 && response.status < 200
// Fetch all the clients from the API
export const onboardingStatus = async (id) => {
    try {
        const response = await fetch(
            `https://integrations.uwc.world/users/${id}/onboarding_status`,
            {
                method: "GET",
                headers: {
                    "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
                    "Content-Type": "application/json",
                    DeviceType: "web",
                    DeviceId: "123",
                    Lat: "1",
                    Long: "1",
                },
            }
        );
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // console.log("onboardingStatus", data);

        if (data?.code === 200) {
            return {
                ResponseCode: 200,
                ResponseMessage: "",
                status: data?.data?.status,
            };
        } else {
            throw new Error(data?.ResponseMessage);
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return {
            ResponseCode: 500,
            ResponseMessage: "Falied to validate onboarding status",
        };
    }
};

export const onboardingTemplate = async (id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_US_BASE_URL}new-uwc/uwc_expert/copilot/get-sub-category?professional_id=${id}`,
            {
                method: "GET",
                headers: {
                    "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
                    "Content-Type": "application/json",
                    DeviceType: "web",
                    DeviceId: "123",
                    Lat: "1",
                    Long: "1",
                },
            }
        );
        if (!response.ok) {
            throw new Error("Failed to fetch onboarding template");
        }
        const data = await response.json();
        // console.log("onboardingStatus", data);

        if (data?.ResponseCode === 200) {
            return {
                ResponseCode: 200,
                ResponseMessage: "",
                template: data?.data?.fields,
                tabs: data?.data?.sections
            };
        } else {
            throw new Error(data?.ResponseMessage);
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return {
            ResponseCode: 500,
            ResponseMessage: "Falied to retrive onboarding template",
        };
    }
};
export const FetchClinicalNotesClients = async (professional_id, session, page) => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/list?professional_id=${professional_id}&status=${session}&count=8&page=${page || 1}`,
            {
                method: "GET",
                headers: {
                    "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
                    "Content-Type": "application/json",
                    token: token,
                },
            }
        );
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }

        const resp = await response.json();

        if (resp.status === "success") {
            return resp;
        } else {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }

    } catch (error) {
        console.error(error);
    }
};
export const FetchArchivedClinicalNotesClients = async (professional_id, session, page) => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/list-archived?professional_id=${professional_id}&status=${session}&count=8&page=${page || 1}`,
            {
                method: "GET",
                headers: {
                    "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
                    "Content-Type": "application/json",
                    token: token,
                },
            }
        );
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }

        const resp = await response.json();
        if (resp.status === "success") {
            return resp;
        } else {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
    } catch (error) {
        console.error(error);
    }
};
// Fetch info of the client from the API
export const FetchClinicalNotesClientDetails = async (appointmentIDfromAPI) => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/get-user?clinical_note_id=${appointmentIDfromAPI}`,
            {
                method: "GET",
                headers: {
                    accept: "application/json",
                    token: token,
                },
            }
        );

        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }

        const resp = await response.json();
        if (resp.status === "success") {
            return resp.data;
        } else {
            return { error: true, message: resp.message };
        }

    } catch (error) {
        console.error(error);
    }
};

// Delete the client from the API
export const DeleteClinicalNotesClient = async (data) => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/delete?clinical_note_id=${data}`,
            {
                method: "DELETE",
                headers: {
                    "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
                    "Content-Type": "application/json",
                    token: token,
                },
            }
        );
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        const resp = await response.json();
        return resp.data;
    } catch (error) {
        console.error(error);
    }
};

// Insert the session data on save 
export const InsertRagSessionData = async (body) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_RAG_BASE_URL}insert-session-doc`,
            {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: body,
            }
        );
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        const resp = await response.json();
        return resp;
    } catch (error) {
        console.error(error);
    }
};

// Insert the final session data
export const InsertRagFinalSessionData = async (body) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_RAG_BASE_URL}insert-final-doc`,
            {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: body,
            }
        );
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        const resp = await response.json();
        return resp;
    } catch (error) {
        console.error(error);
    }
};

// Insert the session data on save
export const InsertRagContext = async ({ appointment_id, user_id, expert_id, appointment_date, booking_date, transcript }) => {
    const body = {
        text: transcript,
        header: {
            session_id: `${appointment_id}`,
            appointment_id: `${appointment_id}`,
            user_id: `${user_id}`,
            expert_id: `${expert_id}`,
            appointment_date: `${appointment_date}`,
            booking_date: `${booking_date}`,
        }
    };

    try {
        const response = await fetch(
            `${process.env.REACT_APP_RAG_BASE_URL}insert-into-context`,
            {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body)
            }
        );
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        const resp = await response.json();
        return resp;
    } catch (error) {
        console.error(error);
    }
};

export const AppendToContext = async ({
    transcript,
    session_id,
    appointment_id,
    user_id,
    expert_id,
    appointment_date,
    booking_date,
    ordered_fields_json
}) => {
    const body = {
        text: transcript,
        session_id: `${session_id}`,
        appointment_id: `${appointment_id}`,
        user_id: `${user_id}`,
        expert_id: `${expert_id}`,
        appointment_date: `${appointment_date}`,
        booking_date: `${booking_date}`,
        ordered_fields_json: ordered_fields_json
    };
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_OCR_URL}append-to-context-v4`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': process.env.REACT_APP_ON_APPEND_X_API_KEY,
                },
                body: JSON.stringify(body),
            }
        );
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        const resp = await response.json();
        return resp;
    } catch (error) {
        console.error(error);
    }
};
export const AppendToStella = async ({
    text,
    session_id,
    appointment_id,
    user_id,
    expert_id,
    appointment_date,
    booking_date,
}) => {
    const body = {
        text: "",
        session_id: `${session_id}`,
        appointment_id: `${appointment_id}`,
        user_id: `${user_id}`,
        expert_id: `${expert_id}`,
        appointment_date: `${appointment_date}`,
        booking_date: `${booking_date}`,
    };
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_OCR_URL}append-to-stella-v2`,
            {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "content-type": "application/json",
                    token: token,
                },
                body: JSON.stringify(body),
            }
        );
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        const resp = await response.json();
        return resp;
    } catch (error) {
        console.error(error);
    }
};

export const FetchSummaryFromRAG = async (user_Id, session_Id) => {
    const payload = {
        user_id: user_Id,
        classify: "therapist",
        time_stamp: "string",
        headers: {
            user_id: user_Id,
            session_id: session_Id,
            registered_id: "string",
        },
        max_tokens: 512,
        temperature: 0,
    };
    try {
        const response = await fetch(
            `${process.env.REACT_APP_RAG_BASE_URL}gen-summary`,
            {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            }
        );
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        const resp = await response.json();
        return resp.response;
    } catch (error) {
        console.error(error);
    }
};

export const FetchFollowUpNote = async (user_Id, limit) => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/followup-summary?client_id=${user_Id}&limit=${limit}`,
            {
                method: "GET",
                headers: {
                    accept: "application/json",
                    token: token,
                },
            }
        );
        const resp = await response.json();
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        if (resp.status === "success") {
            return resp.data;
        } else {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }


    } catch (error) {
        console.error(error);
    }
};

export const FetchRecommendedExpertList = async (Profession, limit, country) => {

    const requestBody = {
        Country: country,
        q: null,
        Profession: Profession,
        Experience: null,
        MinPrice: 0,
        MaxPrice: 20000,
        PageNumber: 0,
        RowsOfPage: limit,
        Category: null,
    };
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_LIST_EXPERTS}popupservices/getExpertListWithFilter_Web_V3_Next_Appointment`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    deviceid: "123",
                    devicetype: "web",
                    lat: "1",
                    long: "1",
                },
                body: JSON.stringify(requestBody), // Convert the JS object to a JSON string
            }
        );

        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        const resp = await response.json();
        const list = jwtDecode(resp.data);
        return list;
    } catch (error) {
        console.error(error);
    }
};

export const FetchRecommendedProgramList = async (program, limit) => {
    const requestBody = {
        price: false,
        clubs: [program],
        user_id: null,
        version: "v3",
    };
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;

    try {
        const response = await fetch(
            `${process.env.REACT_APP_LIST_PROGRAMS}new-uwc/community/course/web_list_v2`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
                },
                body: JSON.stringify(requestBody), // Convert the JS object to a JSON string
            }
        );

        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        const resp = await response.json();
        const list = resp.data.courses;
        return list;
    } catch (error) {
        console.error("Fetching experts list failed:", error);
    }
};

export const FetchRecommendedAssessmentList = async () => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_LIST_PROGRAMS}new-uwc/list/test`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
                },
            }
        );

        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }

        const resp = await response.json();
        const list = resp.data.testList;
        return list;
    } catch (error) {
        console.error("Fetching assessment list failed:", error);
    }
};

export const FetchRecommendedContentList = async (content) => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_LIST_PROGRAMS}new-uwc/community/public/feed?post-type=${content}&count=40&order=id%20&page=0`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
                },
            }
        );

        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }

        const resp = await response.json();
        const list = resp?.data?.feed;
        return list;
    } catch (error) {
        console.error("Fetching assessment list failed:", error);
    }
};

export const FetchRecommendedExpertHistoryList = async (session_id) => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/history?json_path=session_data.session_data.recommendation.recommended_expert&limit=30&current_session_id=${session_id}`,
            {
                method: "POST",
                headers: {
                    accept: "application/json",
                    token: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({}),
            }
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const resp = await response.json();
        const list = resp?.data;
        return list;
    } catch (error) {
        console.error("Fetching assessment list failed:", error);
        throw error;
    }
};

export const FetchRecommendedProgramHistoryList = async (session_id) => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/history?json_path=session_data.session_data.recommendation.recommended_program&limit=30&current_session_id=${session_id}`,
            {
                method: "POST",
                headers: {
                    accept: "application/json",
                    token: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({}),
            }
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }


        const resp = await response.json();
        const list = resp?.data;
        return list;
    } catch (error) {
        console.error("Fetching assessment list failed:", error);
        throw error;
    }
};

export const FetchRecommendedAssessmentHistoryList = async (session_id) => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/history?json_path=session_data.session_data.recommendation.recommended_assessment&limit=30&current_session_id=${session_id}`,
            {
                method: "POST",
                headers: {
                    accept: "application/json",
                    token: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({}),
            }
        );
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const resp = await response.json();
        const list = resp?.data;
        return list;
    } catch (error) {

        console.error("Fetching assessment list failed:", error);
        throw error;
    }
};

export const FetchRecommendedHistoryContentList = async (session_id) => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/history?json_path=session_data.session_data.recommendation.recommended_content&limit=30&current_session_id=${session_id}`,
            {
                method: "POST",
                headers: {
                    accept: "application/json",
                    token: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({}),
            }
        );
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const resp = await response.json();
        const list = resp?.data;
        return list;
    } catch (error) {
        console.error("Fetching assessment list failed:", error);
        throw error;
    }
};

export const FetchRecommendedHistoryAdviseList = async (session_id) => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/history?json_path=session_data.session_data.recommendation.advise&limit=30&current_session_id=${session_id}`,
            {
                method: "POST",
                headers: {
                    accept: "application/json",
                    token: token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({}),
            }
        );
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const resp = await response.json();
        const list = resp?.data;
        return list;
    } catch (error) {
        console.error("Fetching assessment list failed:", error);
        throw error;
    }
};

export const getRecommendedLabTest = async (searchText) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}search/labtests?query=${searchText}`,
            {
                method: "GET",
                headers: {
                    "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        const postResponse = await response.json();
        return postResponse;
    } catch (error) {
        console.error("Error:", error);
    }
};

export const fetchRecommendedMedicines = async (query) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}search/drugs_brands?query=${query}`,
            {
                method: "GET",
                headers: {
                    accept: "application/json",
                },
            }
        );
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
    }
};

export const autocompleteLabTest = async (searchQuery) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}search/labtests?query=${searchQuery}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        return response.json();
    } catch (error) {
        console.error(error);

    }
};

export const LAMA_OCR_Document = async (firebaseUrl) => {
    const body = {
        image_url: firebaseUrl,
        lmm_prompt: "extract the details",
        llm_prompt:
            "these are the contents of a medical file uploaded by a patient. Can you provide me the comprehensive medical description.",
        max_tokens: 4000,
        temperature: 0,
    };
    try {
        const response = await fetch(process.env.REACT_APP_LAMA_OCR_URL, {
            method: "POST",
            headers: {
                Authorization: "Basic " + process.env.REACT_APP_LAMA_OCR_TOKEN,
                accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        return response.json();
    } catch (error) {
        console.error(error);

    }
};

export const HandleBlurForAssistant = async (body) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_DRUG_LABS_BASE_URL}drugs-labs-disease`, {
            method: "POST",
            headers: {
                "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        if (!response.ok) {
            return { error: true, message: messages[Math.floor(Math.random() * messages.length)] };
        }
        const postResponse = await response.json();
        return postResponse;
    } catch (error) {
        console.error("Fetch Error:", error);
    }
}

export const FetchGraphInitialData = async ({ user_id, session_id, labtest, drugs, assistant, user_summary }) => {
    try {
        ;
        const requestBody = JSON.stringify({
            user_id: user_id,
            session_id: session_id,
            labtest: labtest,
            drugs: drugs,
            assistant: assistant,
            user_summary: user_summary
        });
        const response = await fetch(
            "https://neograph.uwc.world/drugs-interaction/graph-fe-user-year",
            {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: requestBody,
            }
        );
        if (response.status === 200) {
            const resp = await response.json();
            console.log(resp);
            const uniqueNodes = [];
            const nodeMap = {};

            resp.nodes.forEach((node) => {
                if (!nodeMap[node.id]) {
                    nodeMap[node.id] = true;
                    uniqueNodes.push(node);
                }
            });

            return (
                {
                    graph: { ...resp, nodes: uniqueNodes },
                    ok: true,
                    message: "The graph data is available for this client."
                }
            );
        } else {
            return { ok: false, message: "The graph data is not available for this client." };
        }
    } catch (error) {
        console.log(error);
    }
}

export const FetchGraphChildrenData = async (nodeId) => {
    try {
        const requestBody = JSON.stringify({
            node_id: nodeId,
            limit: 20,
        });

        const response = await fetch(
            "https://neograph.uwc.world/drugs-interaction/graph-fe-nodes",
            {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: requestBody,
            }
        );
        if (!response.ok) {
            return [];
        }
        const resp = await response.json();
        console.log(resp);
        if (resp.detail === "") {
            return [];
        }

        return resp.nodes;
    } catch (error) {
        console.log(error);
    }
}


export const FetchAuditLog = async (session_id) => {
    const storedData = localStorage.getItem("Authorization");
    const token = storedData ? storedData?.split("Bearer ")[1] : null;
    try {
        const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/audit-logs?clinical_note_id=${session_id}`,
            {
                method: "GET",
                headers: {
                    accept: "application/json",
                    token: token,
                    "Content-Type": "application/json",
                }
            }
        );
        if (!response.ok) {
            return { data: [] }
        }

        const resp = await response.json();
        if (resp.status === "success") {


            return { data: resp.data };
        } else {
            return { data: [] }
        }

    } catch (error) {
        console.error("Fetching assessment list failed:", error);
        return { data: [] }
    }
};