import React from "react";

// MUI
import { Skeleton } from "@mui/material";

const NotesSkeltonLoader = () => {
  return (
    <div
      style={{
        // height: "380px",
        backgroundColor: "#ffffff",
      }}
      className="rounded rounded-3 w-100 "
    >
      <Skeleton
        variant="rounded"
        height={50}
        className="rounded rounded-3 mb-4"
      />
      <Skeleton
        variant="rounded"
        height={20}
        style={{ width: "50%" }}
        className="rounded rounded-3 my-3 "
      />
      <Skeleton
        variant="rounded"
        height={20}
        style={{ width: "90%" }}
        className="rounded rounded-3 my-3"
      />
      <Skeleton
        variant="rounded"
        height={20}
        style={{ width: "75%" }}
        className="rounded rounded-3 my-3"
      />
      <Skeleton
        variant="rounded"
        height={20}
        style={{ width: "80%" }}
        className="rounded rounded-3 my-3"
      />
      <Skeleton
        variant="rounded"
        height={20}
        className="rounded rounded-3 my-3"
      />
       <Skeleton
        variant="rounded"
        height={20}
        style={{ width: "80%" }}
        className="rounded rounded-3 my-3"
      />
        <Skeleton
        variant="rounded"
        height={20}
        className="rounded rounded-3 my-3"
      />
    </div>
  );
};

export default NotesSkeltonLoader;
