import React from 'react'
import { Skeleton } from '@mui/material'

function FollowUpdateSkeleton() {
  return (
    <div className="Table--Body gap-3 my-2">
    <div className="Table-span-3">
      <Skeleton
        variant="rounded"
        width="100%"
        height="56px"
        className="rounded-3"
      />
    </div>
    <div className="Table-span-3">
      <Skeleton
        variant="rounded"
        width="100%"
        height="56px"
        className="rounded-3"
      />
    </div>
    <div className="Table-span-6">
      <Skeleton
        variant="rounded"
        width="100%"
        height="56px"
        className="rounded-3"
      />
    </div>
  </div>
  )
}

export default FollowUpdateSkeleton