import { WarningAmberRounded } from "@mui/icons-material";
import { Button, Dialog, DialogContent } from "@mui/material";
import React from "react";
import CautionContent from "../component/CautionContent";

function DisclaimerModal({ disclaimer, close, handleAccept }) {
  return (
    <Dialog
      className="disclaimer-modal clinicalDashboard--scroll"
      open={disclaimer}
      onClose={close}
    >
      <DialogContent className="p-md-4 p-2 py-4 pt-0">
        <div
          style={{ background: "white" }}
          className="py-3 sticky-top  d-flex flex-column align-items-center justify-content-center"
        >
          <h2 className="d-flex align-items-center gap-2">
            <WarningAmberRounded sx={{ color: "#FDB022", fontSize: "40px" }} />
            Caution
          </h2>
        </div>
        <p className=" text-muted ">
          Please review the following important points before proceeding:
        </p>
        <CautionContent />
      </DialogContent>
      <div className="sticky-bottom border-top border-2 p-3 p-md-4 py-md-2 d-flex justify-content-center justify-content-lg-end">
        <Button
          onClick={handleAccept}
          variant="outlined"
          // sx={{ color: "#73589B", borderColor: "#73589B", fontWeight: "600",fontSize: "16px", }}
          sx={{
            fontWeight: "600",
            fontSize: "16px",
          }}
          className=" text-capitalize PrimaryCTA--Outlined-success  "
        >
          I Understand and Accept the Cautions
        </Button>
      </div>
    </Dialog>
  );
}

export default DisclaimerModal;
