import React from 'react'

// MUI
import { Skeleton } from '@mui/material'
function HistoricalSessionNotesSkeleton() {
  return (
    <div
          className="p-4 d-flex flex-column gap-2 mb-2"
          style={{ backgroundColor: "rgb(0,0,0,0.07)" }}
        >
          <Skeleton
            variant="rounded"
            height="56px"
            className="rounded rounded-3"
          />
          {Array.from(new Array(2)).map((_, index) => (
            <div className="d-flex gap-1">
              <Skeleton
                variant="rounded"
                height="30px"
                width="25%"
                className="rounded rounded-3"
              />
              <Skeleton
                variant="rounded"
                height="30px"
                width="25%"
                className="rounded rounded-3"
              />
              <Skeleton
                variant="rounded"
                height="30px"
                width="50%"
                className="rounded rounded-3"
              />
            </div>
          ))}
        </div>
  )
}

export default HistoricalSessionNotesSkeleton