import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { TextField } from "@mui/material";
import UserInfoPanel from "../clinicalNotesClinicalRoute/Components/UserInfoPanel";
import TextEditor from "../clinicalNotesClinicalRoute/Components/TextEditor";


const ClientDetailsViewMoreModal = ({ isOpen, onClose, rowData }) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [tableRows, setTableRows] = useState([
    {
      drug: "",
      dosage: "",
      form: "",
      route: "",
      frequency: "",
      strength: "",
      duration: "",
    },
  ]);
  const [tableRowsLab, setTableRowsLab] = useState([
    {
      test_name: "",
      sample_requirement: "",
      storage_transport_temperature: "",
      acceptable_alternative_specimen: "",
      other_requirements: "",
      causes_for_rejection: "",
      time_to_result: "",
    },
  ]);
  const [showTextField, setShowTextField] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  if (!isOpen) return null;

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setShowTextField(option === "Yes");
  };

  return (
    <>
      <Dialog
        className="session-details-dialog "
        open={handleClickOpen}
        onClose={onClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"

      >
        <DialogTitle style={{ borderBottom: "none" }}>
          Historical Session Notes {" > "} Session Detail
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <UserInfoPanel />
          </DialogContentText>

          <DialogContentText>
            <div
              className="SessionPrescription--Table p-2"
            >
              <div className="Table--Head gap-2">
                <div className="Table-span-3 fw-semibold">Medicine Name</div>
                <div className="Table-span-1 fw-semibold">Dose</div>
                <div className="Table-span-2 fw-semibold">Frequency</div>
                <div className="Table-span-2 fw-semibold">Taken with</div>
                <div className="Table-span-2 fw-semibold">Route</div>
                <div className="Table-span-2 fw-semibold">Instructions</div>
              </div>
              {tableRows &&
                tableRows.map((row, index) => (
                  <div className="Table--Body gap-2 mt-2" key={index}>
                    {rowData?.prescription?.medicines?.prescribed_medicines.map(
                      (med, index) => (
                        <>
                          <div className="Table-span-3">
                            <TextField
                              fullWidth
                              label=""
                              value={med?.drug == "None" ? "" : med?.drug}
                            />
                          </div>
                          <div className="Table-span-1">
                            <TextField
                              fullWidth
                              label=""
                              value={med?.dosage == "None" ? "" : med?.dodage}
                            />
                          </div>
                          <div className="Table-span-2">
                            <TextField
                              fullWidth
                              label=""
                              value={
                                med?.frequency == "None" ? "" : med?.frequency
                              }
                            />
                          </div>
                          <div className="Table-span-2">
                            <TextField
                              fullWidth
                              label=""
                              value={
                                med?.strength == "None" ? "" : med?.strength
                              }
                            />
                          </div>
                          <div className="Table-span-2">
                            <TextField
                              fullWidth
                              label=""
                              value={med?.route == "None" ? "" : med?.route}
                            />
                          </div>
                          <div className="Table-span-2 d-flex align-items-center gap-1">
                            <TextField
                              fullWidth
                              label=""
                              value={
                                med?.duration == "None" ? "" : med?.duration
                              }
                            />
                          </div>
                        </>
                      )
                    )}
                  </div>
                ))}
            </div>
          </DialogContentText>

          <DialogContentText>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div className="p-2">
                  <h6 className="font-inter font-w600 lh-lg">
                    Chief Complaints
                  </h6>
                  <TextEditor
                    hideToolbar={true}
                    initialText={rowData?.prescription?.chief_complaints?.blob}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="p-2">
                  <h6 className="font-inter font-w600 lh-lg">Vitals</h6>
                  <TextEditor
                    hideToolbar={true}
                    initialText={rowData?.prescription?.vitals?.blob}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="p-2">
                  <h6 className="font-inter font-w600 lh-lg">Diagnosis</h6>
                  <TextEditor
                    hideToolbar={true}
                    initialText={rowData?.prescription?.diagnosis?.blob}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContentText>

          <DialogContentText>
            <div
              className="SessionLabAdvice--Table p-2 mt-4"
            >
              <div
                style={{ gridTemplateColumns: "repeat(7, minmax(0, 1fr))" }}
                className="Table--Body gap-3 mt-2"
              >
                <div className="fw-semibold">Lab Test</div>
                <div className="fw-semibold">Sample Requirement</div>
                <div className="fw-semibold">Storage Temperature</div>
                <div className="fw-semibold">Alternative Specimen</div>
                <div className="fw-semibold">Other Requirements</div>
                <div className="fw-semibold">Causes for Rrejection</div>
                <div className="fw-semibold">Time to result</div>
              </div>

              {tableRowsLab &&
                tableRowsLab.map((row, index) => {
                  return (
                    <div
                      style={{
                        gridTemplateColumns: "repeat(7, minmax(0, 1fr))",
                      }}
                      className="Table--Body gap-2 mt-2"
                      key={index}
                    >
                      {rowData?.lab_test?.prescribed_lab_test?.map(
                        (data, index) => (
                          <>
                            <div className="Table-span-1">
                              <TextField
                                fullWidth
                                label=""
                                value={data?.test_name}
                              />
                            </div>
                            <div className="Table-span-1  ">
                              <TextField
                                fullWidth
                                label=""
                                value={data?.sample_requirement}
                              />
                            </div>
                            <div className="Table-span-1 ">
                              <TextField
                                fullWidth
                                label=""
                                value={data?.storage_transport_temperature}
                              />
                            </div>
                            <div className="Table-span-1">
                              <TextField
                                fullWidth
                                label=""
                                value={data?.acceptable_alternative_specimen}
                              />
                            </div>
                            <div className="Table-span-1">
                              <TextField
                                fullWidth
                                label=""
                                value={data?.other_requirements}
                              />
                            </div>
                            <div className="Table-span-1">
                              <TextField
                                fullWidth
                                label=""
                                value={data?.causes_for_rejection}
                              />
                            </div>
                            <div className="Table-span-1">
                              <div className="d-flex align-items-center">
                                <TextField
                                  fullWidth
                                  label=""
                                  value={data?.time_to_result}
                                />
                              </div>
                            </div>
                          </>
                        )
                      )}
                    </div>
                  );
                })}
            </div>

            {(rowData?.follow_up_date !== "" &&
              rowData?.follow_up_date !== null) && (
                <div className="d-flex flex-column gap-2 my-4">
                  <p className="font-inter font-w500 lh-base ">
                    Recommended follow up Date
                  </p>
                  <TextField
                    placeholder="25/01/2024 2:30 PM"
                    sx={{ width: "300px" }}
                  />
                </div>
              )}
          </DialogContentText>

          <DialogContentText>
            <p className="font-inter font-w600 lh-base text-black mt-4">
              Session Note
            </p>
            <div
              className="rounded rounded-3"
              style={{ backgroundColor: "#F9F5FF" }}
            >
              <div className="p-3">
                <div className="w-100">
                  <h6>Client Updates</h6>

                  <TextEditor initialText={rowData?.progress_note?.client_updates?.text} />
                </div>
                <div className="d-flex gap-4 mt-4">
                  <div className="w-100">
                    <h6>Treatment Plan Progress</h6>
                    <TextEditor initialText={rowData?.progress_note?.treatment_plan_progress?.text} />
                  </div>
                  <div className="w-100">
                    <h6>Intervention Applied</h6>
                    <TextEditor initialText={rowData?.progress_note?.interventions_applied?.text} />
                  </div>
                </div>
                <div className="d-flex gap-4 mt-4">
                  <div className="w-100">
                    <h6>Homework assigned</h6>
                    <div>
                      <div className="d-flex">
                        {rowData?.progress_note?.homework_assigned?.options?.map(
                          (opt, index) => (
                            <div
                              key={index}
                              className="d-flex flex-row align-items-center"
                            >
                              <input
                                type="radio"
                                value={opt.option_text}
                                checked={opt.selected === true}
                                onChange={() => handleOptionSelect(opt)}
                                className="me-2"
                              />
                              <label className="me-3">{opt.option_text}</label>
                            </div>
                          )
                        )}
                      </div>
                      {rowData?.progress_note?.homework_assigned?.options?.map(
                        (opt, index) =>
                          opt.selected && (
                            <div key={index} className="mt-2">
                              <TextEditor
                                initialText={
                                  rowData?.progress_note
                                    ?.homework_assigned?.text
                                }
                              />
                            </div>
                          )
                      )}
                    </div>
                  </div>

                  <div className="w-100">
                    <h6>Next Session</h6>
                    <div>
                      <div className="d-flex">
                        {rowData?.progress_note?.next_session?.options?.map(
                          (opt, index) => (
                            <div
                              key={index}
                              className="d-flex flex-row align-items-center"
                            >
                              <input
                                type="radio"
                                value={opt.option_text}
                                checked={opt.selected === true}
                                onChange={() => handleOptionSelect(opt)}
                                className="me-2"
                              />
                              <label className="me-3">{opt.option_text}</label>
                            </div>
                          )
                        )}
                      </div>
                      {rowData?.progress_note?.next_session?.options?.map(
                        (opt, index) =>
                          opt.selected && (
                            <div key={index} className="mt-2">
                              <TextEditor
                                initialText={
                                  rowData?.progress_note
                                    ?.homework_assigned?.text
                                }
                              />
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-100 mt-2">
                  <h6>Changes to Treatment Plan </h6>
                  <div>
                    <div className="d-flex">
                      {rowData?.progress_note?.changes_to_treatment_plan?.options?.map(
                        (opt, index) => (
                          <div
                            key={index}
                            className="d-flex flex-row align-items-center"
                          >
                            <input
                              type="radio"
                              value={opt.option_text}
                              checked={opt.selected === true}
                              onChange={() => handleOptionSelect(opt)}
                              className="me-2"
                            />
                            <label className="me-3">{opt.option_text}</label>
                          </div>
                        )
                      )}
                    </div>
                    {rowData?.progress_note?.changes_to_treatment_plan?.options?.map(
                      (opt, index) =>
                        opt.selected && (
                          <div key={index} className="mt-2">
                            <TextEditor
                              initialText={
                                rowData?.progress_note
                                  ?.homework_assigned?.text
                              }
                            />
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </DialogContentText>

          <div
            style={{ backgroundColor: "#73589B", width: "170px" }}
            className="d-flex fw-semibold text-white rounded rounded-3 p-3 my-4"
            onClick={onClose}
          >
            <span className="mx-auto" style={{ fontSize: "20px" }}>
              Close
            </span>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ClientDetailsViewMoreModal;
