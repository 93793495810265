export const convertBlobToBase64 = (blob) => {
    //console.log("blob", blob);
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};


export const handleBrokenImage = (imgLink) => {
    const imgLinkSplit = imgLink.split("https://");
    if (imgLinkSplit.length > 1) {
        const updatedLink = "https://" + imgLinkSplit[imgLinkSplit.length - 1];
        return updatedLink;
    } else {
        return "https://images.unitedwecare.com/odoo%2Fopen%2Fprofile-vector.webp"
    }
}