import React, { useContext, useRef } from "react";
import { AppContext } from "../../../../context";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import UserInfoPanel from "../Components/UserInfoPanel";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

import { useReactToPrint } from "react-to-print";
import RecommendExpertListView from "../Components/RecommendExpertListView";
import RecommendedProgramListView from "../Components/RecommendedProgramListView";
import RecommendedAssessmentListView from "../Components/RecommendedAssessmentListView";
import RecommendedContentListView from "../Components/RecommendedContentListView";
import { Close, Download } from "@mui/icons-material";

function PDFDialog({ open, setOpen }) {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  const tab1Ref = useRef(null);
  const renderTextEditor = (key, labelText, section) => {
    return (
      <div
        className={`w-100 position-relative ${
          removeHtmlTags(expertClinicalNotesData?.data?.[section]?.[key]?.text)
            ?.length > 0
            ? ""
            : "d-none"
        }`}
      >
        <h5>{labelText}</h5>{" "}
        <p className="m-0">
          {" "}
          {removeHtmlTags(
            expertClinicalNotesData?.data?.[section]?.[key]?.text
          )}
        </p>
      </div>
    );
  };
  function removeHtmlTags(text) {
    return text?.replace(/<[^>]*>/g, "")?.replace(/&nbsp;/g, "");
  }
  const renderSelect = (key, labelText) => {
    return (
      <div className="w-100">
        {expertClinicalNotesData?.data?.progress_note?.[key]?.options?.find(
          (opt) => opt.selected === "true"
        )?.option_text === "Yes" && (
          <div className="mt-2">
            {renderTextEditor(key, labelText, "progress_note")}
          </div>
        )}
      </div>
    );
  };
  const statusMappings = [
    "marital_status",
    "employment_status",
    "marital_functioning",
    "social_functioning",
    "academic_functioning",
  ];
  const handlePrint = useReactToPrint({
    content: () => tab1Ref.current,
    documentTitle: expertClinicalNotesData?.client?.name,
    onAfterPrint: () => {
      if (expertClinicalNotesData.socketRef.current) {
        expertClinicalNotesData.socketRef.current.send(
          JSON.stringify({
            action_type: "note_downloaded",
            details: {},
            user_id: expertClinicalNotesData?.headerData?.user_id,
            expert_id: expertClinicalNotesData?.headerData?.expert_id,
          })
        );
      }
      setOpen(false);
    },
  });

  const SessionNote = [
    {
      id: "chief_complaints",
      label: "Chief Complaint (CC)",
      type: "textEditor",
      field: "progress_note",
    },
    {
      id: "history_of_present_illness_hpi",
      label: "History of Present Illness (HPI)",
      type: "textEditor",
      field: "progress_note",
    },
    {
      id: "session_summary",
      label: "Session Summary",
      type: "textEditor",
      field: "progress_note",
    },
    {
      id: "homework_assigned",
      label: "Advice",
      type: "textEditor",
      field: "progress_note",
    },
    {
      id: "future_treatment_plan",
      label: "Future Treatment Plan",
      type: "textEditor",
      field: "progress_note",
    },
  ];
  const Followupsummary = [
    {
      id: "compliance",
      label: "Treatment Adherence",
      type: "textEditor",
      field: "follow_up_summary",
    },
    {
      id: "treatment_plan_progress",
      label: "Treatment Plan Progress",
      type: "textEditor",
      field: "follow_up_summary",
    },
    {
      id: "assessment_summary",
      label: "Assessment Summary",
      type: "textEditor",
      field: "follow_up_summary",
    },
    {
      id: "worksheet_journal_summary",
      label: "Worksheet/Journal Summary",
      type: "textEditor",
      field: "follow_up_summary",
    },
    {
      id: "stella_sos_summary",
      label: "Stella SOS Summary",
      type: "textEditor",
      field: "follow_up_summary",
    },
  ];
  const CaseHistory = [
    {
      id: "medical_history",
      label: "Medical History",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "history_of_self_harm_aggression",
      label: "History of Self-Harm/Aggression",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "history_of_trauma",
      label: "History of Trauma",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "history_of_contagious_medical_conditions",
      label: "History of Contagious Medical Conditions",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "medication_history",
      label: "Medication History",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "hospitalization_history",
      label: "Hospitalization History",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "surgical_history_shx",
      label: "Surgical History (SHx)",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "histopathological_pathological_history",
      label: "Histopathological/Pathological History",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "allergy_history",
      label: "Allergy History",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "nutritional_history",
      label: "Nutritional History",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "substance_use_issues",
      label: "Substance Dependence History",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "genetics",
      label: "Genetics",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "family_history_of_mental_illness_or_substance_abuse_issues",
      label: "Family History (FHx)",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "psychosocial_history",
      label: "Social History (SocHx)",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "languages_spoken",
      label: "Languages Spoken",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "lifestyle_history",
      label: "Lifestyle History",
      type: "textEditor",
      field: "case_history",
    },
    {
      id: "developmental_history",
      label: "Developmental History",
      type: "textEditor",
      field: "case_history",
    },
  ];

  const Prescription = [
    {
      id: "vitals",
      label: "Vitals",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "mental_status_exam_results",
      label: "Mental Status Exam Results",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "physical_exam_results",
      label: "Physical Exam Results",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "histopathological_pathological_diagnostics",
      label: "Histopathological/Pathological Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "imaging_and_radiological_diagnostics",
      label: "Imaging and Radiological Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "biochemical_diagnostics",
      label: "Biochemical Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "microbiological_diagnostics",
      label: "Microbiological Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "cardiological_diagnostics",
      label: "Cardiological Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "pulmonary_diagnostics",
      label: "Pulmonary Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "gastro_intestinal_hepatological_diagnostics",
      label: "Gastro-intestinal/Hepatological Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "neurological_diagnostics",
      label: "Neurological Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "nephrological_diagnostics",
      label: "Nephrological Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "urological_diagnostics",
      label: "Urological Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "ophthalmological_diagnostics",
      label: "Ophthalmological Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "dermatological_diagnostics",
      label: "Dermatological Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "reproductive_diagnostics",
      label: "Reproductive Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "ent_diagnostics",
      label: "ENT Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "endocrinological_diagnostics",
      label: "Endocrinological Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "orthopedic_diagnostics",
      label: "Orthopedic Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
    {
      id: "pediatric_diagnostics",
      label: "Pediatric Diagnostics",
      type: "textEditor",
      field: "prescription",
    },
  ];

  const renderField = (field) => {
    if (field.type === "textEditor") {
      if (
        expertClinicalNotesData?.data?.template?.some(
          (item) => item.name === field.id
        )
      ) {
        return renderTextEditor(field.id, field.label, field.field);
      }
    } else if (field.type === "select") {
      if (
        expertClinicalNotesData?.data?.template?.some(
          (item) => item.name === field.id
        )
      ) {
        return renderSelect(field.id, field.label, field.field);
      }
    }
    return null;
  };
  return (
    <div>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            margin: 0,
          },
        }}
        className=""
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          sx={{ background: "#fff", borderBottom: "1px solid #D8D8D8" }}
          className="clinicalnotes-submit-save w-100 sticky-top d-flex ps-4 p-3 align-items-center justify-content-between gap-4 w-full "
        >
          <h3 className="m-0 text-muted">Preview</h3>
          <div className="d-flex align-items-center justify-content-between gap-2 ">
            <Button id="download_pdf" className="save " onClick={handlePrint}>
              Download PDF <Download sx={{ color: "var(--primary-color)" }} />
            </Button>

            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <div ref={tab1Ref} className="page-break">
          <div
            className="w-full px-4 py-2"
            style={{ background: "#fff", borderBottom: "1px solid #D8D8D8" }}
          >
            <div className="text-decoration-none">
              <img src="/assets/img/kareify/UWC logo.png" alt="" height={50} />
            </div>
          </div>

          <div className="d-flex flex-column gap-5 p-2 p-lg-4 ">
            <UserInfoPanel pdf={true} />

            <div className="d-flex flex-column gap-3 p-2 p-lg-4">
              <h4 className=" border-2 border-bottom">Session Note</h4>
              {SessionNote.map(renderField)}
            </div>

            <div className="d-flex flex-column gap-3 p-2 p-lg-4">
              <h4 className=" border-2 border-bottom">Prescription</h4>

              {expertClinicalNotesData?.data?.template?.some(
                (item) => item.name === "medicines"
              ) &&
                expertClinicalNotesData?.data?.progress_note?.medicines
                  ?.prescribed_medicines?.length > 0 && (
                  <TableContainer>
                    <Table aria-label="medication table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl.No</TableCell>
                          <TableCell>Medication Details</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines.map(
                          (row, index) => (
                            <TableRow hover key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                {`${row?.drug || ""} ${row?.strength || ""} ${
                                  row?.dosage_form || ""
                                }, ${row?.quantity || ""} to be taken ${
                                  row?.route || ""
                                } ${row?.frequency || ""}${
                                  row?.start_date
                                    ? ` from ${row?.start_date}`
                                    : ""
                                }${
                                  row?.end_date ? ` until ${row?.end_date}` : ""
                                }${
                                  row?.dispensation
                                    ? `, dispense ${row?.dispensation}`
                                    : ""
                                }${
                                  row?.refills
                                    ? ` with ${row?.refills} refills`
                                    : ""
                                }${
                                  row?.instructions
                                    ? `. ${row?.instructions}`
                                    : "."
                                }`}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              {Prescription.map(renderField)}
              {expertClinicalNotesData?.data?.template?.some(
                (item) => item.name === "lab_test"
              ) && (
                <TableContainer
                  className=" border rounded-3 "
                  sx={{ marginTop: "20px" }}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="lab test table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography fontWeight="bold">Test Name</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight="bold">
                            Time to Result
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {expertClinicalNotesData?.data?.lab_test
                        ?.prescribed_lab_test?.length > 0 ? (
                        expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.map(
                          (row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row?.test_name}</TableCell>
                              <TableCell>
                                <div className="d-flex align-items-center gap-2">
                                  <span>
                                    {row?.time_to_result?.split(" ")?.[0]}
                                  </span>
                                  <span>
                                    {row?.time_to_result?.split(" ")?.[1]}
                                  </span>
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        )
                      ) : (
                        <TableRow>
                          <TableCell colSpan={2}>
                            No lab tests prescribed.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>

            <div className="d-flex flex-column gap-3 p-2 p-lg-4">
              <h4 className=" border-2 border-bottom">Follow-Up Summary</h4>

              {Followupsummary.map(renderField)}
            </div>

            <div className="d-flex flex-column gap-3 p-2 p-lg-4">
              <h4 className=" border-2 border-bottom">Case History</h4>
              {statusMappings.map((row, rowIndex) => (
                <div key={rowIndex} className="">
                  <span className="fw-semibold">
                    {row
                      .replace("_", " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase())}{" "}
                    {" : "}
                  </span>

                  <span>
                    {
                      expertClinicalNotesData?.data?.case_history?.[
                        row
                      ]?.options?.find((op) => op.selected === "true")
                        ?.option_text
                    }
                  </span>
                </div>
              ))}

              {CaseHistory.map(renderField)}
            </div>

            <div className="d-flex flex-column gap-3 p-2 p-lg-4">
              <h4 className=" border-2 border-bottom">Recommendation</h4>
              <RecommendExpertListView download={true} />
              <RecommendedProgramListView download={true} />
              <RecommendedAssessmentListView download={true} />
              <RecommendedContentListView download={true} />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default PDFDialog;
