import { Routes, Route, BrowserRouter } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import { ClinicalRoutes } from "../pages/USonBoardingDashboard";
import ClinicalUserAuthentication from "../components/expertDashboard/component/ClinicalUserAuthentication";
import ClinicalUserUnauthorized from "../components/expertDashboard/component/ClinicalUserUnauthorized";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/clinical/*" element={<ClinicalRoutes />} />
        <Route path="/auth" element={<ClinicalUserAuthentication />} />
        <Route path="*" element={<ClinicalUserAuthentication />} />
        <Route path="/unauthorize" element={<ClinicalUserUnauthorized />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
