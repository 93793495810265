import React from "react";

function TalkToUs() {
  return (
    <div style={{height:"100vh"}} className="d-flex align-items-center justify-content-center">
      {" "}
      <iframe
        src="https://calendar.app.google/xFDTideqm8ai3PxA6"
        title="calender"
        className="w-100 h-100 rounded rounded-4 my-4 "
        style={{ aspectRatio: "16/9" }}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        thumbnail="https://images.unitedwecare.com/odoo/open/clinical-copilot/calendar.gif"
      ></iframe>
    </div>
  );
}

export default TalkToUs;
