import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import React, { useContext, useRef } from "react";
import { AppContext } from "../../../../context";
import { Close, Download } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";

function DownloadPrescription({ open, close }) {
  const { expertClinicalNotesData, expertDetails } = useContext(AppContext);
  const tab1Ref = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => tab1Ref.current,
    documentTitle: expertClinicalNotesData?.client?.name,
    onAfterPrint: () => {
      if (expertClinicalNotesData.socketRef.current) {
        expertClinicalNotesData.socketRef.current.send(
          JSON.stringify({
            action_type: "note_downloaded",
            details: {},
            user_id: expertClinicalNotesData?.headerData?.user_id,
            expert_id: expertClinicalNotesData?.headerData?.expert_id,
          })
        );
      }
      close();
    },
  });
  function formatDate(date) {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    // Function to get the ordinal suffix for the day
    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th"; // for 4th to 20th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}` ===
      "NaNth Invalid Date, NaN"
      ? formatDate(new Date())
      : `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  }

  return (
    <Dialog
      open={open}
      onClose={() => close()}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          height: "100%",
          maxWidth: "1000px",
          maxHeight: "90vh",
          margin: 0,
        },
      }}
      className=""
    >
      <DialogTitle>
        {" "}
        <div className="d-flex align-items-center justify-content-between gap-2 ">
          <IconButton id="download_pdf" className="save " onClick={handlePrint}>
            <Download sx={{ color: "var(--primary-color)" }} />
          </IconButton>

          <IconButton onClick={() => close()}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent ref={tab1Ref}>
        <div
          style={{ background: "#002B6F" }}
          className="d-flex align-items-center gap-4 p-3"
        >
          <img
            src="/assets/img/favicon/android-chrome-192x192.png"
            alt=""
            height={50}
            style={{ filter: "brightness(50)" }}
          />
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <h5 style={{ color: "#fff" }}>
                {expertDetails?.profile?.first_name +
                  " " +
                  expertDetails?.profile?.last_name}
              </h5>
              <div style={{ color: "#fff", fontSize: "14px" }}>
                {(expertDetails?.profile?.address ||
                  expertDetails?.profile?.city ||
                  expertDetails?.profile?.state ||
                  expertDetails?.profile?.zip) && (
                    <p className="m-0">
                      {[
                        expertDetails.profile.address,
                        expertDetails?.profile?.city,
                        expertDetails?.profile?.state,
                        expertDetails?.profile?.zip,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </p>
                  )}
                {expertDetails?.profile?.country && (
                  <p className="m-0">{expertDetails.profile.country}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <p className="d-flex justify-content-end m-0 my-4">
          {" "}
          <strong>{formatDate(new Date())}</strong>
        </p>
        <h5> Prescription</h5>
        <div>
          <TableContainer component={Paper} sx={{ mt: 2, overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ p: 1, fontWeight: "bold" }}>Sl.No</TableCell>
                  <TableCell sx={{ p: 1, fontWeight: "bold" }}>
                    Medicine Name
                  </TableCell>
                  <TableCell sx={{ p: 1, fontWeight: "bold" }}>
                    Dosage
                  </TableCell>
                  <TableCell sx={{ p: 1, fontWeight: "bold" }}>
                    Quantity
                  </TableCell>
                  <TableCell sx={{ p: 1, fontWeight: "bold" }}>
                    Instructions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines?.map(
                  (med, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ p: 1 }}>{index + 1}</TableCell>
                      <TableCell sx={{ p: 1 }}>
                        <strong>{med?.drug?.toUpperCase()}</strong>
                        <br />
                        <span style={{ fontSize: "12px", color: "#555" }}>
                          Strength: {med?.strength || "N/A"}
                        </span>
                        <br />
                        <span style={{ fontSize: "12px", color: "#555" }}>
                          {med?.dosage_form?.toUpperCase()}
                        </span>
                      </TableCell>
                      <TableCell sx={{ p: 1 }}>
                        <span style={{ fontSize: "14px" }}>
                          {med?.frequency}
                        </span>
                        <br />
                        <span style={{ fontSize: "12px", color: "#555" }}>
                          {med?.route}
                        </span>
                      </TableCell>
                      <TableCell sx={{ p: 1 }}>
                        <strong>Quantity:</strong> {med?.quantity || "N/A"}
                        <br />
                        <strong>Dispense:</strong> {med?.dispensation || "N/A"}
                        <br />
                        <strong>Refills:</strong> {med?.refils || "N/A"}
                      </TableCell>
                      <TableCell sx={{ p: 1 }}>
                        {med?.instructions || "N/A"}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="border w-full mt-5"></div>
        <div style={{ overflowX: "auto" }}>
          <div
            style={{ minWidth: "700px" }}
            className="d-flex py-3  gap-2 justify-content-between align-items-center"
          >
            <img src="/assets/img/kareify/UWC logo.png" alt="" height={40} />
            {expertDetails?.profile?.email && (
              <span className=" text-muted">
                {expertDetails?.profile?.email}
              </span>
            )}
            {expertDetails?.profile?.mobile && (
              <span className=" text-muted">
                {expertDetails?.profile?.country_phone_code +
                  " " +
                  expertDetails?.profile?.mobile}
              </span>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DownloadPrescription;
