import React from "react";

function ClinicalNoteStatus({ session, setSession }) {
  const statuses = [
    { name: "In Progress", value: "In Progress" },
    { name: "Completed", value: "Completed" },
    { name: "All", value: "all" },
    { name: "Archived", value: "Archived" },
  ];

  return (
    <div
      style={{ background: "#EEEEEE", overflowX: "auto" }}
      className="d-flex  gap-1 p-1 rounded-pill"
    >
      {statuses.map(({ name, value }) => (
        <button
          id={`status_${name}`}
          key={name}
          className={`py-1   px-4 rounded-pill `}
          style={{
            fontSize: "15px",
            fontFamily: "Inter",
            fontWeight: 600,
            minWidth: "150px",
            background: session === value ? "white" : "",
            border: 0,
            color: session === value ? "var(--Primary-color)" : "#898989",
          }}
          onClick={() => setSession(value)}
        >
          {name}
        </button>
      ))}
    </div>
  );
}

export default ClinicalNoteStatus;
