import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context";

// Component
import UserSummarySkeleton from "../Skeletons/UserSummarySkeleton";

// MUI

import { Cached } from "@mui/icons-material";
import { Button } from "@mui/material";

function UserSummaryPanel({ loading, genSummary, summaryLoading }) {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);

  return (
    <Fragment>
      {loading || summaryLoading ? (
        <UserSummarySkeleton />
      ) : (
        <div
          className="mt-4 rounded rounded-4  border pb-3 "
          style={{ backgroundColor: "white" }}
        >
          <div className="d-flex align-items-center border-bottom justify-content-between">
            <p className="py-3 px-4 m-0 fw-bold">Summary</p>
            {!expertClinicalNotesData?.data?.submitted && (
              <Button
                onClick={() => {
                  genSummary();
                  expertClinicalNotesData.socketRef.current.send(
                    JSON.stringify({
                      action_type: "regenerated",
                      details: {
                        field_name: "Summary",
                      },
                      user_id: expertClinicalNotesData?.headerData?.user_id,
                      expert_id: expertClinicalNotesData?.headerData?.expert_id,
                    })
                  );
                }}
                id="regenerate_summary"
                style={{
                  background: "var(--primary-accent-color)",
                  color: "var(--primary-color)",
                }}
                disabled={expertClinicalNotesData?.editTemplate}
                className="mx-4  rounded-2 font-inter font-w600 border-0"
              >
                <Cached
                  className={`${summaryLoading ? "animate-spin" : ""} me-2`}
                />
                {!expertClinicalNotesData?.data?.summary
                  ? "Generate"
                  : "Regenerate"}
              </Button>
            )}
          </div>

          {expertClinicalNotesData?.data?.summary && (
            <>
              {" "}
              <p
                className="mx-2 mx-sm-4 mt-3 fw-bold  p-2"
                style={{ background: "#E9E8E7", borderRadius: "10px" }}
              >
                {expertClinicalNotesData?.data?.summary?.one_line_summary}
              </p>
              <div className="row row-cols-1 row-cols-lg-2 p-2 p-sm-4">
                <div className="d-flex flex-column col  gap-2">
                  <p className="m-0">
                    <span className="fw-bold">Chronic Conditions:</span>{" "}
                    <span>
                      {
                        expertClinicalNotesData?.data?.summary
                          ?.chronic_conditions
                      }
                    </span>
                  </p>
                  <p className="m-0">
                    <span className="fw-bold">Current Medication:</span>{" "}
                    <span>
                      {
                        expertClinicalNotesData?.data?.summary
                          ?.current_medications
                      }
                    </span>
                  </p>{" "}
                  <p className="m-0">
                    <span className="fw-bold">Hospitalizations:</span>{" "}
                    <span>
                      {expertClinicalNotesData?.data?.summary?.hospitalizations}
                    </span>
                  </p>{" "}
                  <p className="m-0">
                    <span className="fw-bold">Surgeries:</span>{" "}
                    <span>
                      {expertClinicalNotesData?.data?.summary?.surgeries}
                    </span>
                  </p>{" "}
                  <p className="m-0">
                    <span className="fw-bold">Recent Tests:</span>{" "}
                    <span>
                      {expertClinicalNotesData?.data?.summary?.recent_tests}
                    </span>
                  </p>{" "}
                </div>
                <div className="d-flex flex-column   col gap-2">
                  <p className="fw-semibold m-0">Psychiatric History:</p>
                  <div className=" rounded-4  p-2 border border-2">
                    <ul className="d-flex flex-column gap-2 overflow-auto">
                      {expertClinicalNotesData?.data?.summary
                        ?.psychiatric_history?.length > 0 &&
                        expertClinicalNotesData?.data?.summary?.psychiatric_history?.map(
                          (item, index) => <li key={index}>{item?.history}</li>
                        )}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="mx-4  fw-bold d-none p-2"
                style={{ background: "#E9E8E7", borderRadius: "10px" }}
              >
                <p className=" fw-semibold">Notes from Clinician:</p>
                <ul>
                  {expertClinicalNotesData?.data?.summary?.notes_from_clinician
                    ?.length > 0 &&
                    expertClinicalNotesData?.data?.summary?.notes_from_clinician?.map(
                      (item, index) => (
                        <li key={index} className="fw-normal">
                          {item.note}
                        </li>
                      )
                    )}
                </ul>
              </div>
            </>
          )}
        </div>
      )}
    </Fragment>
  );
}

export default UserSummaryPanel;
