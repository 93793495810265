import React, { useContext, useEffect, useState } from "react";

import { useSnackbar } from "notistack";

// API
import { HandleBlurForAssistant } from "../ClinicalNotesAPI/ClinicalNoteAPIManager";

// MUI
import { CheckCircleOutline } from "@mui/icons-material";
import { AppContext } from "../../../../context";

const AssistantItem = ({ item, index, heading, label }) => {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  const [showFullText, setShowFullText] = useState({});
  const [updated, setUpdated] = useState(false);
  const [anyUpdated, setAnyUpdated] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const toggleShowFullText = (key) => {
    setShowFullText((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  useEffect(() => {
    if (!anyUpdated) {
      return
    }
    // Destructure to get the specific item you need
    const item =
      expertClinicalNotesData?.data?.assistant?.[label]?.[heading][index];
    // console.log("inside use effect");
    // Check if the item's function is add/remove and the operation is accepted

    const data = JSON.stringify({
      header: expertClinicalNotesData?.headerData,
      session_data: expertClinicalNotesData?.data,
    });
    if (expertClinicalNotesData.socketRef.current) {
      // console.log("Sending data:", data);
      expertClinicalNotesData.socketRef.current.send(data);

    }
    if (
      (item?.function === "add" || item?.function === "remove" || item?.function === "replace" || item?.function === "adjust") &&
      item?.accept_button_val &&
      updated
    ) {
      // console.log("inside use effect if condition");

      handleUpdateAssitant();
      setUpdated(false);
    }
  }, [expertClinicalNotesData?.data?.assistant?.[label]?.[heading][index]]); // Simplified dependency to reflect potential changes more broadly

  function handleInteraction(mainHead, header, index, prev, isAccept) {
    const section = prev?.data?.assistant[mainHead];
    const currentItem = section[header][index]; // Corrected access

    // Determine if there is no change needed
    if (
      (isAccept && currentItem?.accept_button_val) ||
      (!isAccept && currentItem?.reject_button_val)
    ) {
      return prev;
    }

    // Update interactions based on the action
    const updatedInteractions = section[header].map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          accept_button_val: isAccept,
          reject_button_val: !isAccept,
        };
      }
      return item;
    });

    // Calculate the updated alert number
    let newAlertNumber = prev?.data?.assistant[mainHead]?.alert_number;
    if (!currentItem.accept_button_val && !currentItem.reject_button_val) {
      newAlertNumber -= 1; // Simply decrement since an interaction is now acknowledged
    }

    // Update the state with new interactions
    const newState = {
      ...prev,
      data: {
        ...prev.data,
        assistant: {
          ...prev.data.assistant,
          [mainHead]: {
            ...section,
            [header]: updatedInteractions,
            alert_number: newAlertNumber,
          },
        },
      },
    };

    // Execute additional operations based on the interaction type
    const updatedData = executeLabTestOperation(
      newState,
      currentItem,
      isAccept
    );


    return updatedData;
  }

  function executeLabTestOperation(state, labTest, isAccept) {
    const { path, function: operation } = labTest || {};
    if (isAccept) {
      // console.log("Operation:", operation);
      let TEMP = null;
      switch (operation) {
        case "add":
          //  console.log("updated State", updateState(state, labTest, path, 'add'));
          TEMP = updateState(state, labTest, path, "add");
          return { ...state, data: TEMP?.data };
        case "replace":
        case "adjust":
          TEMP = updateState(state, labTest, path, "replace");
          return { ...state, data: TEMP?.data };
        case "remove":
          TEMP = updateState(state, labTest, path, "remove");
          return { ...state, data: TEMP?.data };
        default:
          return state;
      }
    }

    return state;
  }

  function updateState(state, labTest, path, type) {
    const newState = JSON.parse(JSON.stringify(state));
    modifyNested(newState.data, path, labTest, type);
    return newState;
  }

  function modifyNested(obj, path, labTest, type) {
    const keys = path.split(".");
    const lastKey = keys.pop();
    let lastObj = obj;
    keys.forEach((key) => {
      if (!lastObj[key]) lastObj[key] = {};
      lastObj = lastObj[key];
    });

    // If the path should affect the 'text' key specifically:
    if (path.includes("history_of_present_illness_hpi")) {
      // Ensure that there is a 'text' key to append to
      if (!lastObj[lastKey]["text"]) {
        lastObj[lastKey]["text"] = "<p></p>\n"; // Initialize with an empty paragraph if not present
      }
      // console.log(labTest);
      // Append the labTest details in an HTML paragraph to the 'text' key
      lastObj[lastKey][
        "text"
      ] += `<p>${labTest.name} ${labTest.icd_10_code}</p>\n`;
    } else {
      // For other paths, handle as array operations
      if (!lastObj[lastKey]) {
        lastObj[lastKey] = [];
      }
      let array = lastObj[lastKey];
      switch (type) {
        case "add":
          array.push(labTest);

          break;
        case "replace":
          const index = array.findIndex(
            (item) => item._id === labTest.id_to_replace
          );
          if (index !== -1) {
            array[index] = labTest;
          }
          break;
        case "remove":
          const removeIndex = array.findIndex(
            (item) => item._id === labTest.id_to_remove
          );
          if (removeIndex !== -1) {
            array.splice(removeIndex, 1);
          }
          break;
        default:
          return;
      }
    }
  }

  const severity = (text) => {
    switch (text) {
      case "Minor":
        return (
          <span className="rounded-pill p-2 py-1" style={{ color: "#2D8A38", background: "#2D8A381A" }}>
            Low Risk
          </span>
        );
      case "Moderate":
        return (
          <span className="rounded-pill p-2 py-1" style={{ color: "#D0A50E", background: "#D0A50E1A" }}>
            Moderate Risk
          </span>
        );
      case "Major":
        return (
          <span className="rounded-pill p-2 py-1" style={{ color: "#F62E2E", background: "#F62E2E1A" }}>
            Major Risk
          </span>
        );
      case "Unknown":
        return (
          <span className="rounded-pill p-2 py-1" style={{ color: "#807C75", background: "#F2F2F2" }}>
            Unknown Risk
          </span>
        );
      default:
        return null;
    }
  };

  const handleUpdateAssitant = async () => {
    const payload = {
      ...expertClinicalNotesData?.headerData,
      assistant: expertClinicalNotesData?.data?.assistant,
      drugs:
        expertClinicalNotesData?.data?.progress_note?.medicines
          ?.prescribed_medicines,
      labtest: expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test,
      disease: "",
    };

    HandleBlurForAssistant(payload).then((res) => {
      if (res?.error) {
        enqueueSnackbar(res?.message, { variant: "info" });
        return;
      }
    });
  };

  return (
    <div
      key={index}
      className=" mb-4 rounded-2 border clinicalnotes--assistant__item"
    >
      <div className="header d-flex align-items-center  justify-content-between">
        {heading && (
          <h6 className="m-0">
            {heading.charAt(0).toUpperCase() +
              heading.slice(1).replace(/_/g, " ")}
          </h6>
        )}
        <div className="d-flex gap-2 align-items-center">
          {!expertClinicalNotesData?.data?.assistant?.[label]?.[heading][index]
            ?.reject_button_val && (
              <button
                onClick={() => {
                  setExpertClinicalNotesData((prev) =>
                    handleInteraction(label, heading, index, prev, true)
                  );
                  if (expertClinicalNotesData.socketRef.current) {
                    // console.log("Sending data:", data);
                    expertClinicalNotesData.socketRef.current.send(
                      JSON.stringify({
                        action_type: "alert_acknowledged",
                        details: {
                          alert_name:heading.charAt(0).toUpperCase() +
                          heading.slice(1).replace(/_/g, " ")
                        },
                        user_id: expertClinicalNotesData?.headerData?.user_id,
                        expert_id: expertClinicalNotesData?.headerData?.expert_id,
                      }));

                  }
                  setUpdated(true);
                  setAnyUpdated(true);
                }}
                id={`acknowledge_${label}_${heading}`}
                className="acknowledge"
                style={{
                  background:
                    expertClinicalNotesData?.data?.assistant?.[label]?.[heading][
                      index
                    ]?.accept_button_val && "#F2F2F2",
                  width: "fit-content",
                  color:
                    expertClinicalNotesData?.data?.assistant?.[label]?.[heading][
                      index
                    ]?.accept_button_val && "#807C75",
                  border: "none",
                }}
              >
                {expertClinicalNotesData?.data?.assistant?.[label]?.[heading][
                  index
                ]?.accept_button_val ? (
                  <span>
                    <CheckCircleOutline />
                    Acknowledged
                  </span>
                ) : (
                  item?.accept_button_name
                )}
              </button>
            )}
          {!expertClinicalNotesData?.data?.assistant?.[label]?.[heading][index]
            ?.accept_button_val && (
              <button
                onClick={() => {
                  setExpertClinicalNotesData((prev) =>
                    handleInteraction(label, heading, index, prev, false)
                  )
                  if (expertClinicalNotesData.socketRef.current) {
                    // console.log("Sending data:", data);
                    expertClinicalNotesData.socketRef.current.send(
                      JSON.stringify({
                        action_type: "alert_ignored",
                        details: {
                          alert_name:heading.charAt(0).toUpperCase() +
                          heading.slice(1).replace(/_/g, " ")
                        },
                        user_id: expertClinicalNotesData?.headerData?.user_id,
                        expert_id: expertClinicalNotesData?.headerData?.expert_id,
                      }));

                  }
                  setAnyUpdated(true)
                }
                }
                id={`ignore_${label}_${heading}`}
                className="ignore"
                style={{
                  background:
                    expertClinicalNotesData?.data?.assistant?.[label]?.[heading][
                      index
                    ]?.reject_button_val && "#F2F2F2",
                  width: "fit-content",
                  color:
                    expertClinicalNotesData?.data?.assistant?.[label]?.[heading][
                      index
                    ]?.reject_button_val && "#807C75",
                  border: "none",
                }}
              >
                {expertClinicalNotesData?.data?.assistant?.[label]?.[heading][
                  index
                ]?.reject_button_val ? (
                  <span> Ignored</span>
                ) : (
                  item?.reject_button_name
                )}
              </button>
            )}
        </div>
      </div>
      <div className="p-2 p-lg-3">
        {Object.entries(item).map(([key, value]) => {
          if (
            value === "" ||
            value === null ||
            value === undefined ||
            [
              "accept_button_name",
              "reject_button_name",
              "accept_button_val",
              "reject_button_val",
              "path",
              "function",
              "_id",
              "id_to_replace",
              "id_to_remove",

            ].includes(key)
          ) {
            return null;
          }
          if (typeof value === "string") {
            const shouldShorten = value.length > 250;
            const displayValue =
              shouldShorten && !showFullText[key]
                ? value.substring(0, 250) + "..."
                : value;
            const containsNewLines = displayValue.includes("\n");

            return (
              <div className="mb-1 d-flex mb-2 gap-lg-4" key={key}>
                <span className="fw-bold col-4">
                  {" "}
                  {key.charAt(0).toUpperCase() +
                    key.slice(1).replace(/_/g, " ")}
                  :
                </span>
                <span className="col">
                  {containsNewLines ? (
                    <ul>
                      {displayValue.split("\n").map((line, lineIndex) => (
                        <li key={lineIndex}>{line}</li>
                      ))}
                    </ul>
                  ) : (
                    key === "level" ? severity(displayValue) : displayValue
                  )}
                  {shouldShorten && (
                    <button onClick={() => toggleShowFullText(key)}>
                      {showFullText[key] ? "Show Less" : "Show More"}
                    </button>
                  )}
                </span>
              </div>
            );
          } else {
            return (
              <p className="mb-1 d-flex " key={key}>
                <span className="fw-bold col-4">
                  {" "}
                  {key.charAt(0).toUpperCase() +
                    key.slice(1).replace(/_/g, " ")}
                  :{" "}
                </span>
                <span className=" col">{value}</span>
              </p>
            );
          }
        })}
      </div>
    </div>
  );
};

export default AssistantItem;
