import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

// API
import {
  fetchRecommendedMedicines,
  getRecommendedLabTest,
} from "../ClinicalNotesAPI/ClinicalNoteAPIManager";

// MUI
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import {
  dosageForm,
  frequencyList,
  routeList,
  takenWithList,
} from "../ClinicalNotesAPI/DropdownData";
import dayjs from "dayjs";
function MedicationAddDialog({
  openDialog,
  closeDialog,
  handleData,
  label,
  data,
  handleEdit,
  index,
}) {
  const [recommendedMedicines, setRecommendedMedicines] = useState([]);
  const [medicationData, setMedicationData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  useEffect(() => {
    if (data === null) {
      setMedicationData(null);
    } else {
      console.log(data);

      setMedicationData(data);
      setRecommendedMedicines([{ name: data?.drug }]);
    }
  }, [openDialog, data]);

  useEffect(() => {
    if (!searchTerm) return;
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);
  console.log(medicationData);

  useEffect(() => {
    if (debouncedSearchTerm && searchType === "medicine") {
      fetchRecommendedMedicines(debouncedSearchTerm)
        .then((response) => {
          if (response.status === "success") {
            setRecommendedMedicines(response?.data);
          } else {
            setRecommendedMedicines([]);
          }
        })
        .catch((err) => {
          console.error(err); // Optionally log the error internally here as well
        });
    }
  }, [debouncedSearchTerm]);

  const handleInputChange = (field, value, cims) => {
    setRecommendedMedicines([]);
    const updatedRows = {
      ...medicationData,
      [field]: value,
      cims: cims?.length > 0 ? cims?.split(",") : medicationData?.cims,
    };

    if (field === "drug") {
      setSearchTerm(value);
      setSearchType("medicine");
    }
    setMedicationData(updatedRows);
  };

  return (
    <div>
      <Dialog
        className="create-client-dialog"
        open={openDialog}
        onClose={() => closeDialog()}
      >
        <DialogTitle
          style={{
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          {label}
        </DialogTitle>
        <DialogContent className="p-3">
          <DialogContentText
            className="d-flex gap-2 align-items-center fw-normal lh-base"
            sx={{ fontSize: "18px", padding: "10px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Medicine Name *
                  </p>
                  <Autocomplete
                    disablePortal
                    freeSolo
                    options={recommendedMedicines.map((item) => item.name)}
                    onChange={(event, newValue) => {
                      const cims = recommendedMedicines.find(
                        (item) => item.name === newValue
                      )?.cims_class;
                      handleInputChange("drug", newValue, cims);
                    }}
                    value={medicationData?.drug || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        onChange={(e) =>
                          handleInputChange("drug", e.target.value)
                        }
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6} lg={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Strength
                  </p>
                  <TextField
                    size="small"
                    fullWidth
                    value={
                      medicationData?.strength === "None"
                        ? ""
                        : medicationData?.strength
                    }
                    label=""
                    onChange={(e) =>
                      handleInputChange("strength", e.target.value)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={6} lg={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Dosage Form
                  </p>
                  <Autocomplete
                    freeSolo
                    options={dosageForm}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={medicationData?.dosage_form || ""}
                    onChange={(event, newValue) =>
                      handleInputChange("dosage_form", newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        onChange={(e) =>
                          handleInputChange("dosage_form", e.target.value)
                        }
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6} lg={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Quantity
                  </p>
                  <TextField
                    size="small"
                    fullWidth
                    value={
                      medicationData?.quantity === "None"
                        ? ""
                        : medicationData?.quantity
                    }
                    label=""
                    onChange={(e) =>
                      handleInputChange("quantity", e.target.value)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={6} lg={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Route
                  </p>
                  <Autocomplete
                    freeSolo
                    options={routeList}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={medicationData?.route || ""}
                    onChange={(event, newValue) => {
                      handleInputChange("route", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        onChange={(e) =>
                          handleInputChange("route", e.target.value)
                        }
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6} lg={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Frequency
                  </p>
                  <Autocomplete
                    freeSolo
                    options={frequencyList}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={medicationData?.frequency || ""}
                    onChange={(event, newValue) => {
                      handleInputChange("frequency", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        onChange={(e) =>
                          handleInputChange("frequency", e.target.value)
                        }
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6} lg={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Dispensation
                  </p>
                  <TextField
                    size="small"
                    fullWidth
                    value={
                      medicationData?.dispensation === "None"
                        ? ""
                        : medicationData?.dispensation
                    }
                    label=""
                    onChange={(e) =>
                      handleInputChange("dispensation", e.target.value)
                    }
                  />
                </div>
              </Grid>{" "}
              <Grid item xs={6} lg={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Refills
                  </p>
                  <TextField
                    size="small"
                    fullWidth
                    value={
                      medicationData?.refills === "None"
                        ? ""
                        : medicationData?.refills
                    }
                    label=""
                    onChange={(e) =>
                      handleInputChange("refills", e.target.value)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Instructions
                  </p>
                  <TextField
                    size="small"
                    fullWidth
                    value={
                      medicationData?.instructions === "None"
                        ? ""
                        : medicationData?.instructions
                    }
                    label=""
                    onChange={(e) =>
                      handleInputChange("instructions", e.target.value)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Start Date
                  </p>
                  <input
                    type="date"
                    className="w-100 rounded-2 border p-1"
                    value={medicationData?.start_date}
                    onChange={(e) =>
                      handleInputChange("start_date", e.target.value)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    End Date
                  </p>
                  <input
                    type="date"
                    className="w-100 rounded-2 border p-1"
                    min={medicationData?.start_date}
                    value={medicationData?.end_date}
                    onChange={(e) =>
                      handleInputChange("end_date", e.target.value)
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="px-4 mb-2">
          <Button
            onClick={() => {
              setMedicationData(null);
              closeDialog();
            }}
            className="PrimaryCTA--Ghost"
            sx={{
              background: "white",
              marginLeft: "20px",
              height: "40px",
              width: "100px",
            }}
          >
            Cancel
          </Button>

          {index ? (
            <Button
              id="added_new_medication"
              onClick={() => {
                handleEdit(index, medicationData);
                setMedicationData(null);
                closeDialog();
              }}
              disabled={
                !medicationData?.drug || medicationData?.drug?.trim() === ""
              }
              className="PrimaryCTA"
              sx={{ marginLeft: "20px", height: "40px", width: "100px" }}
            >
              done
            </Button>
          ) : (
            <Button
              id="added_new_medication"
              onClick={() => {
                handleData(medicationData);
                setMedicationData(null);
                closeDialog();
              }}
              disabled={
                !medicationData?.drug || medicationData?.drug?.trim() === ""
              }
              className="PrimaryCTA"
              sx={{ marginLeft: "20px", height: "40px", width: "100px" }}
            >
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MedicationAddDialog;
