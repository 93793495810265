import React from "react";
import { Skeleton } from "@mui/material";

function LabtestsSkeleton() {
  return (
    <div>
      {Array.from(new Array(1)).map((row, index) => (
        <div
          style={{ gridTemplateColumns: "repeat(2, minmax(0, 1fr))" }}
          className="Table--Body gap-2 mt-2"
          key={index}
        >
          <div className="Table-span-1">
            <Skeleton variant="rounded" height="56px" className="rounded-3" />
          </div>
          <div className="Table-span-1">
            <div className="d-flex gap-2 w-100">
              <Skeleton
                variant="rounded"
                width="100%"
                height="56px"
                className="rounded-3"
              />
              <Skeleton
                variant="rounded"
                width="100%"
                height="56px"
                className="rounded-3"
              />
            </div>
          </div>
        </div>
      ))}
      <Skeleton
        variant="rounded"
        width="120px"
        height="50px"
        className="rounded-3 my-3"
      />
    </div>
  );
}

export default LabtestsSkeleton;
