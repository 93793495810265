import { ArrowBackIosNew, ArrowForwardIos, Close } from "@mui/icons-material";
import { Button, Dialog, DialogContent, IconButton } from "@mui/material";
import React from "react";

function SettingUpDialog({ open, close, finish }) {
  const [step, setStep] = React.useState(0);
  const urls = [
    {
      url: "https://www.youtube.com/embed/vayJjCZ4zNI?si=E5DJNw2uudOTFp4r&loop=1&playlist=vayJjCZ4zNI",
      title: `Tutorial: Creating a Clinical Note`,
      description: `Get started by learning how to make your very first clinical note`,
    },
    {
      url: "https://www.youtube.com/embed/1gXMT3XbLeg?si=uoHGMJgBjpPlUfFy&loop=1&playlist=1gXMT3XbLeg",
      title: `Tutorial: Smarter Decision-Making with Clinical Co-Pilot `,
      description: `Discover how Clinical Co-Pilot brings insights from 150 years of medical research to your Clinical Note`,
    },

    // {
    //   url: "https://www.youtube.com/embed/vYsZjtcjzvM?si=zYvYuQT7lGd5cfPO&loop=1&playlist=vYsZjtcjzvM",
    //   title: "HOW TO GENERATE A HEALTH INTAKE FORM",
    //   description: "",
    // },
    {
      url: "https://www.youtube.com/embed/8fLCJzKx-C8?si=bLANp54mbZYBttOB&loop=1&playlist=8fLCJzKx-C8",
      title: `Tutorial: Using Clinical Co-Pilot to Make Recommendations`,
      description: `See how to recommend clinicians, assessments and more to your patients`,
    },
    {
      url: "",
      title: `One-on-One Support`,
      description: ``,
    },
  ];
  return (
    <Dialog
      open={open}
      onClose={() => close()}
      className="recommendation-assessment clinicalDashboard--scroll"
    >
      <DialogContent className="px-2  p-sm-4">
        <div>
          <IconButton
            className=" position-absolute top-0 end-0 m-2"
            onClick={() => finish()}
          >
            <Close />
          </IconButton>

          <h5 className=" d-flex mt-2 justify-content-between  align-items-center text-muted">
            {urls
              ?.filter((it, idx) => idx === step)
              .map((item, index) => item.title)}
          </h5>
          <div className="d-flex w-100 mt-2 flex-column flex-md-row align-items-md-center gap-2 justify-content-between ">
            <h6 className="m-0">
              {urls
                ?.filter((it, idx) => idx === step)
                .map((item, index) => item.description)}
            </h6>
            <div style={{ minWidth: "112px" }}>
              <span
                style={{ border: "1px solid var(--primary-color)", color: "var(--primary-color)", minWidth: "112px" }}
                className="  rounded rounded-pill  py-2 px-3 gap-2"
              >
                Step {step + 1} of {urls.length}
              </span>
            </div>
          </div>

          {urls
            ?.filter((it, idx) => idx === step)
            .map((item, index) => (
              <div>
                {item.url ? (
                  <div>
                    <iframe
                      src={item.url}
                      title="YouTube video player"
                      className="w-100 rounded rounded-4 my-4 "
                      style={{ aspectRatio: "16/9" }}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                ) : (
                  <div
                    style={{ height: "55vh" }}
                    className=" d-flex flex-column gap-4 justify-content-center align-items-center"
                  >
                    <img
                      src="https://images.unitedwecare.com/odoo/open/clinical-copilot/calendar.gif"
                      alt="calender"
                    />
                    <h2>Want to Know More?</h2>
                    <h5 className=" text-center">
                      Schedule a one-on-one meeting with us
                    </h5>
                    <Button
                      style={{
                        background: "var(--primary-color)",
                        color: "white",
                        borderRadius: "10px",
                        padding: "8px 20px",
                        fontSize: "20px",
                        fontWeight: "600",
                        textTransform: "capitalize",
                        boxShadow: "none",
                      }}
                      onClick={() => {
                        window.open(
                          "https://calendar.app.google/xFDTideqm8ai3PxA6",
                          "_blank"
                        );
                      }}
                    >
                      Book Now
                    </Button>
                  </div>
                )}
              </div>
            ))}
        </div>
      </DialogContent>
      <div className="p-3 d-flex gap-3 justify-content-center justify-content-lg-end">
        <Button
          style={{
            border: "1px solid var(--primary-color)",
            color: "var(--primary-color)",
            borderRadius: "10px",
            padding: "8px 20px",
            fontSize: "16px",
            fontWeight: "600",
            textTransform: "capitalize",
            boxShadow: "none",
            display: step === 0 ? "none" : "block",
          }}
          onClick={() => {
            if (step === 0) {
              setStep(0);
            } else {
              setStep(step - 1);
            }
          }}
        >
          Previous
        </Button>
        <Button
          style={{
            background: "var(--primary-color)",
            color: "white",
            borderRadius: "10px",
            padding: "8px 20px",
            fontSize: "16px",
            fontWeight: "600",
            textTransform: "capitalize",
            boxShadow: "none",
          }}
          onClick={() => {
            if (step === urls.length - 1) {
              finish();
            } else {
              setStep(step + 1);
            }
          }}
        >
          {step === urls.length - 1 ? "Finish" : "Next"}
        </Button>
      </div>
    </Dialog>
  );
}

export default SettingUpDialog;
