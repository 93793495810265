import React from "react";

// MUI
import { Skeleton } from "@mui/material";
function UserInfoSkeleton() {
  return (
    <div>
      <div className="d-flex flex-column flex-sm-row align-items-sm-center p-2 px-sm-4 ">
        <div className="d-flex justify-content-center  gap-2 align-items-center">
          <Skeleton
            variant="rounded"
            className="rounded rounded-circle"
            width={100}
            height={100}
          />
          <Skeleton
            variant="rectangular"
            width="64px"
            height="50px"
            className="rounded-2  d-block d-sm-none"
          />
        </div>
        <div className="p-2 p-sm-4 d-flex flex-column gap-sm-3 w-75 gap-1">
          <div className="d-flex align-items-sm-center flex-column flex-sm-row gap-sm-5  ">
            <Skeleton
              variant="rounded"
              width="100%"
              height="35px"
              className="rounded-2"
            />
            <Skeleton
              variant="rectangular"
              width="64px"
              height="50px"
              className="rounded-2 d-none d-sm-block"
            />
            
          </div>
          <div>
            <Skeleton
              variant="rounded"
              width="50%"
              height="25px"
              className="rounded-2"
            />
          </div>

          <div className="d-flex align-items-md-center flex-column flex-md-row gap-1 gap-md-3">
            <Skeleton
              variant="rounded"
              width="50%"
              height="25px"
              className="rounded-2"
            />

            <Skeleton
              variant="rounded"
              width="70%"
              height="25px"
              className="rounded-2"
            />

            <Skeleton
              variant="rounded"
              width="60%"
              height="25px"
              className="rounded-2"
            />
          </div>
        </div>
      </div>
      <div className="d-flex  justify-content-between align-items-center p-4  border-top">
        <div className="d-flex align-items-center flex-wrap gap-5 ">
          <Skeleton
            variant="rounded"
            width="10vw"
            height="25px"
            className="rounded-2"
          />
          <Skeleton
            variant="rounded"
            width="10vw"
            height="25px"
            className="rounded-2"
          />
          <Skeleton
            variant="rounded"
            width="10vw"
            height="25px"
            className="rounded-2"
          />
        </div>
      </div>
    </div>
  );
}

export default UserInfoSkeleton;
