import axios from "axios";
import jwtDecode from "jwt-decode";


const headers = {
  "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
  "Content-Type": "application/json",
};

export const getToken = async (email) => {
  const payload = {
    OTP: "7777",
    UserName: email,
  };

  const otpHeaders = {
    "Content-Type": "application/json",
    Authorization: "Bearer 1231243123hjvjVJHVvjv",
    DeviceId: "us_web",
    DeviceType: "web",
    Lat: "us_lat",
    Long: "us_long",
  };

  try {
    const response = await fetch(`https://app.uwc.world/api/Auth/LoginByOTP`, {
      method: "POST",
      headers: otpHeaders,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const postResponse = await response.json();

    const token = postResponse?.data;

    const value = jwtDecode(token);
    //setBearerToken(value.access_token);
    sessionStorage.setItem("token", value.access_token);
    return value.access_token;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const postAddSessionData = async (body, step) => {
  switch (step) {
    case 1:
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/session-notes/add`,
          {
            method: "POST",
            headers,
            body,
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const postResponse = await response.json();

        return postResponse.data;
      } catch (error) {
        console.error("Error:", error);
      }

      break;
    default:
      console.log("Something went wrong");
  }
};

export const fetchForms = async () => {
  try {
    const response = await fetch(
      "https://us.uwc.world/new-uwc/session-notes/get-forms",
      {
        method: "GET",
        headers,
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const postResponse = await response.json();
    //console.log("postResponse forms", postResponse)
    return postResponse.data;
    // Handle the response data
  } catch (error) {
    console.error(error);
    // Handle the error
  }
};

export const postClientScreenersData = async (userID, expertID) => {
  try {
    const screenerBody = {
      expert_id: userID,
      user_id: expertID,
      // user_id: currentUser?.UserId,
    };

    const response = await fetch(
      `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/assignments`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(screenerBody),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const postResponse = await response.json();

    //const { assignments } = await postResponse.data;
    //const { worksheets } = await postResponse.data;

    return postResponse.data;

    // setClientScreeners(assignments);
    //setClientScreenersQNA(worksheets);
    //setUserData(postResponse?.data);

    //console.log("data", postResponse);
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};

export const getSessionNotes = async (professionalID, clientID) => {
  const body = {
    professional_id: professionalID,
    client_id: clientID,
  };

  // console.log("body", body)
  try {
    const response = await fetch(
      `https://us.uwc.world/new-uwc/session-notes/get-notes`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const postResponse = await response.json();
    //console.log("postResponse", postResponse)

    return postResponse.data;
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};

export const getSessionNotesById = async (professionalID, id) => {
  const body = {
    professional_id: `${professionalID}`, //1545,
    session_note_id: +id, //46
  };

  try {
    const response = await fetch(
      `https://us.uwc.world/new-uwc/session-notes/get-notes`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const postResponse = await response.json();
    //console.log("postResponse", postResponse)

    return postResponse.data;
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};

export const getTranscript = async (
  appointment_id,
  user_id,
  expert_id,
  appointment_date,
  booking_date,
  audio_data
) => {
  const transcriptHeaders = {
    //"Content-Type": "application/json",
    accept: "application/json",
  };

  // const body = {
  //   audio_data_base64: base64data,
  //   session_id: `${appointment_id}`,
  //   appointment_id: `${appointment_id}`,
  //   user_id: `${user_id}`,
  //   expert_id: `${expert_id}`,
  //   appointment_date: `${appointment_date}`,
  //   booking_date: `${booking_date}`,
  //   speaker_id: `${expert_id}`,
  // };
  const formData = new FormData();
  formData.append("audio_data_base64", audio_data);
  formData.append("session_id", `${appointment_id}`);
  formData.append("appointment_id", `${appointment_id}`);
  formData.append("user_id", `${user_id}`);
  formData.append("expert_id", `${expert_id}`);
  formData.append("appointment_date", `${appointment_date}`);
  formData.append("booking_date", `${booking_date}`);
  formData.append("speaker_id", `${expert_id}`);

  try {
    const response = await fetch(`https://newtts.uwc.world/transcribe`, {
      method: "POST",
      headers: transcriptHeaders,
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const postResponse = await response.json();
    console.log("postResponse", postResponse);

    return postResponse;
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};

export const getTranslation = async (appointment_id,
  user_id,
  expert_id,
  appointment_date,
  booking_date,
  transcript) => {
  const body = {
    target: "en-Us",
    text: transcript,
    session_id: `${appointment_id}`,
    appointment_id: `${appointment_id}`,
    user_id: `${user_id}`,
    expert_id: `${expert_id}`,
    appointment_date: `${appointment_date}`,
    booking_date: `${booking_date}`,
    speaker_id: `${expert_id}`,
  };

  try {
    const response = await fetch(`https://newtts.uwc.world/translate`, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const postResponse = await response.json();
    console.log("getTranslation postResponse", postResponse);
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};


export const OCRAppend = async ({ sessionData, ocrText }) => {
  const body = {
    // target: "en-Us",
    ocr_generated_text: ocrText,
    session_id: `${sessionData?.appointment_id}`,
    appointment_id: `${sessionData?.appointment_id}`,
    user_id: `${sessionData?.user_id}`,
    expert_id: `${sessionData?.expert_id}`,
    appointment_date: `${sessionData?.appointment_date}`,
    booking_date: `${sessionData?.booking_date}`,
    speaker_id: `${sessionData?.expert_id}`,
  };

  try {
    const response = await fetch(`https://ocr.uwc.world/ocr-append`, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const postResponse = await response.json();
    console.log("getTranslation postResponse", postResponse);
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};

export const OCRDocumentTranslation = async (
  // appointment_id,
  // user_id,
  // expert_id,
  // appointment_date,
  // booking_date,
  url,
  // transcript
) => {
  const body = {
    // target: "en-Us", 
    // session_id: `${appointment_id}`,
    // appointment_id: `${appointment_id}`,
    // user_id: `${user_id}`,
    // expert_id: `${expert_id}`,
    // appointment_date: `${appointment_date}`,
    // booking_date: `${booking_date}`,
    // speaker_id: `${expert_id}`,
    url: `${url}`
  };

  try {
    const response = await fetch(`https://ocr.uwc.world/ocr-url`, {
      method: "POST",
      headers: {
        "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const postResponse = await response.json();
    console.log("getTranslation postResponse", postResponse);
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};

export const fetchSTTWhisper = async (data) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://s2t-v2.uwc.world/transcribe-v2",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic YWRtaW46WDlyJDN2ITJ3UXorOHBG",
      },
      data,
    };

    const resp = await axios.request(config);

    return resp?.data?.transcription

  } catch (error) {
    console.error("Whisper API error", error)
  }

}