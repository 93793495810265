import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { InputBase, IconButton, Checkbox } from "@mui/material";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TablePagination from "@mui/material/TablePagination";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { LineChart } from '@mui/x-charts/LineChart';

//icons
import { AccessTime, Diversity1Outlined } from "@mui/icons-material";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import TuneIcon from "@mui/icons-material/Tune";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import HeadsetIcon from "@mui/icons-material/Headset";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";


const HistoryContent = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [progressDuration, setProgressDuration] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [historyData, setHistoryData] = useState([]);
  useEffect(() => {
    if (data?.length > 0) {
      const flattenedData = data.flatMap(innerArray => {
        return innerArray.flatMap(subArray => {
          return subArray;
        });
      });

      setHistoryData(flattenedData);
    }
  }, [data])


  const handleChangePage = (event, newPage) => {
    if (newPage >= 0 && newPage <= Math.ceil(historyData.length / rowsPerPage)) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleProgressChange = (event) => {
    setProgressDuration(event.target.value);
  };

  function formatDate(date) {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    // Function to get the ordinal suffix for the day
    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th"; // for 4th to 20th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}` ===
      "NaNth Invalid Date, NaN"
      ? formatDate(new Date())
      : `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  }

  function removeHtmlTags(text) {
    return text?.replace(/<[^>]*>/g, '');
  }
  function getCurrentTime(date) {
    var now = new Date(date);
    var hours = now.getHours();
    var minutes = now.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    minutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero if minutes < 10
    var timeString = hours + ':' + minutes + ' ' + ampm;
    return timeString;
  }

  return (
    <div>
      <button
        style={{
          background: "var(--primary-accent-color)",
          color: "var(--primary-color)",
          height: "40px",
        }}
        className="d-flex align-items-center p-2 px-4 gap-2 rounded-2 border-0"
        onClick={handleClickOpen}
      >
        <AccessTime />
        History
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="recommender-history-dialog"
      >
        <DialogTitle
          className="d-flex align-items-center justify-content-between border-top-1"
          style={{
            border: "1px solid #E6E6E9",
            background: "#F8F8F8",
          }}
        >
          <p className="fw-bold">
            Recommend History <span className="fw-semibold">(Content)</span>
          </p>
          {/* <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              margin: "10px 0",
              width: 300,
            }}
            className="col-6"
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search through Contents"
              value={inputValue}
              onChange={handleChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <KeyboardVoiceOutlinedIcon />
            </IconButton>
          </Paper> */}
        </DialogTitle>
        {historyData?.length < 1 ? <DialogContent className="d-flex align-items-center justify-content-center font-inter fw-semibold">
          No History Data Available
        </DialogContent>
          : <DialogContent className="my-4">
            {historyData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item, index) => (
              <div
                key={index}
                className="py-3 px-1 lh-lg"
                style={{
                  boxShadow: "0px 1px 0px 0px #E6E6E9, 0px -1px 0px 0px #E6E6E9",
                }}
              >
                <p
                  style={{ fontSize: "16px", fontWeight: "400" }}
                  className="my-2"
                >
                  {" "}
                  Recommended on:{" "}
                  <span className="fw-semibold">
                    {formatDate(new Date(item?.recommended_date))}
                  </span> by{" "}
                  <span className="fw-semibold">{item?.recommended_by}</span>
                  {item?.expertType && `(
                ${item?.expertType})`}
                </p>
                <div
                  className="d-flex gap-4 p-2"
                  style={{ borderRadius: "6px", border: "1px solid #E6E6E9" }}
                >
                  <div className="position-relative">
                    <img
                      src={item?.data?.imageUrl ||
                        "/assets/img/Get-started-with-Mindfulness.png"}
                      alt=""
                      style={{
                        width: "100px",
                        height: "100%",
                        borderRadius: "6px",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "white",
                        zIndex: "100",
                      }}
                    >
                      {item?.type === "video" ? (
                        <>
                          <PlayCircleOutlineIcon />
                        </>
                      ) : item?.type === "audio" ? (
                        <>
                          <HeadsetIcon />
                        </>
                      ) : item?.type === "podcast" ? (
                        <>
                          <AutoStoriesOutlinedIcon />
                        </>
                      ) : (
                        "All"
                      )}
                    </div>
                  </div>

                  <div className="">
                    <div
                      className="d-flex align-items-center gap-2 fw-semibold"
                      style={{ fontSize: "18px" }}
                    >
                      {item?.data?.title
                        ? item.data.title.charAt(0).toUpperCase() +
                        item.data.title.slice(1)
                        : ""}
                    </div>

                    <div

                      className="d-flex align-items-center gap-2"
                    >
                      <div> {item?.type
                        ? item?.type.charAt(0).toUpperCase() +
                        item?.type.slice(1)
                        : ""}</div>
                      <div>
                        <AccessTimeOutlinedIcon sx={{ fontSize: "16px" }} /> {item?.data?.duration}
                      </div>
                    </div>
                    <div
                      className="fw-normal"
                      style={{
                        color: "#252422",
                      }}
                    >
                      # {item?.data?.category?.name}
                    </div>
                  </div>
                </div>
                <div className="my-3">
                  <p>Recommended Schedule: </p>
                  <div
                    className="w-100 p-2 rounded-1"
                    style={{
                      border: "1px solid #E6E6E9",
                    }}
                  >
                    Time: <span className=" fw-semibold">{getCurrentTime(item?.time)}</span>
                    {", "}
                    Start Date: <span className=" fw-semibold">{formatDate(new Date(item?.start_date))}</span>
                    {", "}
                    End Date: <span className=" fw-semibold">{formatDate(new Date(item?.end_date))}</span>
                    {", "}
                    Frequency: <span className=" fw-semibold">{item?.frequency}</span>
                  </div>
                </div>

                {item?.note_from_expert != "" && (
                  <div className="my-2 p-1 lh-lg">
                    <p className="my-2 fw-semibold" style={{ color: "#080807" }}>
                      Note From Expert :{" "}
                    </p>
                    <div
                      className="d-flex gap-2 p-2 rounded-1"
                      style={{
                        border: "1px solid #E6E6E9",
                      }}
                    >
                      {(removeHtmlTags(item?.note_from_expert || ""))?.length > 0 ? removeHtmlTags(item?.note_from_expert)?.replace("&nbsp;", "") : "No Note"}
                    </div>
                  </div>
                )}

                {item?.followedUp ? (
                  <>
                    <div className="my-2 w-100">
                      <p
                        style={{
                          color: "#080807",
                        }}
                        className="my-2 fw-normal"
                      >
                        Engagement Details{" "}
                      </p>
                      {item.engagementDetails &&
                        item.engagementDetails.map((item, index) => (
                          <Accordion
                            key={index}
                            sx={{
                              borderRadius: "6px",
                              border: "1px solid #D5D4D2",
                              margin: "10px 0",
                              width: "100%",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<KeyboardArrowDownOutlinedIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <div>Engagement {item.id}</div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div style={{ color: "#807C75" }}>
                                <p>
                                  Date Engaged:{" "}
                                  <span style={{ color: "#080807" }}>
                                    {item.dateEngaged}
                                  </span>
                                </p>
                                <p>
                                  Duration:{" "}
                                  <span style={{ color: "#080807" }}>
                                    {item.duration}
                                  </span>
                                </p>
                                <p>
                                  Completion:{" "}
                                  <span style={{ color: "#080807" }}>
                                    {item.completion}
                                  </span>
                                </p>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </div>
                  </>
                ) : (
                  <div className="my-2 fw-semibold" style={{
                    color: "#080807",
                  }}>
                    Followed up{" "}
                    <span
                      style={{
                        borderRadius: "1000px",
                        background: "#E9E8E7",
                        color: "#4E4E4E",
                      }}
                      className="p-2"
                    >
                      No
                    </span>
                  </div>
                )}
              </div>
            ))}

            <div>
              <TablePagination
                component="div"
                count={historyData?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </DialogContent>}
      </Dialog>
    </div>
  );
};

export default HistoryContent;
