import { Save, Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useContext } from "react";
import { AppContext } from "../../../../context";
import { CaseHistory, Followupsummary, Prescription, SessionNote } from "../ClinicalNotesAPI/DropdownData";

function HideUnhideHandler({ status, field }) {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);

  const handleVisibilityChange = () => {
    const initTemplate = expertClinicalNotesData?.data?.template;
    const initTab = expertClinicalNotesData?.data?.tab;
    let updatedTemplate;
    let updatedTab;
    if (status) {
      updatedTemplate = initTemplate.filter((item) => item.name !== field);
    } else {
      updatedTemplate = [
        ...initTemplate,
        {
          name: field,
        },
      ];
    }

    // Determine which tabs should be visible based on the updated template
    const tabVisibilityMap = {
      "Session Note": SessionNote.map((item) => item.id),
      "Follow-Up Summary": Followupsummary.map((item) => item.id),
      "Case History": CaseHistory.map((item) => item.id),
      Prescription: Prescription.map((item) => item.id),
    };

    updatedTab = Object.keys(tabVisibilityMap).filter((tabName) =>
      updatedTemplate.some((template) =>
        tabVisibilityMap[tabName].includes(template.name)
      )
    );

    // Update the state with the new template and tab visibility
    setExpertClinicalNotesData((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        template: updatedTemplate,
        tab: updatedTab,
      },
    }));
  };

  return (
    <>
      {" "}
      {expertClinicalNotesData?.editTemplate && (
        <span role="button" onClick={handleVisibilityChange}>
          {status ? (
            <Visibility sx={{ color: "#17B26A" }} fontSize="small" />
          ) : (
            <VisibilityOff sx={{ color: "#F04438" }} fontSize="small" />
          )}
        </span>
      )}
    </>
  );
}

export default HideUnhideHandler;
