import React, { useContext, useEffect, useState } from "react";
import { template } from "./SVGs";
import { format, formatDistanceToNow } from "date-fns";
import { FetchAuditLog } from "../ClinicalNotesAPI/ClinicalNoteAPIManager";
import { AppContext } from "../../../../context";
import { IconButton, Skeleton } from "@mui/material";
import { Close } from "@mui/icons-material";
import jwtDecode from "jwt-decode";
function AuditLogs({ close }) {
  const [loading, setLoading] = useState(true);
  const [auditLogs, setAuditLogs] = useState([]);
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);

  useEffect(() => {
    if (!expertClinicalNotesData?.headerData?.session_id) return;
    fetchlogs();
  }, []);

  const fetchlogs = async () => {
    const response = await FetchAuditLog(
      expertClinicalNotesData?.headerData?.session_id
    );
    if (response?.data?.length > 0) {
      const log = jwtDecode(response?.data);
      setAuditLogs(log);
    } else {
      setAuditLogs([]);
    }

    setLoading(false);
  };

  const getIconByActionType = (actionType) => {
    const templateItem = template.find((item) => item.name === actionType);
    return templateItem ? templateItem.path : null;
  };

  const getFormattedOffset = () => {
    const date = new Date();
    const offsetMinutes = date.getTimezoneOffset(); // Get the offset in minutes
    const sign = offsetMinutes > 0 ? "-" : "+"; // Determine the sign of the offset

    // Convert the absolute value of offset minutes to hours and minutes
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMins = Math.abs(offsetMinutes) % 60;

    // Format the offset into +HH:MM or -HH:MM
    const formattedOffset = `${sign}${String(offsetHours).padStart(
      2,
      "0"
    )}:${String(offsetMins).padStart(2, "0")}`;

    return formattedOffset;
  };

  // Function to format the timestamp considering the timezone offset
  const formatTimestampWithOffset = (timestamp) => {
    // Parse the timestamp into a Date object
    let date = new Date(timestamp);

    // Get the current timezone offset
    const offset = getFormattedOffset();

    // Adjust the date based on the offset (if necessary)
    // The backend timestamp might already account for offset, so this step may not be required depending on your setup.
    const offsetHours = parseInt(offset.split(":")[0], 10);
    const offsetMinutes = parseInt(offset.split(":")[1] || 0, 10);
    const offsetInMilliseconds = (offsetHours * 60 + offsetMinutes) * 60000;

    // Adjust date based on offset
    date = new Date(date.getTime() + offsetInMilliseconds);

    const now = new Date(); // Get the current time

    const differenceInHours = Math.abs((now - date) / 36e5); // Calculate the difference in hours

    if (differenceInHours < 24) {
      // If the difference is less than 24 hours, return a "time ago" format
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      // Otherwise, return a formatted date
      return format(date, "MMM d, yyyy 'at' h:mm a");
    }
  };

  function extractTextInParentheses(text) {
    // Regular expression to find text inside parentheses
    const regex = /\(([^)]+)\)/;
    const matches = text.match(regex);

    // Check if there are matches and return the first one
    if (matches && matches[1]) {
      return matches[1];
    } else {
      return null; // or return an empty string, depending on your needs
    }
  }
  return (
    <div>
      <p className="m-0 fs-5 d-flex justify-content-between align-items-center fw-semibold">
        Audit Trail{" "}
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </p>
      <p className="m-0 text-muted">
        View a history of actions performed on your clinical note.
      </p>
      <div className="mt-4">
        {loading &&
          Array.from(new Array(3)).map((_, index) => (
            <div
              className="d-flex p-3 py-2 shadow-sm flex-column my-2 gap-2 border rounded-2"
              key={index}
            >
              <div className="d-flex flex-column flex-md-row justify-content-between">
                <div className="d-flex align-items-center gap-2">
                  <Skeleton variant="circular" width={24} height={24} />
                  <Skeleton variant="text" width="150px" height="20px" />
                </div>
                <Skeleton
                  variant="rectangular"
                  width="100px"
                  height="24px"
                  style={{ borderRadius: "12px" }}
                />
              </div>
              <Skeleton variant="text" width="100%" height="20px" />
            </div>
          ))}
        {!loading &&
          auditLogs?.map((item) => (
            <div
              className="d-flex p-3 py-2 flex-column my-2 gap-2 border rounded-2"
              key={item.timestamp}
            >
              <div className="d-flex flex-column gap-1 flex-md-row justify-content-between">
                <div className="d-flex align-items-center gap-2">
                  {getIconByActionType(item.action_type)}
                  <p className="m-0 fs-6 fw-semibold">
                    {item?.action_type?.charAt(0)?.toUpperCase() +
                      item?.action_type?.slice(1)?.replace(/_/g, " ")}
                  </p>
                </div>
                <div>
                  <span
                    style={{ background: "#b6b6bb40", fontSize: "12px" }}
                    className="m-0 px-2 py-1 rounded-pill"
                  >
                    {formatTimestampWithOffset(item.timestamp)}
                  </span>
                </div>
              </div>
              {item.action_type === "uploaded" ? (
                <p className="m-0 text-break">
                  You uploaded{" "}
                  <a
                    style={{ color: "var(--primary-color)" }}
                    href={extractTextInParentheses(item.message_template)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.message_template.match(/You uploaded (.+) \(/)[1]}
                  </a>
                </p>
              ) : (
                <p className="m-0 text-break">{item.message_template}</p>
              )}
            </div>
          ))}
        {!loading && auditLogs?.length === 0 && (
          <div className="d-flex flex-column align-items-center justify-content-center gap-2">
            <img
              width={200}
              alt="No credits"
              src="/assets/img/no-worksheets.png"
            />
            <p className="m-0 text-muted">No audit trail found</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default AuditLogs;
