import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

import ClientInfoPanelClinicalRoute from "./ClientInfoPanelClinicalRoute";
import jwtDecode from "jwt-decode";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import SessionOverview from "./ClientComponents/SessionOverview";
import ClinicalExamination from "./ClientComponents/ClinicalExamination";
import CaseHistory from "./ClientComponents/CaseHistory";
import Documents from "./ClientComponents/Documents";
import FollowUpnote from "./ClientComponents/FollowUpnote";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const ClientDetailsViewClinicalRoute = ({ clientDetails }) => {
  const [clientViewDetails, setClientViewDetails] = useState({});
  const [clientData, setClientData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    if (clientDetails?.id) {
      setClientViewDetails(clientDetails);
      const storedData = localStorage.getItem("Authorization");
      const profile = JSON.parse(localStorage.getItem("profile"));
      const token = storedData ? storedData?.split("Bearer ")[1] : null;
      const FetchClinicalNotesClients = async (professional_id, client_id, session,) => {

        try {
          const response = await fetch(
            `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/list?professional_id=${professional_id}&client_id=${client_id}&status=all`,
            {
              method: "GET",
              headers: {
                "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
                "Content-Type": "application/json",
                token: token,
              },
            }
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const resp = await response.json();
          if (resp.status === "success") {
            const clientData = jwtDecode(resp.data);
            setClientData(clientData);
          } else {
            enqueueSnackbar(resp.message, { variant: "info" });
          }

        } catch (error) {
          console.error(error);
        }
      };
      FetchClinicalNotesClients(profile.UserId, clientDetails?.id);
    }

  }, [clientDetails?.id]);
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabsArray = [
    "Clinical Notes",
    // "Clinical Examination",
    // "Follow-Up Note",
    // "Case History",
    "Documents"
  ];
  const tabComponents = {
    "Clinical Notes": {
      component: <SessionOverview clientData={clientData} />,
      name: "Clinical Notes",
    },
    "Clinical Examination": {
      component: <ClinicalExamination />,
      name: "Clinical Examination",
    },
    "Follow-Up Note": {
      component: <FollowUpnote />,
      name: "Follow-Up Note",
    },
    "Case History": {
      component: <CaseHistory />,
      name: "Case History",
    },
    "Documents": {
      component: <Documents clientDetails={clientDetails} />,
      name: "Documents",
    }
  };

  const renderedTabs = tabsArray
  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={12}>
          <ClientInfoPanelClinicalRoute clientDetails={clientViewDetails} />
        </Grid>


        <Grid item xs={12}>

          <div
            className="mt-4 rounded rounded-4 position-relative border"
            style={{ backgroundColor: "white", overflowX: "auto" }}
          >
            <div className="SessionDashboard--TabsContainer px-md-4">
              <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                className="SessionDashboard--Tabs"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                {renderedTabs.map((tab, index) => (
                  <Tab
                    key={index}
                    id={tab.replace(/\s+/g, "")} // Use a sanitized version of the tab name for id
                    label={tabComponents[tab].name} // Corrected to fetch the name from the tabComponents object
                  />
                ))}
              </Tabs>
            </div>

            {renderedTabs.map((tab, index) => (
              <CustomTabPanel key={index} value={selectedTab} index={index}>
                <div
                  style={{ paddingBottom: "100px" }}
                  className="SessionTab--Panel p-2 p-md-4 py-4 SessionSessionNote--Panel"
                >
                  {tabComponents[tab].component}
                </div>
              </CustomTabPanel>
            ))}
          </div>
        </Grid>

      </Grid>
    </>
  );
};

export default ClientDetailsViewClinicalRoute;
