import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context";

// Components
import FollowUpNoteSkeleton from "./Skeletons/FollowUpNoteSkeleton";
import FollowUpNoteDialog from "./Dialogs/FollowUpNoteDialog";
import NotesSkeltonLoader from "./Skeletons/NotesSkeltonLoader";
import TextEditor from "./Components/TextEditor";
import CNReviewBar from "./CNReviewBar";
import TextEditorCommonSkeleton from "./Skeletons/TextEditorCommonSkeleton";

// MUI
import Button from "@mui/material/Button";
import HideUnhideHandler from "./Components/HideUnhideHandler";
import { Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

function CNFollowUpSummary() {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleViewMore = () => {
    setOpen(true);
  };

  // Generalized function to render TextEditor components
  const renderTextEditor = (key, labelText, tooltipText) => {
    const initialText =
      expertClinicalNotesData?.data?.follow_up_summary?.[key]?.text || "";

    return (
      <div
        className={`w-100 ${
          !expertClinicalNotesData?.data?.template?.some(
            (item) => item.name === key
          ) &&
          !expertClinicalNotesData?.editTemplate &&
          "d-none"
        }`}
      >
        <h6>
          {labelText}{" "}
          {/* {" Completed:" +
            expertClinicalNotesData?.data?.follow_up_summary?.[key]?.completed +
            " total:" +
            expertClinicalNotesData?.data?.follow_up_summary?.[key]?.total +
            " current:" +
            expertClinicalNotesData?.data?.follow_up_summary?.[key]?.current} */}
          <HideUnhideHandler
            status={expertClinicalNotesData?.data?.template?.some(
              (item) => item.name === key
            )}
            field={key}
          />
          <Tooltip
            arrow
            title={
              <p className="m-0" style={{ fontSize: "14px", color: "white" }}>
                {tooltipText}
              </p>
            }
            placement="top-start"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "common.black",
                  "& .MuiTooltip-arrow": {
                    color: "common.black",
                  },
                  color: "common.white",
                  padding: "10px",
                },
              },
            }}
          >
            <InfoOutlined sx={{ fontSize: "16px", color: "black" }} />
          </Tooltip>
        </h6>

        <div
          className={`${
            !expertClinicalNotesData?.data?.template?.some(
              (item) => item.name === key
            ) && "d-none"
          }`}
        >
          <div className="w-100 position-relative ">
            {expertClinicalNotesData?.data?.follow_up_summary?.[key]
              ?.display === "false" ? (
              <NotesSkeltonLoader />
            ) : (
              <>
                {" "}
                {!expertClinicalNotesData?.data?.follow_up_summary?.[key] ? (
                  <TextEditorCommonSkeleton />
                ) : (
                  <>
                    <TextEditor
                      initialText={initialText}
                      disabled={
                        expertClinicalNotesData?.data?.submitted ||
                        expertClinicalNotesData?.editTemplate
                      }
                      htmlText={(text) => {
                        setExpertClinicalNotesData((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState?.data,
                            follow_up_summary: {
                              ...prevState?.data?.follow_up_summary,
                              [key]: {
                                ...prevState?.data?.follow_up_summary[key],
                                text: text,
                              },
                            },
                          },
                        }));
                      }}
                    />

                    {expertClinicalNotesData?.data?.follow_up_summary?.[key]
                      ?.accepted !== "true" &&
                      initialText !== "" &&
                      initialText !== "<p></p>\n" &&
                      initialText != null && (
                        <CNReviewBar
                          section_={"follow_up_summary"}
                          key_={key}
                        />
                      )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  function removeHtmlTags(text) {
    return text?.replace(/<[^>]*>/g, "")?.replace(/&nbsp;/g, "");
  }
  return (
    <div className="p-lg-4">
      <div className="mb-4">
        {isLoading ? (
          <FollowUpNoteSkeleton />
        ) : (
          <>
            <div className="gap-4 my-1 d-flex flex-column flex-md-row justify-content-md-between">
              {expertClinicalNotesData?.followUpNotes?.length > 0 ? (
                expertClinicalNotesData?.followUpNotes
                  ?.slice(0, 2)
                  ?.map((data, index) => (
                    <div
                      key={index}
                      className="rounded-1 p-3 w-100 lh-md mb-2"
                      style={{
                        border: "1px solid #D0D5DD",
                        width: "calc(50% - 16px)",
                      }}
                    >
                      <div className="d-flex justify-content-between flex-wrap fw-semibold">
                        <p>{data?.title?.replace("()", "")}</p>
                        <div className="d-flex gap-1">
                          <img
                            src="/assets/img/calendar_timer.jpg"
                            width={24}
                            height={24}
                            alt="calendar"
                          />
                          {data?.date}
                        </div>
                      </div>

                      <div
                        className="rounded-1 p-2 mt-2"
                        style={{
                          border: "1px solid #E6E6E9",
                          background: "#F8F8F8",
                        }}
                      >
                        {removeHtmlTags(data?.note)?.length > 120
                          ? removeHtmlTags(data?.note)?.substring(0, 120) +
                            "..."
                          : removeHtmlTags(data?.note) || "No notes available"}
                      </div>

                      <div className="fw-bold mt-2">{data?.note_summary}</div>
                    </div>
                  ))
              ) : (
                <div className="fw-bold">No Notes Available</div>
              )}
            </div>
          </>
        )}

        {expertClinicalNotesData?.followUpNotes?.length > 2 && (
          <div className="d-flex justify-content-end">
            <Button className="PrimaryCTA---ALT" onClick={handleViewMore}>
              View More
            </Button>
          </div>
        )}
      </div>

      <FollowUpNoteDialog open={open} setOpen={() => setOpen(false)} />
      <div className="d-flex flex-column flex-lg-row gap-4">
        {renderTextEditor(
          "compliance",
          "Treatment Adherence",
          "The patient's compliance with your treatment plan since the last session."
        )}
        {renderTextEditor(
          "treatment_plan_progress",
          "Treatment Plan Progress",
          "Updates on how your treatment is proceeding."
        )}
      </div>
      <div className="d-flex flex-column flex-lg-row gap-4 mt-4">
        {renderTextEditor(
          "assessment_summary",
          "Assessment Summary",
          "The results of any self-assessments taken by the patient on our app."
        )}
        {renderTextEditor(
          "worksheet_journal_summary",
          "Worksheet/Journal Summary",
          "A summary of any worksheets or journals filled by the patient on our app."
        )}
      </div>

      <div className="w-100 flex-column flex-lg-row mt-4">
        {renderTextEditor(
          "stella_sos_summary",
          "Stella SOS Summary",
          "Things the patient said on our app that registered as particularly alarming or as requiring an emergency response."
        )}
      </div>
    </div>
  );
}

export default CNFollowUpSummary;
