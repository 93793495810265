import React, { useContext } from "react";
import { AppContext } from "../../../../context";

// Component
import TextEditor from "./TextEditor";

// MUI
import { FormControl, Grid, MenuItem, Select, TextField } from "@mui/material";
import { DoNotDisturbOnOutlined } from "@mui/icons-material";

function RecommendExpertListView({ download }) {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  const handleRemoveExpert = (indexToRemove) => {
    setExpertClinicalNotesData((prev) => {
      return {
        ...prev,

        recommendation: {
          ...prev?.recommendation,
          recommended_expert: prev?.recommendation?.recommended_expert?.filter(
            (item, i) => i !== indexToRemove
          ),
        },
      };
    });
  };
  const handleTimeChange = (event, index, type, key) => {
    const value = event.target.value;
    setExpertClinicalNotesData((prev) => ({
      ...prev,

      recommendation: {
        ...prev.recommendation,
        [key]: prev.recommendation[key].map((item, i) => {
          if (i === index) {
            const [number, unit] = item[
              key === "recommended_expert" ? "consult_in" : "complete_in"
            ]?.split(" ") || ["", ""];
            return {
              ...item,
              [key === "recommended_expert" ? "consult_in" : "complete_in"]:
                type === "number"
                  ? `${value.replace(/\D/g, "")} ${unit}`
                  : `${number} ${value}`,
            };
          }
          return item;
        }),
      },
    }));
  };

  return (
    <div className="mt-3">
      {" "}
      {expertClinicalNotesData?.recommendation?.recommended_expert?.length >
        0 && (
        <div>
          <Grid
            container
            spacing={3}
            className="pb-lg-5 p-0  "
            sx={{
              backgroundColor: "#FFF",
              marginLeft: 0,
              marginRight: 0,

              width: "100%",
            }}
          >
            {expertClinicalNotesData?.recommendation?.recommended_expert?.map(
              (data, index) => (
                <Grid
                  item
                  className="p-1 pt-3 p-lg-3"
                  xs={12}
                  lg={download ? 12 : 6}
                >
                  <div
                    style={{ background: "#F8F8F8" }}
                    className="p-3 rounded-3"
                  >
                    <div className="d-flex  justify-content-between ">
                      <div className="d-flex">
                        <img
                          src={
                            data?.ProfileImage ||
                            "/assets/img/elements/therapist_dummy.png"
                          }
                          width={78}
                          height={78}
                          className="rounded cover rounded-3"
                          alt=""
                        />
                        {data?.recommended ? (
                          <p className="fw-bold m-0 d-flex align-items-center">
                            {data?.Name}
                          </p>
                        ) : (
                          <div className="px-2">
                            <p className="fw-bold m-0">{data?.Name}</p>
                            <p className="m-0" style={{ fontSize: "14px" }}>
                              {data?.Experience}+ Years of Experience
                            </p>
                            <p
                              style={{ fontSize: "14px" }}
                              className="fw-bold m-0"
                            >
                              {data?.RegisterYourselfAs}
                            </p>
                          </div>
                        )}
                      </div>
                      {!expertClinicalNotesData?.data?.submitted &&
                        !download && (
                          <div
                            role="button"
                            onClick={() => handleRemoveExpert(index)}
                          >
                            <DoNotDisturbOnOutlined sx={{ color: "var(--primary-color)" }} />
                          </div>
                        )}
                    </div>
                    <p className="mt-3 mb-0 fw-bold">Consult in</p>
                    <div className="d-flex align-items-center gap-2">
                      <TextField
                        size="small"
                        fullWidth
                        label=""
                        disabled={
                          expertClinicalNotesData?.data?.submitted || download
                        }
                        placeholder="0"
                        sx={{ background: "white" }}
                        value={data?.consult_in?.split(" ")[0] || ""} // Use state to store the input value
                        onChange={(event) => {
                          handleTimeChange(
                            event,
                            index,
                            "number",
                            "recommended_expert"
                          );
                        }}
                      />
                      <FormControl
                        size="small"
                        fullWidth
                        sx={{ background: "white" }}
                        className="my-3"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              data?.consult_in?.split(" ")[1] || "Frequency"
                            }
                            label=""
                            placeholder="Frequency..."
                            disabled={
                              expertClinicalNotesData?.data?.submitted ||
                              download
                            }
                            onChange={(event) => {
                              handleTimeChange(
                                event,
                                index,
                                "unit",
                                "recommended_expert"
                              );
                            }}
                          >
                            <MenuItem
                              value="Frequency"
                              disabled={
                                expertClinicalNotesData?.data?.submitted ||
                                download
                              }
                            >
                              Frequency
                            </MenuItem>
                            <MenuItem value={"hours"}>Hour(s)</MenuItem>
                            <MenuItem value={"days"}>Day(s)</MenuItem>
                            <MenuItem value={"weeks"}>Week(s)</MenuItem>
                            <MenuItem value={"months"}>Month(s)</MenuItem>
                            <MenuItem value={"years"}>Year(s)</MenuItem>
                          </Select>
                        </div>
                      </FormControl>
                    </div>

                    <TextEditor
                      hideToolbar={true}
                      placeholder={!download && "Add note to clinician..."}
                      initialText={data?.note_from_expert}
                      disabled={
                        expertClinicalNotesData?.data?.submitted || download
                      }
                      htmlText={(text) =>
                        setExpertClinicalNotesData((prev) => {
                          return {
                            ...prev,

                            recommendation: {
                              ...prev?.recommendation,
                              recommended_expert:
                                prev?.recommendation?.recommended_expert?.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        note_from_expert: text,
                                      };
                                    }
                                    return item;
                                  }
                                ),
                            },
                          };
                        })
                      }
                    />
                  </div>
                </Grid>
              )
            )}
          </Grid>
        </div>
      )}
    </div>
  );
}

export default RecommendExpertListView;
