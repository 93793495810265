import React from "react";

// MUI
import { Skeleton } from "@mui/material";
function BrowseFileSkeleton() {
  return (
    <div>
      {" "}
      <div className="d-flex justify-content-between align-items-center my-3">
        <div className="pl-2 d-flex gap-2">
          <Skeleton
            variant="rounded"
            width="320px"
            height="50px"
            className="rounded-3"
          />
          <Skeleton
            variant="rounded"
            width="95px"
            height="50px"
            className="rounded-3"
          />
        </div>
        <Skeleton
          variant="rounded"
          width="90px"
          height="35px"
          className="rounded-3"
        />
      </div>
    </div>
  );
}

export default BrowseFileSkeleton;
