
import React from "react";

// MUI
import { Skeleton } from "@mui/material";

function CaseHistoryDropdownSkeleton() {
  return (
    <div className="mt-4 d-flex flex-column gap-4">
      <div className="d-flex gap-4">
        <div className="w-100">
          <Skeleton
            variant="rounded"
            height="56px"
            className="rounded rounded-3"
          />
        </div>

        <div className="w-100">
          <Skeleton
            variant="rounded"
            height="56px"
            className="rounded rounded-3"
          />
        </div>
      </div>
      <div className="d-flex gap-4">
        <div className="w-100">
          <Skeleton
            variant="rounded"
            height="56px"
            className="rounded rounded-3"
          />
        </div>

        <div className="w-100">
          <Skeleton
            variant="rounded"
            height="56px"
            className="rounded rounded-3"
          />
        </div>
      </div>
      <div className="w-100">
        <Skeleton
          variant="rounded"
          height="56px"
          className="rounded rounded-3"
        />
      </div>
    </div>
  );
}

export default CaseHistoryDropdownSkeleton;
