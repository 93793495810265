import React, { useEffect, useState } from "react";

// MUI
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { InputBase, IconButton, Checkbox } from "@mui/material";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TablePagination from "@mui/material/TablePagination";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { LineChart } from "@mui/x-charts/LineChart";

//icons
import { AccessTime, Diversity1Outlined } from "@mui/icons-material";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];

const HistoryAssessment = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [progressDuration, setProgressDuration] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [historyData, setHistoryData] = useState([]);
  useEffect(() => {
    if (data?.length > 0) {
      const flattenedData = data.flatMap(innerArray => {
        return innerArray.flatMap(subArray => {
          return subArray;
        });
      });
     
      setHistoryData(flattenedData);
    }
  }, [data])


  // const [expertClinicalNotesData, setExpertClinicalNotesData] = useContext(AppContext);
  
  const handleChangePage = (event, newPage) => {
    if (newPage >= 0 && newPage <= Math.ceil(historyData.length / rowsPerPage)) { 
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleProgressChange = (event) => {
    setProgressDuration(event.target.value);
  };
  function formatDate(date) {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    // Function to get the ordinal suffix for the day
    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th"; // for 4th to 20th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}` ===
      "NaNth Invalid Date, NaN"
      ? formatDate(new Date())
      : `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  }

  function removeHtmlTags(text) {
    return text?.replace(/<[^>]*>/g, '');
  }
  function getCurrentTime(date) {
    var now = new Date(date);
    var hours = now.getHours();
    var minutes = now.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    minutes = minutes < 10 ? '0' + minutes : minutes; // Add leading zero if minutes < 10
    var timeString = hours + ':' + minutes + ' ' + ampm;
    return timeString;
  }
  return (
    <div>
      <button
        style={{
          background: "var(--primary-accent-color)",
          color: "var(--primary-color)",
          height: "40px",
        }}
        className="d-flex align-items-center p-2 px-4 gap-2 rounded-2 border-0"
        onClick={handleClickOpen}
      >
        <AccessTime />
        History
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="recommender-history-dialog"
      >
        <DialogTitle
          className="d-flex align-items-center justify-content-between rounded-top-1"
          style={{
            border: "1px solid #E6E6E9",
            background: "#F8F8F8",
          }}
        >
          <p className="fw-bold">
            Recommend History <span className="fw-semibold">(Assessment)</span>
          </p>
          {/* <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              margin: "10px 0",
              width: 300,
            }}
            className="col-6"
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search through Contents"
              value={inputValue}
              onChange={handleChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <KeyboardVoiceOutlinedIcon />
            </IconButton>
          </Paper> */}
        </DialogTitle>
        {historyData?.length < 1 ? <DialogContent className="d-flex align-items-center justify-content-center font-inter fw-semibold">
          No History Data Available
        </DialogContent>
          : <DialogContent className="my-4">
            {/* <div>
            <div
              style={{
                borderRadius: "6px",
                border: "1px solid #D1D5DB",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <div className="d-flex justify-content-between align-items-center p-2">
                <p className="col" style={{ color: "[#080807]" }}>Complete Assessment Analysis</p>
                <div className="col d-flex gap-2">
                  <FormControl fullWidth >
                    <InputLabel>Add Assessment</InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={progressDuration}
                      onChange={handleProgressChange}
                      fullWidth
                    >
                      <MenuItem value="PHQ 9">PHQ 9</MenuItem>
                      <MenuItem value="GAD 7">GAD 7</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel>Last Month</InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={progressDuration}
                      onChange={handleProgressChange}
                      fullWidth
                    >
                      <MenuItem value="Last Month">Last Month</MenuItem>
                      <MenuItem value="This Month">This Month</MenuItem>
                      <MenuItem value="This Week">This Week</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div>
                <LineChart
                  // width={800}
                  height={400}
                  className="col"
                  series={[
                    { data: pData, label: "pv" },
                    { data: uData, label: "uv" },
                  ]}
                  xAxis={[{ scaleType: "point", data: xLabels }]}
                />
              </div>
            </div>
          </div> */}
            {historyData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item, index) => (
              <div
                key={index}
                className="py-3 px-1 lh-lg"
                style={{
                  boxShadow: "0px 1px 0px 0px #E6E6E9, 0px -1px 0px 0px #E6E6E9",
                }}
              >
                <p className="my-2 fw-normal" >
                  {" "}
                  Recommended on:{" "}
                  <span className="fw-semibold">
                    {formatDate(new Date(item?.recommended_date))}
                  </span> by{" "}
                  <span className="fw-semibold">{item?.recommended_by}</span>
                  {item?.expertType && `(
                ${item?.expertType})`}
                </p>
                <div
                  className="d-flex gap-4 p-2 rounded-1"
                  style={{ border: "1px solid #E6E6E9" }}
                >
                  <img
                    src={item?.backgroundImageUrl}
                    alt=""
                    style={{
                      width: "100px",
                      borderRadius: "6px",
                    }}
                  />
                  <div className="">
                    <div
                      className="d-flex align-items-center gap-2 fw-semibold"
                    >
                      {item?.title}
                    </div>

                    <div
                      style={{ color: "#807C75" }}
                      className="d-flex align-items-center gap-4"
                    >
                      <div>
                        <ContentPasteOutlinedIcon /> {item?.questionsCount}{" question(s)"}
                      </div>
                      {/* <div>
                      <AccessTimeOutlinedIcon /> {item?.assessmentDuration}
                    </div> */}
                    </div>
                    <div
                      className="fw-normal"
                      style={{
                        color: "#252422",
                      }}
                    >
                      {item?.author}
                    </div>
                  </div>
                </div>
                <div className="my-3">
                  <p>Recommended Schedule: </p>
                  <div
                    className="w-100 p-2 rounded-1"
                    style={{
                      border: "1px solid #E6E6E9",
                    }}
                  >
                    Time: <span className=" fw-semibold">{getCurrentTime(item?.time)}</span>
                    {", "}
                    Start Date: <span className=" fw-semibold">{formatDate(new Date(item?.start_date))}</span>
                    {", "}
                    End Date: <span className=" fw-semibold">{formatDate(new Date(item?.end_date))}</span>
                    {", "}
                    Frequency: <span className=" fw-semibold">{item?.frequency}</span>
                  </div>
                </div>

                <div className="my-2 p-1 lh-lg">
                  <p className="my-2" style={{ color: "#080807" }}>Note From Expert : </p>
                  <div
                    className="d-flex gap-2 p-2 rounded-1"
                    style={{
                      border: "1px solid #E6E6E9",
                    }}
                  >
                    {(removeHtmlTags(item?.note_from_expert || ""))?.length > 0 ? removeHtmlTags(item?.note_from_expert)?.replace("&nbsp;","")  : "No Note"}
                  </div>
                </div>

                {item?.followedUp ? (
                  <>
                    <div
                      className="rounded-1"
                      style={{
                        border: "1px solid #D1D5DB",
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <p style={{ color: "#080807" }}>Progress Chart</p>

                        <FormControl sx={{ m: 1, minWidth: 80 }}>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={progressDuration}
                            onChange={handleProgressChange}
                            autoWidth
                          >
                            <MenuItem value="Last Month">Last Month</MenuItem>
                            <MenuItem value="This Month">This Month</MenuItem>
                            <MenuItem value="This Week">This Week</MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <div>
                        <LineChart
                          // width={800}
                          height={400}
                          className="col"
                          series={[
                            { data: pData, label: "pv" },
                            { data: uData, label: "uv" },
                          ]}
                          xAxis={[{ scaleType: "point", data: xLabels }]}
                        />
                      </div>
                    </div>

                    <div className="my-2 w-100">
                      <p
                        style={{
                          color: "#080807",
                        }}
                        className="my-2 fw-normal"
                      >
                        Assessment Details{" "}
                      </p>
                      {item?.assessmentDetails &&
                        item?.assessmentDetails.map((_item, index) => (
                          <Accordion
                            key={index}
                            sx={{
                              borderRadius: "6px",
                              border: "1px solid #D5D4D2",
                              margin: "10px 0",
                              width: "100%",
                              height: "fit-content",
                            }}
                            className=" h-auto"
                          >
                            <AccordionSummary
                              expandIcon={<KeyboardArrowDownOutlinedIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <div>Assessment {_item?.id}</div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div style={{ color: "#807C75" }}>
                                <p>
                                  Date Taken:{" "}
                                  <span style={{ color: "#080807" }}>
                                    {_item?.dateTaken}
                                  </span>
                                </p>
                                <p>
                                  Score:{" "}
                                  <span style={{ color: "#080807" }}>
                                    {_item?.score}
                                  </span>
                                </p>
                                <p>
                                  Results:{" "}
                                  <span style={{ color: "#080807" }}>
                                    {_item?.results}
                                  </span>
                                </p>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                    </div>
                  </>
                ) : (
                  <div style={{ color: "#080807" }} className="my-2">
                    Followed up{" "}
                    <span
                      style={{
                        borderRadius: "1000px",
                        background: "#E9E8E7",
                        color: "#4E4E4E",
                      }}
                      className="p-2"
                    >
                      No
                    </span>
                  </div>
                )}
              </div>
            ))}

            <div
              className="my-4 d-none gap-1 align-items-center fw-normal"
              style={{ color: "#080807" }}
            >
              Session Note :{" "}
              <button className="PrimaryCTA rounded rounded-5 p-3"> View</button>
            </div>

            <div>
              <TablePagination
                component="div"
                count={historyData?.length } 
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </DialogContent>}
      </Dialog>
    </div>
  );
};

export default HistoryAssessment;
