import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TicketDetailsTableRow from "./tables/TicketDetailsTableRow";
import Skeleton from "@mui/material/Skeleton";
//Helpers

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Pagination as SwiperPagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import {
  HelpOutline,
  InfoOutlined,
  PictureAsPdfOutlined,
  QuizOutlined,
  VideoLibraryOutlined,
} from "@mui/icons-material";
import RaiseTicketModalClinical from "./modals/RaiseTicketModalClinical";
import { Box, Breadcrumbs, Link, Pagination, PaginationItem, Tooltip, Typography } from "@mui/material";

const ExpertClinicalHelpCenterDB = () => {
  const [ticketsData, setTicketsData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fetchData, setFetchData] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const localView = new URLSearchParams(
    new URL(window.location.href).search
  ).get("status");
  const headers = {
    "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
    "Content-Type": "application/json",
  };

  const { currentUser } = useAuth();

  const navigate = useNavigate();

  const postTicketsData = async () => {
    setIsLoading(true);
    const payload = {
      user_id: currentUser?.UserId,
      page: page,
      count: 10,
      status: selectedTab
    };
    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/tickets`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      if (response.status !== 200) {
        throw new Error("Something went wrong!");
      }
      const postResponse = await response.json();
      if (postResponse?.code !== 200) return;
      const data = postResponse.data.tickets;

      setTicketsData(data);
      setTotalPages(postResponse.data.total_page_count);
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setFetchData(false);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (localView === "All Tickets") {
      setSelectedTab("");
    } else if (localView === "Ongoing") {
      setSelectedTab("New");
    } else if (localView === "Resolved") {
      setSelectedTab("Resolved");
    }
  }, [])

  //Get all existing tickets
  useEffect(() => {
    if (!currentUser?.UserId) return;

    if (fetchData) {
      postTicketsData();
    }
  }, [fetchData]);
  useEffect(() => {
    postTicketsData()
  }, [page, selectedTab])


  const handletabSelect = (val) => {
    if (val === "") {
      navigate(`?status=All Tickets`);
    } else if (val === "New") {
      navigate(`?status=Ongoing`);
    } else if (val === "Resolved") {
      navigate(`?status=Resolved`);
    }
    setSelectedTab(val);
    setPage(1)
  };


  const handleTicketStatus = () => {
    setFetchData(true);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="mt-3 ExpertHelpCenter--Dashboard">
      <div className="ExpertHelpCenter--Header d-flex gap-2 flex-column flex-md-row justify-content-md-between align-items-md-center">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              onClick={() => { navigate("/clinical/help-center"); setSelectedTab("") }}
              role="button"

              sx={{
                fontWeight: "500",
                fontSize: "20px",
                textDecoration: "none",
              }}
              id="help_center"
              className="d-flex text-secondary align-items-center justify-content-between gap-2 "
            >
              Help Center
            </Link>

            <Typography
              sx={{ fontWeight: "500", fontSize: "20px" }}
              color="text.primary"
            >
              {selectedTab === "New" ? "Ongoing" : selectedTab === "Resolved" ? "Resolved" : "All Tickets"}
            </Typography>
          </Breadcrumbs>
        </div>
        <RaiseTicketModalClinical isTickeSent={postTicketsData} />
      </div>
      <div style={{ overflowX: "auto" }} className="d-flex my-2">
        {[
          {
            name: "Documents",
            icons: (
              <PictureAsPdfOutlined sx={{ color: "var(--primary-color)", fontSize: 32 }} />
            ),
            title: "Documentation & Guides",
            description:
              "Find manuals, how-to PDFs, white papers, case studies, and other resources to help you use our services.",
            navigate: "/clinical/help-center/documents",
          },
          {
            name: "Videos",
            icons: (
              <VideoLibraryOutlined sx={{ color: "var(--primary-color)", fontSize: 32 }} />
            ),
            title: "Tutorials & Demos",
            description:
              "Watch guides, product demos, and customer testimonials to learn how to effectively use our services.",
            navigate: "/clinical/help-center/videos",
          },
          {
            name: "FAQs",
            icons: <QuizOutlined sx={{ color: "var(--primary-color)", fontSize: 32 }} />,
            title: "FAQs",
            description:
              "Get quick answers to common questions about our products, services, and policies.",
            navigate: "/clinical/help-center/faqs",
          },
        ].map((item, index) => (
          <div style={{ minWidth: "320px", }} className=" p-2 " key={index}>
            <div
              style={{ height: "180px" }}
              role="button"
              onClick={() => navigate(item.navigate)}
              id={`help_center_${item.name}`}
              className="help_center_content rounded-4 Help-center--card shadow-sm border  p-4 d-flex gap-2 justify-content-center flex-column "
            >
              {item.icons}
              <p className=" fw-semibold m-0">{item.title}</p>
              <p className=" text-muted m-0 lh-sm ">{item.description}</p>
            </div>
          </div>
        ))}
      </div>



      <div className="ExpertHelpCenter--Header">
        <h2 className="d-flex gap-2 align-items-center">Tickets
          <Tooltip
            arrow
            title={
              <p className="m-0" style={{ fontSize: "14px", color: "white" }}>
                The feedback, complaints, and suggestions you submit are listed here
              </p>
            }
            placement="top-start"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "common.black",
                  "& .MuiTooltip-arrow": {
                    color: "common.black",
                  },
                  color: "common.white",
                  padding: "10px",
                },
              },
            }}
          >
            <InfoOutlined sx={{ fontSize: "16px", color: "black" }}/>
          </Tooltip></h2>
      </div>
      <div className=" ">
        <div
          style={{ background: "#EEEEEE", width: "350px", }}
          className="d-flex flex-wrap mt-2 gap-1 p-1 rounded-pill tickets"
        >
          <div
            style={{
              fontSize: "15px",
              fontWeight: 600,
              background: selectedTab === "" ? "white" : "",
              border: 0,
              color:
                selectedTab === ""
                  ? "var(--Primary-color)"
                  : "#898989",
            }}
            id="all_tickets"
            role="button"
            className={`py-1  px-4 rounded-pill all_tickets`}
            onClick={() => handletabSelect("")}
          >
            All Tickets
          </div>
          <div
            style={{
              fontSize: "15px",

              fontWeight: 600,
              background: selectedTab === "Resolved" ? "white" : "",
              border: 0,
              color:
                selectedTab === "Resolved" ? "var(--Primary-color)" : "#898989",
            }}
            role="button"
            id="resolved_tickets"
            className={`py-1 px-4 rounded-pill resolved_tickets`}
            onClick={() => handletabSelect("Resolved")}
          >
            Resolved
          </div>
          <div
            style={{
              fontSize: "15px",

              fontWeight: 600,
              background: selectedTab === "New" ? "white" : "",
              border: 0,
              color:
                selectedTab === "New" ? "var(--Primary-color)" : "#898989",
            }}
            role="button"
            id="ongoing_tickets"
            className={`py-1  px-4 rounded-pill ongoing_tickets`}
            onClick={() => handletabSelect("New")}
          >
            Ongoing
          </div>
        </div>
      </div>


      <div className="ExpertHelpCenter--Content border rounded-3  mt-3 padding-3">
        {isLoading ? (
          <TableContainer sx={{}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No</TableCell>
                  <TableCell>Topic</TableCell>
                  <TableCell>Detail</TableCell>
                  {/* <TableCell>Status</TableCell> */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(new Array(10)).map((_, index) => (
                  <TableRow key={index}
                    className={`TicketDetails--TableRow  SelectedTableRow
                    `}
                    sx={{
                      backgroundColor: "#FFF",
                      "&:last-child td, &:last-child th": { border: 0 },
                      maxWidth: "fit-content",
                    }}
                  >
                    <TableCell className="Table--SRNO">
                      <Skeleton
                        variant="text"
                        sx={{ width: "20px", height: "20px" }}
                        className=""
                      />
                    </TableCell>
                    <TableCell className="Table--Topic">
                      <Skeleton
                        variant="text"
                        sx={{ width: "120px", height: "24px" }}
                        className=""
                      />
                      <Skeleton
                        variant="text"
                        sx={{ width: "80px", height: "24px" }}
                        className=""
                      />
                    </TableCell>
                    <TableCell className="Table--Detail">
                      <Skeleton
                        variant="text"
                        sx={{ width: "400px", height: "24px" }}
                        className=""
                      />
                    </TableCell>
                    <TableCell className="Table--Actions">
                      {selectedTab === "Resolved" ? (
                        <Skeleton
                          variant="text"
                          sx={{ width: "75px", height: "50px" }}
                          className=""
                        />
                      ) : (
                        <div
                          className="d-flex align-items-center gap-2"
                          style={{ width: "max-width" }}
                        >
                          <Skeleton
                            variant="text"
                            sx={{ width: "75px", height: "50px" }}
                            className=""
                          />

                          <Skeleton
                            variant="text"
                            sx={{ width: "100px", height: "50px" }}
                            className=""
                          />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No</TableCell>
                  <TableCell>Topic</TableCell>
                  <TableCell>Detail</TableCell>
                  {/* <TableCell>Status</TableCell> */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ticketsData?.length === 0 ? (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <div className="NoDataAvailable">
                        <img src="/assets/img/no-worksheets.png" />
                        <p>No Tickets Available</p>
                      </div>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ) : (
                  ticketsData.map((item, index) => (
                    <TicketDetailsTableRow
                      key={item.serial_no}
                      ticketID={item.serial_no}
                      selectedTab={selectedTab}
                      date={item.date_time}
                      detail={item.detail}
                      serialNO={item.serial_no}
                      topic={item.topic}
                      status={item.status}
                      index={(page * 10) - 10 + index}
                      handleTicketStatus={handleTicketStatus}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <div className="d-flex justify-content-end mb-5 p-4">
        <Box
          sx={{
            "& .MuiPaginationItem-root": {
              "&.Mui-selected": {
                backgroundColor: "var(--primary-color)", // Selected page background color
                color: "#fff", // Selected page text color
              },
              "&:hover": {
                backgroundColor: "var(--primary-color)", // Hover background color
              },
            },
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChange}
            renderItem={(item) => <PaginationItem {...item} />}
          />
        </Box>
      </div>
    </div>
  );
};

export default ExpertClinicalHelpCenterDB;
