import React from 'react'
import { Skeleton } from '@mui/material'
function AddMedicationSkeleton() {
  return (
    <div className="p-2">
    {Array.from(new Array(1)).map((row, index) => (
      <div className="Table--Body gap-2 mt-2" key={index}>
        <div className="Table-span-3">
          <Skeleton
            variant="rounded"
            height="56px"
            className="rounded-3"
          />
        </div>
        <div className="Table-span-1">
          <Skeleton
            variant="rounded"
            height="56px"
            className="rounded-3"
          />
        </div>
        <div className="Table-span-2">
          <Skeleton
            variant="rounded"
            height="56px"
            className="rounded-3"
          />
        </div>
        <div className="Table-span-2">
          <Skeleton
            variant="rounded"
            height="56px"
            className="rounded-3"
          />
        </div>
        <div className="Table-span-2">
          <Skeleton
            variant="rounded"
            height="56px"
            className="rounded-3"
          />
        </div>
        <div className="Table-span-2">
          <Skeleton
            variant="rounded"
            height="56px"
            className="rounded-3"
          />
        </div>
      </div>
    ))}
    <Skeleton
      variant="rounded"
      width="120px"
      height="50px"
      className="rounded-3 my-3"
    />
  </div>
  )
}

export default AddMedicationSkeleton