import React from "react";
import { useNavigate } from "react-router-dom";

function SessionOverview({ clientData }) {
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <h5 className="my-2 pb-2">Session overview</h5>
      <div
        style={{
          background: "#fff",
          borderRadius: "10px",
        }}
        className="border p-2 p-lg-3 d-flex flex-column gap-3"
      >
        {clientData?.map((item, index) => (
          <div
            role="button"
            onClick={() =>
              navigate(
                `/clinical/home?view=view&clinicalId=${item?._id}&noteType=${item?.session_type}`
              )
            }
            style={{
              background: "var(--primary-accent-color)",
            }}
            className=" p-3 rounded-3"
            key={index}
          >
            <div className=" fw-semibold d-flex mb-2 justify-content-between ">
              <p className="m-0">{item?.session_date}</p>{" "}
              <p className="m-0">{item?.status}</p>
            </div>

            <p className="m-0">
              {item?.session_overview || "No session overview available "}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SessionOverview;
