import React, { useContext } from "react";
import { AppContext } from "../../../../context";

// Component
import TextEditor from "./TextEditor";

// MUI
import { FormControl, Grid, MenuItem, Select, TextField } from "@mui/material";
import { DoNotDisturbOnOutlined, Star } from "@mui/icons-material";

function RecommendedProgramListView({ download }) {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  const handleRemoveProgram = (indexToRemove) => {
    setExpertClinicalNotesData((prev) => {
      return {
        ...prev,

        recommendation: {
          ...prev?.recommendation,
          recommended_program:
            prev?.recommendation?.recommended_program?.filter(
              (item, i) => i !== indexToRemove
            ),
        },
      };
    });
  };
  const handleTimeChange = (event, index, type, key) => {
    const value = event.target.value;
    setExpertClinicalNotesData((prev) => ({
      ...prev,

      recommendation: {
        ...prev.recommendation,
        [key]: prev.recommendation[key].map((item, i) => {
          if (i === index) {
            const [number, unit] = item[
              key === "recommended_expert" ? "consult_in" : "complete_in"
            ]?.split(" ") || ["", ""];
            return {
              ...item,
              [key === "recommended_expert" ? "consult_in" : "complete_in"]:
                type === "number"
                  ? `${value.replace(/\D/g, "")} ${unit}`
                  : `${number} ${value}`,
            };
          }
          return item;
        }),
      },
    }));
  };
  console.log("ExpertClinicalNotesData", expertClinicalNotesData);

  return (
    <div>
      {" "}
      {expertClinicalNotesData?.recommendation?.recommended_program?.length >
        0 && (
        <div>
          <Grid
            container
            spacing={3}
            className="pb-5 pe-lg-4 mt-2"
            sx={{
              backgroundColor: "#FFF",
              marginLeft: 0,
              marginRight: 0,

              width: "100%",
            }}
          >
            {expertClinicalNotesData?.recommendation?.recommended_program?.map(
              (data, index) => (
                <Grid
                  key={index}
                  item
                  className=" p-1 ps-lg-4"
                  xs={12}
                  lg={download ? 12 : 6}
                >
                  <div
                    style={{ background: "#F8F8F8" }}
                    className="p-3 rounded-3"
                  >
                    <div className="d-flex  justify-content-between ">
                      <div className="d-flex">
                        <img
                          src={
                            data?.website_thumbnail_url ||
                            "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2FThumbnail.png?alt=media"
                          }
                          width={78}
                          height={78}
                          className="rounded cover rounded-3"
                          alt=""
                          style={{objectFit: "cover"}}
                        />
                        <div className="px-2">
                          <p className="fw-bold m-0 ">
                            {data?.name}
                            {"  "} <Star sx={{ color: "#FCAF23" }} />
                            {data?.rating}
                          </p>
                          <p
                            style={{ fontSize: "14px" }}
                            className="fw-bold m-0"
                          >
                            {data?.club}
                          </p>
                          <p style={{ fontSize: "14px" }} className="m-0">
                            {data?.duration}
                          </p>
                        </div>
                      </div>
                      {!expertClinicalNotesData?.data?.submitted &&
                        !download && (
                          <div
                            role="button"
                            onClick={() => handleRemoveProgram(index)}
                          >
                            <DoNotDisturbOnOutlined sx={{ color: "var(--primary-color)" }} />
                          </div>
                        )}
                    </div>

                    <p className="m-0 mt-3  fw-bold">Complete in</p>
                    <div className="d-flex align-items-center gap-2">
                      <TextField
                        size="small"
                        fullWidth
                        label=""
                        sx={{ background: "white" }}
                        placeholder="0"
                        disabled={
                          expertClinicalNotesData?.data?.submitted || download
                        }
                        value={data?.complete_in?.split(" ")[0] || ""} // Use state to store the input value
                        onChange={(event) => {
                          handleTimeChange(
                            event,
                            index,
                            "number",
                            "recommended_program"
                          );
                        }}
                      />
                      <FormControl
                        size="small"
                        fullWidth
                        sx={{ background: "white" }}
                        className="my-3"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              data?.complete_in?.split(" ")[1] || "Frequency"
                            }
                            label=""
                            disabled={
                              expertClinicalNotesData?.data?.submitted ||
                              download
                            }
                            onChange={(event) => {
                              handleTimeChange(
                                event,
                                index,
                                "unit",
                                "recommended_program"
                              );
                            }}
                          >
                            <MenuItem
                              value={"Frequency"}
                              disabled={
                                expertClinicalNotesData?.data?.submitted ||
                                download
                              }
                            >
                              Frequency
                            </MenuItem>
                            <MenuItem value={"hours"}>Hour(s)</MenuItem>
                            <MenuItem value={"days"}>Day(s)</MenuItem>
                            <MenuItem value={"weeks"}>Week(s)</MenuItem>
                            <MenuItem value={"months"}>Month(s)</MenuItem>
                            <MenuItem value={"Year"}>Year(s)</MenuItem>
                          </Select>
                        </div>
                      </FormControl>
                    </div>

                    <TextEditor
                      hideToolbar={true}
                      placeholder={!download && "Add note to client..."}
                      initialText={data?.note_from_expert}
                      disabled={
                        expertClinicalNotesData?.data?.submitted || download
                      }
                      htmlText={(text) =>
                        setExpertClinicalNotesData((prev) => {
                          return {
                            ...prev,
                            recommendation: {
                              ...prev?.recommendation,
                              recommended_program:
                                prev?.recommendation?.recommended_program?.map(
                                  (item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        note_from_expert: text,
                                      };
                                    }
                                    return item;
                                  }
                                ),
                            },
                          };
                        })
                      }
                    />
                  </div>
                </Grid>
              )
            )}
          </Grid>
        </div>
      )}
    </div>
  );
}

export default RecommendedProgramListView;
