import React from "react";

// MUI
import { Skeleton } from "@mui/material";
function RecommendationTabSkeleton({ heading }) {
  return (
    <div
      className="w-100 my-4 p-4 rounded rounded-3"
      style={{ backgroundColor: "rgb(0,0,0,0.07)" }}
    >
      <div className="d-flex justify-content-between gap-3">
        <p className="fw-bold ">{heading}</p>
        <Skeleton
          variant="rounded"
          width={130}
          height={56}
          className="rounded rounded-3"
        />
      </div>
      <div className="d-flex gap-2 my-2">
        <Skeleton
          variant="rounded"
          width="350px"
          height="56px"
          className="rounded rounded-3"
        />
        <Skeleton
          variant="rounded"
          width={120}
          height={56}
          className="rounded rounded-3"
        />
      </div>
    </div>
  );
}

export default RecommendationTabSkeleton;
