import React, { useContext } from "react";
import { AppContext } from "../../../../context";

// MUI
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";

function FollowUpNoteDialog({ open, setOpen }) {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  function removeHtmlTags(text) {
    return text?.replace(/<[^>]*>/g, "")?.replace(/&nbsp;/g, "");
  }
  return (
    <div>
      {" "}
      <Dialog
        className="recommendation-assessment"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          style={{ backgroundColor: "#F8F8F8" }}
          className="d-flex align-items-center justify-content-between"
        >
          <p className="fw-bold m-0 p-2">Notes from Clinician </p>
          <IconButton onClick={() => setOpen(false)}>
            <Clear />
          </IconButton>
        </DialogTitle>
        <DialogContent className="">
          <div className=" my-4 px-2 row  row-cols-1 row-cols-md-2">
            {expertClinicalNotesData?.followUpNotes?.map((data, index) => (
              <div className="p-2" key={index}>
                <div
                  style={{
                    border: "1px solid #D0D5DD",
                    width: "",
                  }}
                  className="rounded-1  col p-3 lh-md h-100"
                >
                  <div className="d-flex justify-content-between flex-wrap  fw-semibold">
                    <p>{data?.title?.replace("()", "")}</p>
                    <div className="d-flex gap-1">
                      <img
                        src="/assets/img/calendar_timer.jpg"
                        width={24}
                        height={24}
                        alt="calendar"
                      />
                      {data?.date}
                    </div>
                  </div>

                  <div
                    className="rounded-1 p-2 mt-2"
                    style={{
                      border: "1px solid #E6E6E9",
                      background: "#F8F8F8",
                    }}
                  >
                    {removeHtmlTags(data?.note) || "No notes available"}
                  </div>

                  <div className="fw-bold mt-2">{data?.note_summary}</div>
                </div>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default FollowUpNoteDialog;
