import { useState } from "react";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";

const ClinicalClientDetailsTableRowClinicalRoute = ({
  id,
  image,
  name,
  email,
  sessions,
  onClickCallback,

}) => {
  const selectedRowClass = "SelectedTableRow";
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRowClick = () => {
    if (onClickCallback) {
      onClickCallback(name, id, email, sessions, image);
    }
  };



  return (
    <TableRow
      className={`ClientDetails--TableRow`}
      sx={{
        backgroundColor: "#FFF",
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >

      <TableCell className="Table--Name">
        <div>
          <p className="m-0">{name}</p>

        </div>
      </TableCell>

      <TableCell className="Table--Sessions">
        <span>{email}</span>
      </TableCell>
      <TableCell className="Table--Sessions">
        <span>{sessions}</span>
      </TableCell>

      <TableCell align="right">
        <div className="Table--Actions__Alt d-flex">

          <Button className="view_client" onClick={handleRowClick}>
            <VisibilityIcon />
            <p className="m-0">View</p>
          </Button>





        </div>
      </TableCell>
    </TableRow>
  );
};

export default ClinicalClientDetailsTableRowClinicalRoute;
