import React, { Component, useState } from "react";
import AppRoutes from "./routing/AppRoutes";
import { AppContext } from "./context";
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ThemeSwitcher from "./ThemeSwitcher";



export default class App extends Component {
  render() {
    return <AppView />;
  }
}

const AppView = (props) => {
  const [ShowLloginPopup, setShowLloginPopup] = useState(0);
  const [expertDashboardData, setExpertDashboardData] = useState({});
  const [expertClinicalNotesData, setExpertClinicalNotesData] = useState({});
  const [CNrecommendationData, setCNrecommendationData] = useState({});
  const [credits, setCredits] = useState(0);
  const [expertDetails, setExpertDetails] = useState({});
  return (
    <SnackbarProvider maxSnack={3}
      action={(key) => (
        <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
          <CloseIcon />
        </IconButton>
      )}
    >
      <AppContext.Provider
        value={{
          ShowLloginPopup,
          setShowLloginPopup,
          expertDashboardData,
          setExpertDashboardData,
          expertClinicalNotesData,
          setExpertClinicalNotesData,
          CNrecommendationData,
          setCNrecommendationData,
          credits,
          setCredits,
          expertDetails,
          setExpertDetails
        }}
      >
        <ThemeSwitcher />
        <AppRoutes />
      </AppContext.Provider>
    </SnackbarProvider>
  );
};
