export const Config = () => ({
  headers: {
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem("Authorization"),
    'X-Email-Id': (localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")).Emailid : "vikas@cyberexpert.com"),
    'X-API-Key': process.env.REACT_APP_EXPERT_API_KEY,
    'DeviceType': 'web',
    'DeviceId': '123',
    'Lat': '1',
    'Long': '1'
  },
  data: {}
})