import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  InputBase,
  IconButton,
  Checkbox,
  Rating,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";

//icons
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import MicIcon from "@mui/icons-material/Mic";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { CheckBox, Save, Star } from "@mui/icons-material";

import { set } from "date-fns";
import RecommendedExpertCardSkeleton from "../Skeletons/RecommendedExpertCardSkeleton";
import { FetchRecommendedExpertList } from "../ClinicalNotesAPI/ClinicalNoteAPIManager";
import { AppContext } from "../../../../context";

const categories = [
  "Relationship Issues",
  "Mindfulness",
  "Meditation/Dhyana",
  "Stress",
  "Anxiety",
  "Weight Management",
  "Grief, Loss and PTSD",
  "Depression",
  "Addiction",
  "Parenting",
  "Personality & Behavioural Issues",
];

const RecommendationExpertAdd = ({ dataRecived, expertType }) => {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [open, setOpen] = useState(false);
  const [cardDetails, setCardDetails] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [checkbox, setCheckbox] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const fetchExpertList = () => {
    setLoading(true);
    FetchRecommendedExpertList(
      expertType,
      30,
      expertClinicalNotesData?.expert_info?.Country
    )
      .then((res) => {
        if (res?.error) {
          enqueueSnackbar(res?.message, { variant: "info" });
          setLoading(false);
          return;
        }
        setCardDetails(
          res.map((expert) => ({
            ...expert,
            is_selected: false,
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        console.error(err); // Optionally log the error internally here as well
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
    fetchExpertList();
  };

  const handleCheckboxClick = (event, index) => {
    // Update the is_selected property of the clicked item in cardDetails
    const updatedDetails = [...cardDetails];
    updatedDetails[index].is_selected = event.target.checked;
    updatedDetails[index].recommended_by =
      expertClinicalNotesData?.expert_info?.Name;
    updatedDetails[index].recommended_expert_type = "";
    updatedDetails[index].recommended_date = new Date();

    // Update the cardDetails state
    setCardDetails(updatedDetails);

    // Update selectedItems array
    const updatedSelectedItems = updatedDetails.filter(
      (item) => item.is_selected
    );
    setSelectedItems(updatedSelectedItems);
  };

  const handleClose = () => {
    dataRecived(selectedItems);

    setSelectedItems([]);
    const updatedDetails = cardDetails.map((item) => ({
      ...item,
      is_selected: false,
    }));

    // Update the cardDetails state
    setCardDetails(updatedDetails);

    setCheckbox(false);
    setOpen(false);
  };

  const data = {
    Id: 9999999,
    Title: null,
    Name: expertType,
    Emailid: "",
    MobileNo: "",
    ExpertId: null,
    ProfileImage: "",
    RegisterYourselfAs: "",
    Category: "",
    Profile_Title: null,
    Experience: "",
    Rating: 0,
    Referral_Code: null,
    Affiliates: null,
    IsAllowForInstitutionsUser: null,
    Hourly_Consultaion_Fee_amount: 0,
    Currency: null,
    NextAvailability: "",
    Languages: "",
    Qualifications: "",
    Country: "",
    TimeZone: "",
    licensed_practice_in_state: null,
    Expert_Banner: "",
    USD_Amount: null,
    ReviewsCount: null,
    is_selected: true,
    recommended: true,
    recommended_by: expertClinicalNotesData?.expert_info?.Name,
    recommended_expert_type: "",
    recommended_date: new Date(),
  };
  // console.log(cardDetails);

  return (
    <div>
      <div className="d-flex align-items-center gap-3">
        <Button
          sx={{
            width: "120px",
            backgroundColor: "transparent",
          }}
          disabled={expertType === ""}
          onClick={handleClickOpen}
          className="PrimaryCTA "
          id="add_expert"
        >
          <AddOutlinedIcon />
          Add
        </Button>
      </div>
      <Dialog
        className="recommendation-assessment"
        open={open}
        onClose={() => {
          setCardDetails([]);
          setOpen(false);
        }}
      >
        <DialogTitle
          style={{ backgroundColor: "#F8F8F8" }}
          className="d-flex p-2 p-lg-4 align-items-center justify-content-between"
        >
          <p className="fw-bold m-0  p-2">{expertType}</p>
          {/* <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              margin: "10px 0",
            }}
            className="col-8"
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search through Therapists"
              value={inputValue}
              onChange={handleChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <KeyboardVoiceOutlinedIcon />
            </IconButton>
          </Paper> */}
        </DialogTitle>
        <DialogContent className="d-flex  w-100">
          {/* <div className="col-4">
            <p className="fw-bold  p-2">Categories</p>
            {categories.map((category, index) => {
              return (
                <div className="d-flex align-items-center " key={index}>
                  <Checkbox {...label} /> <p className="  p-2">{category}</p>
                </div>
              );
            })}
          </div> */}
          {loading && <RecommendedExpertCardSkeleton />}
          {!loading &&
            (cardDetails?.length < 1 ? (
              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <img
                  width={100}
                  src="/assets/img/clinical_notes_assets/not-found.png"
                />
                <p className="w-100 m-0 mt-2 d-flex justify-content-center align-items-center">
                  No {expertType} Found
                </p>
              </div>
            ) : (
              <div className="col" style={{ height: "500px" }}>
                {cardDetails?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex my-4 justify-content-between "
                  >
                    <div className="d-flex">
                      <img
                        src={item?.ProfileImage}
                        width={78}
                        height={78}
                        className="rounded rounded-3"
                        alt=""
                      />
                      <div className="px-2">
                        <p className="fw-bold m-0 ">
                          {item?.Name}
                          {"  "}
                        </p>
                        <p className="m-0" style={{ fontSize: "14px" }}>
                          {item?.Experience}+ Years of Experience
                        </p>
                        <p className="m-0" style={{ fontSize: "14px" }}>
                          <span className="fw-bold">Focus:</span>
                          {" " + item?.Category?.split(",")?.join(", ")}
                        </p>
                      </div>
                    </div>

                    <div>
                      <Checkbox
                        {...label}
                        checked={item?.is_selected}
                        onChange={(e) => handleCheckboxClick(e, index)}
                        disabled={checkbox}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </DialogContent>
        <DialogTitle
          style={{ backgroundColor: "#F8F8F8" }}
          className="d-flex p-2 p-lg-4 align-items-center justify-content-between"
        >
          <div className="d-flex  align-items-center">
            <Checkbox
              {...label}
              onChange={(e) => {
                setCheckbox(e.target.checked);

                if (e.target.checked) {
                  setCardDetails(
                    cardDetails.map((item) => ({
                      ...item,
                      is_selected: false,
                    }))
                  );
                  setSelectedItems([data]);
                } else {
                  setSelectedItems([]);
                  setCardDetails(
                    cardDetails.map((item) => ({
                      ...item,
                      is_selected: false,
                    }))
                  );
                }
              }}
            />{" "}
            <p className="fw-bold m-0" style={{ fontSize: "16px" }}>
              Check this box if you want to simply recommend a {expertType}
            </p>
          </div>
          <Button
            sx={{
              width: "120px",
              backgroundColor: "transparent",
            }}
            disabled={selectedItems?.length === 0}
            className="PrimaryCTA "
            onClick={handleClose}
            id="added_expert"
          >
            Done
          </Button>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default RecommendationExpertAdd;
