import React from "react";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import {
  ClinicalDashboard,
} from "../../components/expertDashboard/Dashboard";
import ExpertClinicalSettingsDB from "../../components/expertDashboard/ExpertClinicalSettingsDB";
import ExpertClinicalNotesDBClinical from "../../components/expertDashboard/ExpertClinicalNotesDBClinical";
import ExpertClinicalClientDB from "../../components/expertDashboard/ExpertClinicalClientDB";
import ExpertClinicalHelpCenterDB from "../../components/expertDashboard/ExpertClinicalHelpCenterDB";
import HelpCenterDocuments from "../../components/expertDashboard/helpCenter/HelpCenterDocuments";
import HelpCenterVideos from "../../components/expertDashboard/helpCenter/HelpCenterVideos";
import HelpCenterFAQs from "../../components/expertDashboard/helpCenter/HelpCenterFAQs";
import TalkToUs from "../../components/expertDashboard/component/TalkToUs";



export const ClinicalRoutes = () => {
  return (
    <>
      <Routes>
        <Route element={<ClinicalDashboard />}>
          <Route path="*" element={<ExpertClinicalNotesDBClinical />} />
          <Route
            path="clinicalNotes"
            element={<ExpertClinicalNotesDBClinical />}
          />
          <Route path="clients" element={<ExpertClinicalClientDB />} />
          <Route path="help-center" element={<ExpertClinicalHelpCenterDB />} />
          <Route path="settings" element={<ExpertClinicalSettingsDB />} />
          {/* <Route path="talk-to-us" element={<TalkToUs />} /> */}
          <Route
            path="help-center/documents"
            element={<HelpCenterDocuments />}
          />
          <Route path="help-center/videos" element={<HelpCenterVideos />} />
          <Route path="help-center/faqs" element={<HelpCenterFAQs />} />
        </Route>
      </Routes>
    </>
  );
};
