import { Skeleton } from "@mui/material";
import React from "react";

function RecommendedExpertCardSkeleton() {
  return (
    <div className="col" style={{ height: "500px" }}>
      {Array.from(new Array(6))?.map((item, index) => (
        <div key={index} className="d-flex my-4 justify-content-between ">
          <div className="d-flex">
         
            <Skeleton
              variant="rounded"
              width="78px"
              height="78px"
              className="rounded-3"
            />
            <div className="px-2 d-flex flex-column gap-2">
              
              <Skeleton
                variant="rounded"
                width="78px"
                height="14x"
                className="rounded-3"
              />
              <Skeleton
                variant="rounded"
                width="150px"
                height="14x"
                className="rounded-3"
              />
              <Skeleton
                variant="rounded"
                height="14x"
                width={300}
                className="rounded-3"
              />
            </div>
          </div>

          <div>
            <Skeleton
              variant="rounded"
              width="24px"
              height="24px"
              className="rounded-2"
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default RecommendedExpertCardSkeleton;
