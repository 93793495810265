import axios from "axios";
import { Config } from "../../core/_request";
import jwtDecode from "jwt-decode";
//var jwtDecode = require('jwt-decode');

const headers = {
  "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
  "Content-Type": "application/json",
};







export const validate_token = (token) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}api/sso/validate_token?token=${token}`,
      Config()
    )
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};


export const UserDetailsService = () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}api/Auth/GetUserbyId`, Config())
    .then((resp) => resp.data)
    .then((resp) => {
      if (resp.data) resp.data = jwtDecode(resp.data);
      return resp;
    });
};


export const fetchExpertDetails = async (payload) => {
  try {
    const headers = {
      "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
      "Content-Type": "application/json",
    };
    const response = await fetch(`https://us.uwc.world/new-uwc/professionals/get`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const postResponse = await response.json();
    return postResponse?.data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error so the calling function can handle it
  }
};








export const for_location = () => {
  return fetch(`${process.env.REACT_APP_GEO_LOCATION_URL}`, {
    // mode: 'no-cors',
    method: "GET",
    headers: {
      "Content-Type": "text/plain",
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (resp) {
      return resp;
    })
    .catch((e) => console.error(e));
};

export const getTNC = async (id) => {
  try {
    const response = await fetch(`https://integrations.uwc.world/users/v2/${id}/tnc_status`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "copilot"
        })
      }
    );
    if (response.ok) {
      const data = await response.json();
      if (data?.code === 200) {
        return { status: data?.accepted_terms_copilot, success: true };
      } else {
        throw new Error("Failed to fetch TNC status");
      }
    } else {
      console.error("Error fetching TNC status:", response.status);
      throw new Error("Failed to fetch TNC status");
    }
  } catch (error) {
    console.error("Error fetching TNC status:", error);
    return { success: false };
  }
}

export const sendNotification = async (body) => {
  try {
    const response = await fetch(`https://notifications.uwc.world/events/event_action/send-notification`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body
      }
    );

    //console.log("response", response)
  } catch (error) {
    throw new Error("Something went wrong!", error)
  }
}

export const acceptTNC = async (id) => {
  try {
    const response = await fetch(`https://integrations.uwc.world/users/v2/${id}/accept_tnc`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "copilot",
          status: true
        })
      }
    );
    if (response.ok) {
      const data = await response.json();

      return { success: true };

    } else {
      console.error("Error accepting TNC status:", response.status);
      throw new Error("Failed to accept TNC status");
    }
  } catch (error) {
    console.error("Error accepting TNC status:", error);
    return { success: false };
  }
}

export const fetchCountries = async () => {
  try {
    const headers = {
      "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
      "Content-Type": "application/json",
    };
    const response = await fetch("https://us.uwc.world/new-uwc/country?all=true", {
      method: "GET",
      headers: headers,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const postResponse = await response.json();
    return postResponse?.data.countries;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error so the calling function can handle it
  }
};

export const fetchStates = async (countryCode) => {
  try {
    const headers = {
      "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
      "Content-Type": "application/json",
    };
    const response = await fetch(`https://us.uwc.world/new-uwc/state/${countryCode}`, {
      method: "GET",
      headers: headers,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const postResponse = await response.json();
    return postResponse?.data.states;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error so the calling function can handle it
  }
};

export const getNextSteps = async (id) => {
  try {
    const response = await fetch(
      `https://integrations.uwc.world/users/${id}/introduction_status`,
      {
        method: "GET",
        headers: {
          "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
          "Content-Type": "application/json",
          DeviceType: "web",
          DeviceId: "123",
          Lat: "1",
          Long: "1",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    // console.log("onboardingStatus", data);

    if (data?.code === 200) {
      return {
        ResponseCode: 200,
        ResponseMessage: "",
        status: data?.data?.status,
      };
    } else {
      throw new Error(data?.ResponseMessage);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return {
      ResponseCode: 500,
      ResponseMessage: "Falied to validate next steps",
    };
  }
};

export const acceptNextSteps = async (id) => {
  try {
    const response = await fetch(
      `https://integrations.uwc.world/users/${id}/introduction_status`,
      {
        method: "POST",
        headers: {
          "X-API-Key": `${process.env.REACT_APP_EXPERT_API_KEY}`,
          "Content-Type": "application/json",
          DeviceType: "web",
          DeviceId: "123",
          Lat: "1",
          Long: "1",
        },
        body: JSON.stringify({
          status: true,
        }),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    if (data?.code === 200) {
      return {
        ResponseCode: 200,
        ResponseMessage: "Next steps accepted successfully",
        status: data?.data?.status,
      };
    } else {
      throw new Error(data?.ResponseMessage);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return {
      ResponseCode: 500,
      ResponseMessage: "Falied to update Next steps status",
    };
  }
};

export const getDisclaimer = async () => {
  try {
    const response = await fetch(`https://legal.unitedwecare.com/disclaimer-ccop`
    );
    if (response.ok) {
      const data = await response.text();

      return data
    } else {
      console.error("Error fetching Disclaimer:", response.status);
      throw new Error("Failed to fetch Disclaimer");
    }
  } catch (error) {
    console.error("Error fetching Disclaimer:", error);
    return { success: false };
  }

}











