import React, { useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  InputBase,
  IconButton,
  Checkbox,
  Rating,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";

//icons
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";

import {
  AccessAlarm,
  AccessTime,
  CheckBox,
  People,
  Save,
  Star,
} from "@mui/icons-material";
import RecommendedProgramCardSkeleton from "../Skeletons/RecommendedProgramCardSkeleton";
import { FetchRecommendedProgramList } from "../ClinicalNotesAPI/ClinicalNoteAPIManager";

const categories = [
  "Relationship Issues",
  "Mindfulness",
  "Meditation/Dhyana",
  "Stress",
  "Anxiety",
  "Weight Management",
  "Grief, Loss and PTSD",
  "Depression",
  "Addiction",
  "Parenting",
  "Personality & Behavioural Issues",
];

const RecommendationProgramAdd = ({ dataRecived, program, expert_info }) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [cardDetails, setCardDetails] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const fetchProgramList = () => {
    FetchRecommendedProgramList(program, 30)
      .then((res) => {
        if (res?.error) {
          enqueueSnackbar(res?.message, { variant: "info" });
          setLoading(false);
          return;
        }
        setCardDetails(
          res.map((program) => ({
            ...program,
            is_selected: false,
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        console.error(err); // Optionally log the error internally here as well
      });
  };

  // console.log(selectedItems);
  // Function to handle checkbox click
  const handleCheckboxClick = (index) => {
    const updatedDetails = [...cardDetails];
    updatedDetails[index].is_selected = !updatedDetails[index].is_selected;
    updatedDetails[index].recommended_by = expert_info?.Name;
    updatedDetails[index].recommended_expert_type = "";
    updatedDetails[index].recommended_date = new Date();

    // Update the cardDetails state
    setCardDetails(updatedDetails);

    // Update selectedItems array
    const updatedSelectedItems = updatedDetails
      .filter((item) => item.is_selected)
      .map((item) => item);
    setSelectedItems(updatedSelectedItems);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setLoading(true);
    fetchProgramList();
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    dataRecived(selectedItems);
  };

  return (
    <div>
      <div className="d-flex align-items-center gap-3">
        <Button
          sx={{
            width: "120px",
            backgroundColor: "transparent",
          }}
          disabled={program === ""}
          onClick={handleClickOpen}
          className="PrimaryCTA "
          id="add_program"
        >
          <AddOutlinedIcon />
          Add
        </Button>
      </div>

      <Dialog
        className="recommendation-assessment"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle
          style={{ backgroundColor: "#F8F8F8" }}
          className="d-flex align-items-center justify-content-between"
        >
          <p className="fw-bold m-0  p-2">Wellness Program</p>
          {/* <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
            }}
            className="col-4"
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search through Wellness Program"
              value={inputValue}
              onChange={handleChange}
            />
            <IconButton type="button" aria-label="search">
              <KeyboardVoiceOutlinedIcon />
            </IconButton>
          </Paper> */}
        </DialogTitle>
        <DialogContent className="d-flex p-0 p-md-3 w-100">
          {/* <div className="col-4">
            <p className="fw-bold  p-2">Categories</p>
            {categories.map((category, index) => {
              return (
                <div className="d-flex align-items-center " key={index}>
                  <Checkbox {...label} /> <p className="  p-2">{category}</p>
                </div>
              );
            })}
          </div> */}
          {loading && <RecommendedProgramCardSkeleton />}
          {!loading &&
            (cardDetails.length < 1 ? (
              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <img
                  width={100}
                  src="/assets/img/clinical_notes_assets/not-found.png"
                />
                <p className="w-100 mt-2 d-flex justify-content-center align-items-center">
                  No program Found
                </p>
              </div>
            ) : (
              <div className="col py-2 my-4" style={{ height: "500px" }}>
                <Grid
                  container
                  spacing={1}
                  className="pb-5 "
                  sx={{
                    backgroundColor: "#FFF",
                    marginLeft: 0,
                    marginRight: 0,
                    borderRadius: "10px",
                    width: "100%",
                  }}
                >
                  {cardDetails.map((data, index) => (
                    <Grid
                      className="p-1 p-md-2"
                      item
                      key={index}
                      xs={12}
                      md={6}
                    >
                      <div
                        className=" p-2 rounded-3"
                        style={{
                          border: data?.is_selected
                            ? "1px solid var(--primary-color-opacity50)"
                            : "",

                          minWidth: "350px",
                          background: "#FEFEFE",
                          boxShadow: data?.is_selected
                            ? "1.84px 3.68px 11.44px 0px var(--primary-color-opacity30)"
                            : "1.84px 3.68px 11.44px 0px rgba(0, 0, 0, 0.20)",
                          height: "100%",
                        }}
                      >
                        <img
                          height={180}
                          src={
                            data?.website_thumbnail_url ||
                            "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2FThumbnail.png?alt=media"
                          }
                          alt={data?.urlTitle}
                          loading="lazy"
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            background:
                              "lightgray -45.151px -1.371px / 177.244% 100.931% no-repeat",
                          }}
                          className=" rounded-4 rounded-bottom-0"
                        />
                        <div className="d-flex mt-2  col justify-content-between flex-wrap">
                          <p
                            style={{ fontSize: "14px" }}
                            className="fw-bold col m-0"
                          >
                            {data?.name}
                          </p>

                          <div
                            style={{
                              fontSize: "12px",
                            
                              background: "#9FD68B1A",
                              height: "30px",
                            }}
                            className=" d-flex px-2 align-items-center gap-2 justify-content-center rounded-pill"
                          >
                            <img src="/assets/img/tabler_clock-filled.png" />
                            <p className="m-0 ">{data?.duration}</p>
                          </div>
                        </div>
                        <p
                          style={{ fontSize: "14px" }}
                          className="d-flex align-items-center gap-1 m-0 fw-bold"
                        >
                          <Star sx={{ color: "#FCAF23" }} />{" "}
                          <span >{data?.rating} </span>{" "}
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <p
                            style={{ fontSize: "14px" }}
                            className="fw-bold m-0"
                          >
                            {data?.people_enrolled || 0} enrollments <People />
                          </p>
                          <Checkbox
                            {...label}
                            checked={data.is_selected}
                            onChange={() => handleCheckboxClick(index)}
                          />
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))}
        </DialogContent>
        <DialogTitle
          style={{ backgroundColor: "#F8F8F8" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Button
            sx={{
              width: "120px",
              backgroundColor: "transparent",
            }}
            className="PrimaryCTA "
            onClick={handleClose}
            id="added_program"
          >
            Done
          </Button>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default RecommendationProgramAdd;
