import React, { useState, useEffect } from "react";
import "./index.scss"; // Import the SCSS file with your styles

function ThemeSwitcher() {
  const [primaryColor, setPrimaryColor] = useState("#73589B");

  useEffect(() => {
    const root = document.documentElement; // Accessing the root element of the document
    root.style.setProperty("--primary-color", primaryColor);
    root.style.setProperty("--primary-color-darken", darken(primaryColor, 20));
    root.style.setProperty("--primary-accent-color", `${primaryColor}15` );
    root.style.setProperty(
      "--primary-color-opacity30",
      `${primaryColor}30` 
    );
    root.style.setProperty(
      "--primary-color-opacity50",
      `${primaryColor}50` 
    );
    root.style.setProperty("--secondary-color", darken(primaryColor, 10));
  }, [primaryColor]); // This effect runs whenever primaryColor changes

  // Helper functions to darken and fade colors
  function darken(color, percent) {
    const f = parseInt(color.slice(1), 16),
          R = f >> 16,
          G = f >> 8 & 0x00FF,
          B = f & 0x0000FF;
    return '#' + (
      0x1000000 +
      (Math.floor(R * (100 - percent) / 100) * 0x10000) +
      (Math.floor(G * (100 - percent) / 100) * 0x100) +
      (Math.floor(B * (100 - percent) / 100))
    ).toString(16).slice(1);
  }


  return <></>;
}

export default ThemeSwitcher;
