import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

// MUI
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers";

function FollowUpDateCaclulator({ date, setDate, disabled }) {
  const [value, setValue] = useState(dayjs().format("YYYY-MM-DD"));
  const [followUpIn, setFollowUpIn] = useState(0);
  const [followUpUnit, setFollowUpUnit] = useState("days");

  useEffect(() => {
    if (setDate) {
      setValue(dayjs(setDate).format("YYYY-MM-DD"));
    }
  }, [setDate]);

  const handleInputChangeDate = (field, inputValue) => {
    if (field === "time") {
      setValue(inputValue);

      // Update follow-up interval and unit based on the difference between current date and follow-up date
      const currentDate = dayjs();
      const followUpDate = dayjs(inputValue);
      const diffInDays = followUpDate.diff(currentDate, "days");

      // Assuming follow-up interval is in days
      setFollowUpUnit("days");
      setFollowUpIn(diffInDays);

      date(inputValue);
    } else if (field === "followUpIn") {
      setFollowUpIn(inputValue.replace(/\D/g, ""));
      calculateFollowUpDate(inputValue.replace(/\D/g, ""), followUpUnit); // Calculate follow-up date
    } else if (field === "followUpUnit") {
      setFollowUpUnit(inputValue);
      calculateFollowUpDate(followUpIn, inputValue); // Calculate follow-up date
    }
  };
  const calculateFollowUpDate = (followUpInValue, followUpUnitValue) => {
    const currentDate = dayjs(); // Get the current date
    const newDate = currentDate.add(
      parseInt(followUpInValue, 10),
      followUpUnitValue
    );

    if (newDate.isValid()) {
      const calculatedFollowUpDate = newDate.format("YYYY-MM-DD");
      setValue(calculatedFollowUpDate); // Set the value of DateTimePicker
      date(calculatedFollowUpDate);
    } else {
      console.error("Invalid date");
    }
  };

  // const handleConsultationDateChange = (date) => {
  //   const formattedDate = date.target.value;
  //   console.log(formattedDate);

  // };
  return (
    <div className="Table--Body gap-3 my-2">
      <div className="Table-span-3">
        <TextField
          size="small"
          value={followUpIn || ""}
          fullWidth
          onChange={(e) => handleInputChangeDate("followUpIn", e.target.value)}
          label=""
          disabled={disabled}
        />
      </div>
      <div className="Table-span-3">
        <FormControl size="small" fullWidth>
          <Select
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            value={followUpUnit || ""}
            onChange={(e) =>
              handleInputChangeDate("followUpUnit", e.target.value)
            }
            disabled={disabled}
          >
            <MenuItem value={""} disabled>
              Select an option
            </MenuItem>
            <MenuItem value={"days"}>Day(s)</MenuItem>
            <MenuItem value={"weeks"}>Week(s)</MenuItem>
            <MenuItem value={"months"}>Month(s)</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="Table-span-6 date--picker">
        {/* <LocalizationProvider
          size="small"
          dateAdapter={AdapterDayjs}
          className="pt-0"
        >
          <DemoContainer components={["DatePicker"]} sx={{ paddingTop: 0 }}>
            <DatePicker
              className="pt-0"
              value={value}
              format="YYYY-MM-DD"
              onChange={(newValue) => handleInputChangeDate("time", newValue)}
              disablePast={true}
              disabled={disabled}
            />
          </DemoContainer>
        </LocalizationProvider> */}
        <input
          type="date"
          className="w-100 rounded-3 border p-2 border-1 border-secondary"
          min={dayjs().format("YYYY-MM-DD")}
          value={value}
          onChange={(e) => handleInputChangeDate("time", e.target.value)}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default FollowUpDateCaclulator;
