import React from "react";

// MUI
import { HelpOutline, Send } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";

function ClinicalNoteSubmitDialog({
  submission,
  setSubmission,
  handleSubmit,
  handleSave,
}) {
  return (
    <div>
      {" "}
      <Dialog
        className="clinicalnotes-submit-dialog text-center"
        open={submission}
        onClose={setSubmission}
      >
        <DialogContent className="p-3 p-sm-4">
          <DialogTitle
            style={{ fontSize: "20px", color: "#101828", fontWeight: "600",paddingTop:"0px" }}
          >
            Submit Clinical Note{" "}
            <Tooltip
              arrow
              title={
                <p className="m-0" style={{ fontSize: "14px", color: "white" }}>
                  Once submitted, editing is not possible.
                </p>
              }
              placement="top-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "common.black",
                    "& .MuiTooltip-arrow": {
                      color: "common.black",
                    },
                    color: "common.white",
                    padding: "10px",
                  },
                },
              }}
            >
              <HelpOutline />
            </Tooltip>
          </DialogTitle>
          <DialogContentText
            id="alert-dialog-title"
            style={{ fontSize: "16px", color: "#344054" }}
          >
            Would you like to submit the clinical note before leaving?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="p-4 d-flex gap-3 justify-content-between  pt-0 ">
          <Button id="save_clinical_note" onClick={handleSave} className="save font-inter col  ">
            Save and Exit
          </Button>
          <Button
            onClick={handleSubmit}
            autoFocus
            className="submit m-0  col font-inter "
            id="submitted_clinical_note"
          >
            Submit
         
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ClinicalNoteSubmitDialog;
