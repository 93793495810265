import React from 'react'

import UserInfoSkeleton from '../../clinicalNotesClinicalRoute/Skeletons/UserInfoSkeleton';

const ClientInfoPanelClinicalRoute = ({ clientDetails, handleChat, handleNotesOpen }) => {
    const handleBrokenImage = (imgLink) => {

        const imgLinkSplit = imgLink.split("https://");
        if (imgLinkSplit.length > 1) {
            const updatedLink = "https://" + imgLinkSplit[imgLinkSplit.length - 1];
            return updatedLink;
        } else {
            return "https://images.unitedwecare.com/odoo%2Fopen%2Fprofile-vector.webp"
        }
    }
    return (
        <div
            className=" mt-2 border rounded rounded-4"
            style={{ backgroundColor: "white" }}
        >
            {
                !clientDetails ? (
                    <UserInfoSkeleton />
                ) : (
                    <>
                        <div className="d-flex align-items-center px-4">
                            <div>
                                <img
                                    src={clientDetails?.image}
                                    onError={(e) => {
                                        const newLink = handleBrokenImage(clientDetails?.image);
                                        e.target.src = newLink
                                    }}
                                    alt=""

                                    className="rounded rounded-3 "
                                    width={60}
                                    height={60}
                                    style={{
                                        backgroundColor: "white",
                                        objectFit: "cover",
                                        objectPosition: "center",
                                    }}
                                />
                            </div>
                            <div className="p-4 d-flex flex-column gap-1">
                                <div className="d-flex ">
                                    <h4 className="m-0">
                                        {clientDetails?.name || "Client Name"}
                                    </h4>
                                </div>
                                <p className="fs-6 m-0">
                                    {clientDetails?.email}
                                </p>
                            </div>
                        </div>

                    </>
                )
            }
        </div>
    )
}

export default ClientInfoPanelClinicalRoute