import { useState, useEffect, useRef, forwardRef } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment-timezone";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";

import { useAuth } from "../../../auth";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

//TextField
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";

//Icons
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";

import CircularProgress from "@mui/material/CircularProgress";
import { HighlightOffOutlined } from "@mui/icons-material";

import dayjs from "dayjs";
import utc from "dayjs-plugin-utc";

// Extend dayjs with the plugins
dayjs.extend(utc);

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TicketDetailsTableRow = ({
  date,
  detail,
  serialNO,
  topic,
  status,
  ticketID,
  selectedTab,
  index,
  handleTicketStatus,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const formattedDate = moment(date).format("D MMM YYYY");
  const [openReply, setOpenReply] = useState(false);

  const { currentUser, userType, logout } = useAuth();
  const [chatHistory, setChatHistory] = useState([]);

  const [chatData, setChatData] = useState({
    chatID: 0,
    clientID: 0,
    clientName: "",
    clientProfilePicture: "",
    clientAppointmentId: 0,
    chatHistory: [],
  });

  const [showAttachmentPreview, setShowAttachmentPreview] = useState(false);
  const [sendChatValue, setSendChatValue] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const headers = {
    "X-API-Key": process.env.REACT_APP_EXPERT_API_KEY,
    "Content-Type": "application/json",
  };
  const chatHistoryRef = useRef(null);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  //Get Ticket History

  const postTicketsHistoryData = async () => {
    const payload = {
      ticket_id: ticketID,
    };
    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/ticket-reply-list`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const postResponse = await response.json();
      if (postResponse?.code === 200) {
        setChatHistory(postResponse?.data?.replies);
      }

      // console.log("data", replies);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // console.log(chatHistory);

  const sendTicketReply = async (replyText) => {
    const payload = {
      ticket_id: ticketID,
      reply_text: replyText,
      reply_from: "expert",
    };
    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/ticket-reply`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const postResponse = await response.json();

      if (postResponse?.code === 200) {
        setSnackbarMessage(postResponse?.data?.message);
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const scrollChatToBottom = () => {
    //console.log("chatHistoryRef", chatHistoryRef)
    if (chatHistoryRef.current) {
      const chatContainer = chatHistoryRef.current;
      const lastChatMessage = chatContainer.lastElementChild;

      if (lastChatMessage) {
        lastChatMessage.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    scrollChatToBottom();
  }, [chatHistory]);

  const handleCloseReply = () => {
    setOpenReply(false);
  };

  const handleReplyToChat = () => {
    setOpenReply(true);
    postTicketsHistoryData();
    setTimeout(() => {
      scrollChatToBottom();
    }, 100);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setShowAttachmentPreview(true);
  };

  const handleRemoveAttachment = () => {
    setSelectedFile(null);
    setShowAttachmentPreview(false);
  };

  // const handleSendChat = () => {
  //   const chatObj = {
  //     Appointment_Id: chatData?.clientAppointmentId,
  //     from_id: currentUser?.UserId,
  //     message: sendChatValue,
  //     timestamp: moment().format("DD/MMM/YYYY HH:mm"),
  //     to_id: chatData?.clientID,
  //   };

  //   //console.log("chatObj", chatObj)
  //   setChatData((prevData) => ({
  //     ...prevData,
  //     chatHistory: [...prevData.chatHistory, chatObj],
  //   }));

  //   setSendChatValue("");
  //   postChat(
  //     chatObj.from_id,
  //     chatObj.to_id,
  //     chatObj.message,
  //     chatObj.Appointment_Id
  //   );
  // };

  const handleTextChange = (e) => {
    setSendChatValue(e.target.value);
  };

  const handleReply = () => {
    setChatHistory((prevData) => [
      ...prevData,
      {
        id: +prevData?.length + 1,
        ticket_id: ticketID,
        reply_text: sendChatValue,
        reply_from: "expert",
        sequence: prevData?.length + 1,
        reply_time: moment().utc(),
        expert_reply: true,
      },
    ]);

    setSendChatValue("");

    sendTicketReply(sendChatValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && sendChatValue !== "") {
      // Call a function when Enter key is pressed
      handleReply();
    }
  };

  const handleResolved = async () => {
    try {
      const payload = {
        ticket_id: ticketID,
      };

      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/resolve-ticket`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const postResponse = await response.json();

      // console.log("postResponse", postResponse);
      if (postResponse?.code == 200) {
        handleTicketStatus();
      }
    } catch (error) { }
  };

  // const tempChatObj = [
  //   {
  //     "id": 1,
  //     "reply_from": "uwc",
  //     "reply_text": "Please be patient it took 7 days",
  //     "reply_time": "2023-08-18 12:36:08",
  //     "sequence": 1,
  //     "expert_reply": false,
  //   },
  //   {
  //     "id": 2,
  //     "reply_from": "expert",
  //     "reply_text": "payment not recieved",
  //     "reply_time": "2023-08-18 12:36:28",
  //     "sequence": 2,
  //     "expert_reply": true,
  //   },
  //   {
  //     "id": 16,
  //     "reply_from": "chandu",
  //     "reply_text": "you will recieve your payment today",
  //     "reply_time": "2023-08-25 16:59:25",
  //     "sequence": 3,
  //     "expert_reply": false,
  //   },
  //   {
  //     "id": 17,
  //     "reply_from": "arti khanijo",
  //     "reply_text": "still payment not recieved",
  //     "reply_time": "2023-08-25 17:00:26",
  //     "sequence": 4,
  //     "expert_reply": true,
  //   },
  //   {
  //     "id": 18,
  //     "reply_from": "arti khanijo",
  //     "reply_text": "Test",
  //     "reply_time": "2023-08-26 17:00:26",
  //     "sequence": 5,
  //     "expert_reply": true,
  //   }

  // ]
  // let testChatHistory = tempChatObj?.sort((a, b) => a.sequence - b.sequence)
  //   ?.reduce((acc, chat) => {
  //     const isCurrentUser = chat.expert_reply == true;

  //     // Check if there are any chat groups created so far
  //     if (acc.length === 0) {
  //       // If this is the first chat, create a new group
  //       acc.push({
  //         expertReply: chat.expert_reply,
  //         messages: [chat],
  //       });
  //     } else {
  //       // Get the last chat group
  //       const prevChatGroup = acc[acc.length - 1];

  //       // Check if the expert_reply property has changed
  //       if (prevChatGroup.expertReply !== chat.expert_reply) {
  //         // If it has changed, create a new group
  //         acc.push({
  //           expertReply: chat.expert_reply,
  //           messages: [chat],
  //         });
  //       } else {
  //         // If it hasn't changed, add the message to the current group
  //         prevChatGroup.messages.push(chat);
  //       }
  //     }

  //     return acc;
  //   }, [])

  // console.log("ChatHistory", chatHistory)

  return (
    <>
      <TableRow
        className={`TicketDetails--TableRow ${status == "Resolved" ? "" : "SelectedTableRow"
          }`}
        sx={{
          backgroundColor: "#FFF",
          "&:last-child td, &:last-child th": { border: 0 },
          maxWidth: "fit-content",
        }}
      >
        <TableCell className="Table--SRNO">
          <p className="m-0">{index + 1}</p>
        </TableCell>
        <TableCell className="Table--Topic">
          <div>
            <p className="m-0">{topic}</p>
            <span>{formattedDate}</span>
          </div>
        </TableCell>

        <TableCell className="Table--Detail">
          <p className="m-0">{detail}</p>
        </TableCell>

        {/* <TableCell className="Table--Status">
          {status == "Resolved" ? (
            <p>Resolved</p>
          ) : (
            <p>Ongoing</p>
          )}
        </TableCell> */}

        <TableCell className="Table--Actions">
          {status === "Resolved" ? (
            <div
              style={{ color: "#000", textTransform: "capitalize", background: "#12B76A1A" }}
              className="d-inline px-3 py-2  rounded-3 fw-bold"
            >
              Resolved
            </div>
          ) : (
            <div
              className="d-flex align-items-center gap-2"
              style={{ width: "max-width" }}
            >
              <button
                sx={{ color: "#000", textTransform: "capitalize" }}
                style={{ border: "none" }}
                className="Ticket--Resolved ticket_reply"

                onClick={() => handleReplyToChat()}
              >
                Reply
              </button>

              <button
                sx={{ color: "#000", textTransform: "capitalize" }}
                style={{ border: "none" }}
                className="Ticket--Resolved__Oultine ticket_marked_as_resolved"

                onClick={() => handleResolved()}
              >
                Mark as Resolved
              </button>
            </div>
          )}
        </TableCell>
      </TableRow>

      {/* Reply to ticket */}
      <Dialog
        className="ReplyTicket--Dialog clinicalDashboard--scroll"
        open={openReply}
        onClose={handleCloseReply}
      >
        <DialogContent>
          <div
            style={{ background: "#F2F4F7" }}
            className="d-flex px-4 py-2 border-bottom align-items-center w-100 justify-content-between"
          >
            <div className="d-flex flex-column ">
              <p style={{ fontSize: "16px" }} className=" fw-bold">{topic}</p>
              <small>{formattedDate}</small>
            </div>
            <IconButton onClick={handleCloseReply}>
              <HighlightOffOutlined sx={{ fontSize: "28px" }} />
            </IconButton>
          </div>

          <div className="HelpChat--Chats " ref={chatHistoryRef}>
            {chatHistory?.map((chat, index) => {
              //console.log("chat", chat);
              return (
                <div
                  key={index}
                  className={`message d-flex gap-3 ${chat?.expert_reply
                    ? " justify-content-end"
                    : "justify-content-start"
                    }`}
                >
                  <div style={{ maxWidth: "60%" }} className=" d-flex gap-3">
                    <div className="col-auto d-flex align-items-center">
                      {!chat?.expert_reply && (
                        <img
                          src={"/assets/img/favicon/android-chrome-512x512.png"}
                          width={46}
                          height={46}
                          style={{ objectFit: "cover", borderRadius: "50%" }}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        background: chat?.expert_reply ? "var(--primary-color)" : "var(--primary-accent-color) ",
                        color: chat?.expert_reply ? "white" : "black",
                      }}
                      className=" rounded-4 p-3 "
                    >
                      <div className="message-info col ">
                        {/* {
                          !chat?.expert_reply && <div className="message-header mb-1">
                            <strong>Support team</strong>

                          </div>} */}
                        <div className="message-text fs-6">
                          {chat?.reply_text}
                        </div>
                        <div className="d-flex justify-content-end">
                          <small
                            style={{ fontSize: "12px" }}
                            className=" ml-2 text-end"
                          >
                            {moment(chat?.reply_time)
                              .tz(moment.tz.guess())
                              .format("DD MMM YYYY")}
                            {/* {moment(chat?.reply_time).format(
                              "DD MMM YYYY hh:mm A"
                            )} */}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="HelpChat--Send">
            <FormControl
              sx={{ width: "100%" }}
              className="rounded-3 TextBox--Container"
              variant="outlined"
            >
              {selectedFile && (
                <div className="SelectedFile">
                  <AttachFileIcon
                    sx={{ fill: "var(--primary-color)", transform: "rotate(45deg)" }}
                  />
                  <div>
                    <p>{selectedFile.name}</p>
                    <span>{`${(selectedFile.size / 1024).toFixed(2)} KB`}</span>
                  </div>

                  <IconButton
                    onClick={handleRemoveAttachment}
                    aria-label="remove"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
              <div className="Input--Area">
                <Input
                  className="TextBox"
                  id="outlined-adornment-weight"
                  sx={{ background: "#FFF", borderRadius: "10px" }}
                  value={sendChatValue}
                  onChange={handleTextChange}
                  onKeyDown={handleKeyPress}
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="Type you message here..."
                />
                <Button
                  disabled={sendChatValue?.trim() === ""}
                  onClick={handleReply}
                  id="send_ticket_message"
                >
                  Send
                </Button>
              </div>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TicketDetailsTableRow;
