import { Button, Dialog, DialogContent } from "@mui/material";
import React from "react";

function AcceptCCOPDisclaimer({
  open,
  close,
  handleAccept,
  disclaimercontent,
}) {
  return (
    <Dialog
      className="disclaimer-modal clinicalDashboard--scroll"
      open={open}
      onClose={close}
    >
      <DialogContent className="p-2 p-md-4">
        <div
          style={{ zoom: "0.8" }}
          id="disclaimer-content"
          dangerouslySetInnerHTML={{
            __html: disclaimercontent,
          }}
        ></div>
      </DialogContent>
      <div className="sticky-bottom border-top border-2 p-3 p-md-4 py-md-2 d-flex justify-content-center justify-content-lg-end">
        <Button
          onClick={handleAccept}
          variant="outlined"
          // sx={{ color: "#73589B", borderColor: "#73589B", fontWeight: "600",fontSize: "16px", }}
          sx={{
            fontWeight: "600",
            fontSize: "16px",
          }}
          className=" text-capitalize PrimaryCTA--Outlined-success  "
        >
          I Understand and Accept the Disclaimer
        </Button>
      </div>
    </Dialog>
  );
}

export default AcceptCCOPDisclaimer;
