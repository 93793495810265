import { Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const helpfulPDFs = [
  {
    id: 1,
    link: "https://images.unitedwecare.com/odoo%2Fopen%2Fwebapp%2Fco-pilot%2FHow%20to%20Create%20A%20Clinical%20Note.pdf",
    name: "How to Create A Clinical Note",
  },
  {
    id: 2,
    link: "https://images.unitedwecare.com/odoo%2Fopen%2Fwebapp%2Fco-pilot%2FHow%20to%20Create%20a%20New%20Client%20Profile.pdf",
    name: "How to Create a New Client Profile",
  },
  {
    id: 3,
    link: "https://images.unitedwecare.com/odoo%2Fopen%2Fwebapp%2Fco-pilot%2FHow%20to%20Generate%20Clinical%20Note%20with%20Voice.pdf",
    name: "How to Generate Clinical Note with Voice",
  },
  {
    id: 4,
    link: "https://images.unitedwecare.com/odoo%2Fopen%2Fwebapp%2Fco-pilot%2FHow%20to%20Generate%20Clinical%20Note%20with%20Document%20Upload.pdf",
    image: "/assets/img/help-center-assets/pdf.png",
    name: "How to Generate Clinical Note with Document Upload",
  },
  {
    id: 5,
    link: "https://images.unitedwecare.com/odoo%2Fopen%2Fwebapp%2Fco-pilot%2FWhat%20are%20the%20Different%20Sections%20of%20Clinical%20Note%20and%20How%20to%20Navigate%20through%20Them.pdf",
    name: "What are the Different Sections of Clinical Note and How to Navigate through Them?",
  },

  {
    id: 6,
    link: "https://images.unitedwecare.com/odoo%2Fopen%2Fwebapp%2Fco-pilot%2FHow%20to%20Recommend%20Resources%20to%20Clients.pdf",
    name: " How to Recommend Resources to Clients?",
  },
  {
    id: 7,
    link: "https://images.unitedwecare.com/odoo%2Fopen%2Fwebapp%2Fco-pilot%2FHow%20to%20Create%20Stella%20Mode.pdf",
    name: "How to Create Stella Mode",
  },
  {
    id: 8,
    link: "https://images.unitedwecare.com/odoo%2Fopen%2Fwebapp%2Fco-pilot%2FHow%20to%20Edit%20Profile%20Details.pdf",
    name: "  How to Edit Profile Details",
  },

  {
    id: 9,
    link: "https://images.unitedwecare.com/odoo%2Fopen%2Fwebapp%2Fco-pilot%2FHow%20to%20Contact%20Support%20or%20Raise%20Tickets.pdf",
    name: "How to Contact Support or Raise Tickets?",
  },
];

function HelpCenterDocuments() {
  const navigate = useNavigate();
  return (
    <div className="mt-3 ExpertHelpCenter--Dashboard">
      <div className="ExpertHelpCenter--Header d-flex justify-content-between align-items-center">
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              onClick={() => {
                navigate("/clinical/help-center");
              }}
              role="button"
              color="text.primary"
              sx={{
                fontWeight: "500",
                fontSize: "20px",
                textDecoration: "none",
              }}
              className="d-flex text-secondary align-items-center justify-content-between gap-2 "
            >
              Help Center
            </Link>

            <Typography
              sx={{ fontWeight: "500", fontSize: "20px" }}
              color="text.primary"
            >
              Documents
            </Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div className="d-none">
        <Typography
          sx={{ fontWeight: "500", fontSize: "24px" }}
          className="mt-3"
          color="text.primary"
        >
          Documentation & Guides
        </Typography>
        <p style={{ fontSize: "1rem" }} className="mt-3 mb-5">
          Find manuals, how-to PDFs, white papers, case studies, and other
          resources to help you use our services.
        </p>
      </div>

      <div className="ExpertHelpCenter--documents">
        {helpfulPDFs.map((item) => (
          <div
            className="cursor-pointer  shadow-sm help_center_documents"
            key={item.id}
            onClick={() => window.open(item.link)}
            data-help-center-document={item.link}
            style={{
              cursor: "pointer",
              background: "white",
              borderRadius: "5px",
              maxWidth: "160px",
            }}
          >
            <div
              style={{ background: "#D0D5DD", borderRadius: "5px 5px 0 0" }}
              className="w-100 py-3 pe-3 d-flex align-items-center justify-content-center"
            >
              <img
                src={"/assets/img/help-center-assets/Pdf-type.png"}
                alt="..."
              />
            </div>
            <div className="py-3 px-2">
              <span
                className=" fw-semibold line-clamp "
                style={{ fontSize: "0.75rem", lineHeight: "120%" }}
              >
                {item.name}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HelpCenterDocuments;
