import React from "react";

// MUI
import { Skeleton } from "@mui/material";
function ClientCardsSkeleton() {
  return (
    <div className="p-3">
      <div
        className="p-4 col rounded-4"
        style={{ backgroundColor: "rgb(0,0,0,0.09)" }}
      >
        <div className="d-flex gap-2 align-items-center">
          <Skeleton variant="circular" width={50} height={50} />
          <div className="d-flex flex-column gap-2">
            {Array.from(new Array(2)).map((_, index) => (
              <Skeleton
                variant="rectangular"
                width={100}
                height={15}
                className="rounded-3"
              />
            ))}
          </div>
        </div>
        <div className="w-100 my-2 px-2">
          {Array.from(new Array(3)).map((_, index) => (
            <Skeleton
              variant="rectangular"
              height={15}
              className="rounded-5 my-2"
            />
          ))}
        </div>
        <div>
          <Skeleton variant="rounded" height={40} className="rounded-5  mt-3" />
        </div>
      </div>
    </div>
  );
}

export default ClientCardsSkeleton;
