import React from 'react'

// MUI
import { Skeleton } from '@mui/material'
function UserSummarySkeleton() {
  return (
    <div
    className="mt-4 rounded rounded-4 border pb-3 "
    style={{ backgroundColor: "white" }}
  >
    <p className="py-3 px-4 border-bottom fw-bold ">Summary</p>
    <p className="mx-2 mx-sm-4 mt-3 ">
      <Skeleton
        variant="rounded"
        height="40px"
        width="100%"
        className="rounded-3"
      />
    </p>
    <div className="row row-cols-1 row-cols-lg-2 gap-4 gap-lg-0 p-2 p-sm-4">
      <div className="d-flex flex-column col  gap-3">
        <Skeleton
          variant="rounded"
          height="22px"
          width="100%"
          className="rounded-3"
        />
        <Skeleton
          variant="rounded"
          height="22px"
          width="80%"
          className="rounded-3"
        />
        <Skeleton
          variant="rounded"
          height="22px"
          width="70%"
          className="rounded-3"
        />
        <Skeleton
          variant="rounded"
          height="22px"
          width="100%"
          className="rounded-3"
        />
        <Skeleton
          variant="rounded"
          height="22px"
          width="90%"
          className="rounded-3"
        />
      </div>
      <div className="d-flex flex-column col gap-2 rounded rounded-3">
        <Skeleton
          variant="rounded"
          height="60px"
          width="100%"
          className="rounded-3"
        />
      </div>
    </div>

  
  </div>
  )
}

export default UserSummarySkeleton