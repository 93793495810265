import React from 'react'
import { Skeleton } from '@mui/material'

function TextEditorCommonSkeleton() {
  return (
    <div className="w-100 h-100 my-2">
    <Skeleton
      variant="rounded"
      height="300px"
      className="rounded rounded-3"
    />
    <Skeleton
      variant="rounded"
      height="45px"
      width="182px"
      className="rounded rounded-3 mt-3 ml-4"
    />
  </div>
  )
}

export default TextEditorCommonSkeleton