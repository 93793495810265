import { Grid, Skeleton } from "@mui/material";
import React from "react";

function RecommendedProgramCardSkeleton() {
  return (
    <div className="col py-2 my-4" style={{ height: "500px" }}>
      <Grid
        container
        spacing={1}
        className="pb-5"
        sx={{
          backgroundColor: "#FFF",
          marginLeft: 0,
          marginRight: 0,
          borderRadius: "10px",
          width: "100%",
        }}
      >
        {Array.from(new Array(6))?.map((data, index) => (
          <Grid item key={index} xs={12} sm={6} >
            <div
              className=" p-2 rounded-3"
              style={{
                minWidth: "350px",
                background: "#FEFEFE",

                height: "100%",
              }}
            >
              
              <Skeleton
                variant="rounded"
               
                height="180px"
                className="rounded-4"
              />
              <div className="d-flex mt-2   col justify-content-between">
                <Skeleton
                  variant="rounded"
                  width="78px"
                  height="14x"
                  className="rounded-3"
                />

                <Skeleton
                  variant="rounded"
                  width="78px"
                  height="14x"
                  className="rounded-3"
                />
              </div>
              <Skeleton
                variant="rounded"
                width="150px"
                height="14x"
                className="rounded-3 mt-1"
              />
              <div className="d-flex justify-content-between align-items-center">
                <Skeleton
                  variant="rounded"
                  width="150px"
                  height="14x"
                  className="rounded-3"
                />
                <Skeleton
                  variant="rounded"
                  width="24px"
                  height="24px"
                  className="rounded-2"
                />
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default RecommendedProgramCardSkeleton;
