import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

// API
import { getRecommendedLabTest } from "../ClinicalNotesAPI/ClinicalNoteAPIManager";

// MUI
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
function RecommendedLabTestDialog({
  openDialog,
  closeDialog,
  recommendation,
  labtestData,
  label,
}) {
  const [selectedRecommendedTest, setSelectedRecommendedTest] = useState({});
  const [recommendedLabTest, setRecommendedLabTest] = useState([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (openDialog) {
      setSelectedRecommendedTest({});
      setRecommendedLabTest([]);
    }
  }, [openDialog]);

  useEffect(() => {
    if (!searchTerm) return;
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (recommendation?.trim() !== "") {
      getRecommendedLabTest(recommendation).then((res) => {
        if (res?.error) {
          enqueueSnackbar(res?.message, { variant: "info" });
          return;
        }
        setSelectedRecommendedTest(res?.data[0]);
      });
    }
  }, [recommendation]);

  const handleChange = (value, field) => {
    setSelectedRecommendedTest({
      ...selectedRecommendedTest,
      [field]: value,
    });
    if (field === "test_name") {
      setSearchTerm(value);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      getRecommendedLabTest(debouncedSearchTerm).then((res) => {
        if (res?.error) {
          enqueueSnackbar(res?.message, { variant: "info" });
          return;
        }
        setRecommendedLabTest(res?.data);
      });
    }
  }, [debouncedSearchTerm]);

  console.log(selectedRecommendedTest);

  return (
    <div>
      <Dialog
        className="create-client-dialog"
        open={openDialog}
        onClose={() => closeDialog()}
      >
        <DialogTitle>{label}</DialogTitle>
        <DialogContent className="p-3">
          <DialogContentText
            className="d-flex gap-2 align-items-center fw-normal lh-base"
            sx={{ fontSize: "18px", padding: "10px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Lab Test *
                  </p>
                  <Autocomplete
                    disablePortal
                    freeSolo
                    id="combo-box-demo"
                    options={recommendedLabTest?.map((test) => test.test_name)} // Map the test_name from each object in the recommendedLabTest array
                    onChange={(event, newValue) => {
                      const selectedTest = recommendedLabTest.find(
                        (test) => test.test_name === newValue
                      );
                      setSelectedRecommendedTest(selectedTest || {});
                      setSearchTerm("");
                      setDebouncedSearchTerm("");
                    }}
                    value={selectedRecommendedTest?.test_name || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        label=""
                        value={selectedRecommendedTest?.test_name || ""}
                        onChange={(e) => {
                          handleChange(e.target.value, "test_name");
                        }}
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={6} lg={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Sample Requirement
                  </p>

                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={selectedRecommendedTest?.sample_requirement || ""}
                    onChange={(e) =>
                      handleChange(e.target.value, "sample_requirement")
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={6} lg={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Storage Temperature
                  </p>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={
                      selectedRecommendedTest?.storage_transport_temperature ||
                      ""
                    }
                    onChange={(e) =>
                      handleChange(
                        e.target.value,
                        "storage_transport_temperature"
                      )
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ flex: 1, marginRight: 8 }}>
                    <p
                      className="text-black fw-semibold"
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Alternative Specimen
                    </p>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      value={
                        selectedRecommendedTest?.acceptable_alternative_specimen ||
                        ""
                      }
                      onChange={(e) =>
                        handleChange(
                          e.target.value,
                          "acceptable_alternative_specimen"
                        )
                      }
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} lg={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Other Requirements
                  </p>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={selectedRecommendedTest?.other_requirements || ""}
                    onChange={(e) =>
                      handleChange(e.target.value, "other_requirements")
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={6} lg={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Causes for Rejection
                  </p>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={selectedRecommendedTest?.causes_for_rejection || ""}
                    onChange={(e) =>
                      handleChange(e.target.value, "causes_for_rejection")
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={6} lg={6}>
                <div>
                  <p
                    className="text-black fw-semibold"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Time for result
                  </p>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    value={selectedRecommendedTest?.time_to_result || ""}
                    onChange={(e) =>
                      handleChange(e.target.value, "time_to_result")
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="px-4 mb-2">
          <Button
            onClick={() => {
              setSelectedRecommendedTest(null);
              closeDialog();
            }}
            className="PrimaryCTA--Ghost"
            sx={{
              background: "white",
              marginLeft: "20px",
              height: "40px",
              width: "100px",
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              !selectedRecommendedTest?.test_name ||
              selectedRecommendedTest?.test_name?.trim() === ""
            }
            onClick={() => {
              labtestData(selectedRecommendedTest);
              setSelectedRecommendedTest(null);
              closeDialog();
            }}
            className="PrimaryCTA"
            id="added_new_lab_test"
            sx={{ marginLeft: "20px", height: "40px", width: "100px" }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RecommendedLabTestDialog;
