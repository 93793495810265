import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../../context";
import {
  FetchGraphChildrenData,
  FetchGraphInitialData,
} from "../ClinicalNotesAPI/ClinicalNoteAPIManager";
import ForceGraph2D from "react-force-graph-2d";

import { useSnackbar } from "notistack";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import {
  Add,
  DarkMode,
  Fullscreen,
  FullscreenExit,
  KeyboardArrowDownOutlined,
  LightMode,
  Remove,
} from "@mui/icons-material";

const initialGraphData = { nodes: [], links: [] };
function GraphVisualization({ currentTab }) {
  const [graphData, setGraphData] = useState(initialGraphData);
  const { expertClinicalNotesData } = useContext(AppContext);
  const [nodeData, setNodeData] = useState({});
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const graphRef = useRef();
  const fgRef = useRef();
  const [dimension, setDimension] = useState({
    height: graphRef?.current?.getBoundingClientRect()?.height,
    width: graphRef?.current?.getBoundingClientRect()?.width,
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (
      currentTab === "graph" &&
      expertClinicalNotesData?.headerData?.user_id
    ) {
      loadData();
    }
  }, []);

  useEffect(() => {
    setDimension({
      height: graphRef?.current?.getBoundingClientRect()?.height,
      width: graphRef?.current?.getBoundingClientRect()?.width,
    });
    window.addEventListener("resize", handleResize);
    document.addEventListener("fullscreenchange", (e) => {
      if (document.fullscreenElement) {
        setIsFullScreen(true);
      } else {
        setIsFullScreen(false);
      }
    });
  }, []);

  const loadData = async () => {
    setNodeData({});
    setGraphData(initialGraphData);
    FetchGraphInitialData({
      user_id: expertClinicalNotesData.headerData.user_id,
      session_id: expertClinicalNotesData.headerData.session_id,
      labtest:
        expertClinicalNotesData.data?.lab_test?.prescribed_lab_test || [],
      drugs:
        expertClinicalNotesData.data?.progress_note?.medicines
          ?.prescribed_medicines || [],
      assistant: expertClinicalNotesData.data?.assistant || {},
      user_summary: expertClinicalNotesData.data?.summary || {},
    }).then((resp) => {
      if (resp.ok) {
        setGraphData(resp.graph);
      } else {
        enqueueSnackbar(resp.message, {
          variant: "error",
        });
      }
    });
  };

  const collapseNode = (node) => {
    // Collapse logic: Remove these nodes and all their descendants
    const childrenIds = new Set(node.children?.map((child) => child.id));

    setGraphData((prev) => {
      // Helper function to recursively collect child node IDs
      const getAllChildIds = (nodeId, nodes, links) => {
        let childrenIds = new Set();
        let stack = [nodeId];

        while (stack.length > 0) {
          const currentId = stack.pop();
          // Add to the set of children
          childrenIds.add(currentId);
          // Find all children of the current node
          const children = links
            .filter((l) => l.source.id === currentId)
            .map((l) => l.target.id);
          // Stack up the children to traverse them next
          stack.push(...children);
        }

        return childrenIds;
      };

      // Get all IDs that need to be removed
      let toRemoveIds = new Set();
      childrenIds.forEach((childId) => {
        const descendantIds = getAllChildIds(childId, prev.nodes, prev.links);
        descendantIds.forEach((id) => toRemoveIds.add(id));
      });

      // Filter nodes and links to remove the nodes and any links connected to them
      const remainingNodes = prev.nodes.filter((n) => !toRemoveIds.has(n.id));
      const remainingLinks = prev.links.filter(
        (l) => !toRemoveIds.has(l.source.id) && !toRemoveIds.has(l.target.id)
      );

      return {
        nodes: remainingNodes,
        links: remainingLinks,
      };
    });
  };

  const expandNode = (node) => {
    setGraphData((prev) => {
      const newNodes = node.children;
      const existingNodeIds = new Set(prev.nodes.map((n) => n.id));
      const newLinks = newNodes
        .map((child) => ({
          source: node.id,
          target: child.id,
          // name: `Link from ${node.id} to ${child.id}`,
          name: child?.link_name,
        }))
        .filter((link) => !existingNodeIds.has(link.target));

      return {
        nodes: [
          ...prev.nodes,
          ...newNodes.filter((newNode) => !existingNodeIds.has(newNode.id)),
        ],
        links: [...prev.links, ...newLinks],
      };
    });
  };

  const handleNodeClick = async (node) => {
    setNodeData(node || {});
    const childrenIds = node.children?.map((child) => child.id);
    const currentlyDisplayed = childrenIds?.some((childId) =>
      graphData.nodes.find((n) => n.id === childId)
    );

    if (currentlyDisplayed) {
      collapseNode(node);
    } else {
      if (node.parent) {
        expandNode(node);
        return;
      }

      const uniqueIds = node.children.map((child) => child.id);
      const children = await FetchGraphChildrenData(node.id);
      node.children.push(
        ...children.filter((child) => !uniqueIds.includes(child.id))
      );

      expandNode(node);
    }
  };

  const nodeCanvasObject = (node, ctx, globalScale) => {
    const label = node.name;
    const fontSize = 12 / globalScale; // Adjust font size based on zoom level
    ctx.font = `${fontSize}px Sans-Serif`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = node.colour;
    ctx.beginPath();
    ctx.arc(node.x, node.y, 5, 0, 2 * Math.PI, false);
    ctx.fill();
    ctx.fillStyle = isDarkMode ? "#fff" : "#000"; // Text color
    ctx.fillText(label, node.x, node.y + 8); // Adjust text position relative to the node
  };
  const linkCanvasObject = (link, ctx, globalScale) => {
    const label =
      link.name.substring(0, 25) + (link.name.length > 25 ? "..." : ""); // Assuming the label is stored under 'name'
    const fontSize = 12 / globalScale; // Adjust font size based on zoom level
    const textOffset = 0.8; // Vertical offset for the text above the line, increased minimum

    // Draw the link line
    ctx.beginPath();
    ctx.moveTo(link.source.x, link.source.y);
    ctx.lineTo(link.target.x, link.target.y);
    ctx.strokeStyle = isDarkMode ? "rgba(52, 64, 84, 1)" : "#D0D5DD"; // Default link color
    ctx.lineWidth = Math.max(1 / globalScale, 0.5); // Ensure line width does not become too thin
    ctx.stroke();

    // Draw the label if it exists
    if (label) {
      const midX = (link.source.x + link.target.x) / 2;
      const midY = (link.source.y + link.target.y) / 2;
      const angle = Math.atan2(
        link.target.y - link.source.y,
        link.target.x - link.source.x
      );

      ctx.save(); // Save the current state of the canvas
      ctx.translate(midX, midY); // Translate to the midpoint
      ctx.rotate(angle); // Rotate the canvas to align with the link

      ctx.font = `${fontSize}px Sans-Serif`;
      ctx.textAlign = "center";
      ctx.textBaseline = "bottom"; // Changed from 'middle' to 'bottom'
      ctx.fillStyle = isDarkMode ? "#fff" : "#000"; // Text color
      ctx.fillText(label, 0, -textOffset); // Draw the text above the line

      ctx.restore(); // Restore the canvas to the original state
    }
  };

  const GraphLegends = [
    { title: "User", colour: "#4169E1" },
    { title: "Timeline", colour: "#36454F" },
    { title: "Gene", colour: "#DC143C" },
    { title: "Medicine", colour: "#008080" },
    { title: "Disease", colour: "#50C878" },
    { title: "Diagnostics", colour: "#663399" },
    { title: "Nutrient", colour: "#FFD700" },
    { title: "Brand", colour: "#00359E" },
  ];

  const handleZoomIn = () => {
    const currentZoom = fgRef.current.zoom();
    fgRef.current.zoom(currentZoom * 1.2); // Zoom in by 20%
  };

  const handleZoomOut = () => {
    const currentZoom = fgRef.current.zoom();
    fgRef.current.zoom(currentZoom * 0.8); // Zoom out by 20%
  };

  // Toggle Full-Screen Mode
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      graphRef.current.requestFullscreen().catch((e) => {
        console.error(
          `Error attempting to enable full-screen mode: ${e.message} (${e.name})`
        );
      });
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullScreen(false);
      }
    }
  };

  const handleResize = () => {
    setDimension({
      height: graphRef?.current?.getBoundingClientRect()?.height,
      width: graphRef?.current?.getBoundingClientRect()?.width,
    });
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div ref={graphRef} className="graph--container mt-2">
      <div
        style={{
          zIndex: "1000",
          width: dimension.width,
        }}
        className="p-2 mb-1 pb-0 position-absolute start-50 translate-middle-x bottom-0"
      >
        <div
          style={{ background: "white", overflowY: "auto" }}
          className="border border-1 px-2 rounded-3 "
        >
          <div
            style={{ minWidth: "800px" }}
            className="d-flex gap-2  flex-wrap justify-content-around align-items-center m-0 font-inter   "
          >
            <p
              style={{
                fontSize: "14px",
                color: "#101828",
                fontWeight: "600",
              }}
              className="my-2"
            >
              Legends
            </p>{" "}
            {GraphLegends.map((legend, index) => (
              <div className="d-flex align-items-center  gap-2 p-0" key={index}>
                {" "}
                <div
                  style={{
                    height: "24px",
                    width: "24px",
                    background: legend.colour,
                    borderRadius: "5px",
                  }}
                ></div>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#344054",
                    fontWeight: "500",
                    margin: "0px",
                  }}
                >
                  {legend.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Dark Mode */}
      {/* <div
        style={{
          position: "absolute",
          left: 20,
          top: 20,
          zIndex: 1000,
          boxShadow: " 0px 4px 20px 0px rgba(0, 0, 0, 0.1)",
          background: "white",
        }}
        className=" rounded-2"
      >
        <IconButton onClick={toggleDarkMode}>
          {!isDarkMode ? <DarkMode /> : <LightMode />}
        </IconButton>
      </div> */}
      <div
        style={{
          position: "absolute",
          left: 20,
          top: 80,
          zIndex: 1000,
          boxShadow: " 0px 4px 20px 0px rgba(0, 0, 0, 0.1)",
          background: "white",
        }}
        className=" rounded-2"
      >
        <IconButton onClick={toggleFullScreen}>
          {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
        </IconButton>
      </div>

      <div
        style={{
          left: 20,
          top: 140,
          zIndex: "1000",
          boxShadow: " 0px 4px 20px 0px rgba(0, 0, 0, 0.1)",
          background: "white",
        }}
        className="position-absolute d-flex flex-column rounded-2"
      >
        <IconButton onClick={handleZoomIn}>
          <Add />
        </IconButton>
        <hr className="my-0" />
        <IconButton onClick={handleZoomOut}>
          <Remove />
        </IconButton>
      </div>
      {/* EXPERIMENTAL*/}
      <Accordion
        sx={{
          // width: isFullScreen ? "450px" : "350px",
          position: "absolute",
          zIndex: "1000",
          right: "0",
        }}
        className=" h-auto col-10 col-md-8 col-lg-5 col-xl-4 col-xxl-4  shadow-none border border-1 shadow-sm rounded-3 me-md-2"
      >
        <AccordionSummary
          expandIcon={
            <IconButton>
              <KeyboardArrowDownOutlined />
            </IconButton>
          }
          aria-controls="panel1-content"
          id="panel1-header graph_node_properties"
        >
          <div className="d-flex w-100 justify-content-between align-items-center">
            <span
              style={{ color: "#101828", fontWeight: "600" }}
              className="fw-inter"
            >
              Node Properties
            </span>
            <Button
              onClick={loadData}
              style={{
                background: "var(--primary-accent-color)",
                color: "var(--primary-color)",
              }}
              className=" rounded-pill font-inter p-0 font-w600 border-0"
            >
              Reset
            </Button>
          </div>
        </AccordionSummary>

        <AccordionDetails
          className="font-inter px-0 font-w400"
          sx={{
            maxHeight: isFullScreen ? "80vh" : "60vh",
            overflow: "auto",
          }}
        >
          <div
            style={{ background: "#EAECF0" }}
            className="p-3 d-flex align-items-center"
          >
            <span
              style={{ background: nodeData?.colour, color: "white" }}
              className=" rounded-pill px-2 py-1"
            >
              {nodeData?.name}
            </span>
          </div>
          {nodeData?.description &&
            Object.keys(nodeData?.description || {}).length > 0 && (
              <table
                style={{
                  fontSize: "12px",
                  color: "#667085",
                  textAlign: "start",
                  tableLayout: "fixed", // Ensures that the table layout is fixed
                  width: "100%", // Ensures the table uses the full width
                }}
                className=" table  table-bordered "
              >
                <tbody>
                  {Object.keys(nodeData?.description)
                    .filter((i) => i !== "Image") // Excluding 'Image' from being displayed
                    .map((key) => (
                      <tr key={key}>
                        <td
                          className="fw-bold col-4"
                          style={{
                            overflowWrap: "break-word", // Breaks overly long words to prevent overflow
                            wordWrap: "break-word", // Breaks words to prevent overflow
                          }}
                        >
                          {key.charAt(0).toUpperCase() +
                            key.slice(1).replace(/_/g, " ")}
                        </td>
                        <td
                          className="col"
                          style={{
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                          }}
                        >
                          {nodeData?.description[key]?.toString()}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
        </AccordionDetails>
      </Accordion>
      {graphData.nodes.length < 1 && (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "40%",
            left: "50%",
            zIndex: "1000",
            transform: "translate(-40%, -50%)",
          }}
        />
      )}
      <ForceGraph2D
        ref={fgRef}
        graphData={graphData}
        linkDirectionalParticles={2}
        nodeLabel={(node) => node.name}
        nodeColor={(node) => node.colour}
        // linkLabel={(link) => link.name}
        linkDirectionalArrowRelPos={1}
        linkDirectionalArrowLength={3}
        height={dimension.height}
        linkWidth={2}
        nodeResolution={32}
        nodeOpacity={1}
        width={dimension.width}
        nodeCanvasObject={nodeCanvasObject}
        enablePanInteraction={true}
        linkCanvasObject={linkCanvasObject}
        linkDirectionalParticleWidth={1}
        linkDirectionalParticleSpeed={0.005}
        linkDirectionalParticleColor={
          isDarkMode ? "#D0D5DD" : "rgba(52, 64, 84, 1)"
        }
        onNodeClick={handleNodeClick}
        maxZoom={100}
        minZoom={0.5}
        linkDirectionalArrowColor={
          isDarkMode ? "rgba(52, 64, 84, 1)" : "#D0D5DD"
        }
        backgroundColor={isDarkMode ? "rgba(12, 17, 29, 1)" : "#ffffff"}
      />
    </div>
  );
}

export default GraphVisualization;
