import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { InputBase, IconButton, Checkbox } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";

import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

//icons
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import HeadsetIcon from "@mui/icons-material/Headset";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import RecommendedContentCardSkeleton from "../Skeletons/RecommendedContentCardSkeleton";
import { FetchRecommendedContentList } from "../ClinicalNotesAPI/ClinicalNoteAPIManager";

const RecommendContentAdd = ({ dataRecived, contents, expert_info }) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [cardDetails, setCardDetails] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true)
  const { enqueueSnackbar } = useSnackbar();


  const fetchContentList = async () => {


    FetchRecommendedContentList(contents, 30)
      .then((res) => {
        if (res?.error) {
          enqueueSnackbar(res?.message, { variant: "info" });
          setLoading(false)
          return;
        }
        setCardDetails(
          res.map((content) => ({
            ...content,
            is_selected: false,
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        console.error(err); // Optionally log the error internally here as well
      });
  };
  const handleCheckboxClick = (index) => {
    const updatedDetails = [...cardDetails];
    updatedDetails[index].is_selected = !updatedDetails[index].is_selected;
    updatedDetails[index].recommended_by =
      expert_info?.Name;
    updatedDetails[index].recommended_expert_type = "";
    updatedDetails[index].recommended_date = new Date();
    updatedDetails[index].start_date = new Date().toISOString();
    // Update the cardDetails state
    setCardDetails(updatedDetails);


    // Update selectedItems array
    const updatedSelectedItems = updatedDetails
      .filter((item) => item.is_selected)
      .map((item) => item);
    setSelectedItems(updatedSelectedItems);
  };
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setLoading(true);
    fetchContentList();
  };

  const handleClose = () => {
    setOpen(false);
    dataRecived(selectedItems);
  };



  return (
    <div className="d-flex align-items-center gap-3">

      <Button
        sx={{
          width: "120px",
          backgroundColor: "transparent",
        }}
        onClick={handleClickOpen}
        className="PrimaryCTA "
        disabled={contents === ""}
        id="add_content"
      >
        <AddOutlinedIcon />
        Add
      </Button>
      <Dialog
        className="recommendation-assessment"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle
          style={{ backgroundColor: "#F8F8F8" }}
          className="d-flex align-items-center justify-content-between"
        >
          <p className="fw-bold  p-2">Content</p>
          {/* <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              margin: "10px 0",
              width: 300,
            }}
            className="col-6"
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search through Contents"
              value={inputValue}
              onChange={handleChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <KeyboardVoiceOutlinedIcon />
            </IconButton>
          </Paper> */}
        </DialogTitle>
        <DialogContent className="d-flex w-100">
          <DialogContentText className="d-flex justify-content-between my-2">
            {/* <div
              style={{
                borderRadius: "50px",
                border: "1px solid var(--primary-color)",
                background: "var(--Link-link-primary, var(--primary-color))",
                color: "white",
                padding: "10px",
              }}
            >
              {content === "Video" ? (
                <>
                  <PlayCircleOutlineIcon /> Video
                </>
              ) : content === "Audio" ? (
                <>
                  <HeadsetIcon /> Audio
                </>
              ) : content === "Podcast" ? (
                <>
                  <AutoStoriesOutlinedIcon /> Podcast
                </>
              ) : "All"}
            </div> */}

            {/* <Button className=" border-0 text-black">
              <TuneIcon />
              <span style={{ fontSize: "20px", fontWeight: "400" }}>
                Filter
              </span>
            </Button> */}
          </DialogContentText>

     
            {loading && <RecommendedContentCardSkeleton />}
            {!loading && (cardDetails?.length < 1 ?
              <div className="w-100  d-flex flex-column justify-content-center align-items-center">
                <img
                  width={100}
                  src="/assets/img/clinical_notes_assets/not-found.png"
                />
                <p className="w-100 mt-2 d-flex justify-content-center align-items-center">
                  No {contents
                    ? contents?.charAt(0).toUpperCase() +
                    contents.slice(1)
                    : "Content"} Found
                </p>
              </div>


              : <Grid container spacing={2} className="mt-4">
                {cardDetails.map((data, index) => (
                  <Grid item key={index} xs={12} sm={6} >
                    <div
                      style={{

                        border: data?.is_selected ? "1px solid var(--primary-color-opacity50)" : "",
                        background: "#FEFEFE",
                        boxShadow: data?.is_selected ? "1.84px 3.68px 11.44px 0px var(--primary-color-opacity30)" : "1.84px 3.68px 11.44px 0px rgba(0, 0, 0, 0.20)",

                      }}
                      className=" rounded-4 h-100"
                    >

                      <div
                        className=" rounded-4 position-relative"
                      >
                        <img height={180}
                          src={data?.data?.smallThumbnailUrl || "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2FThumbnail.png?alt=media"
                          }
                          alt="contents"
                          loading="lazy"
                          style={{
                            width: "100%",
                            background:
                              "lightgray -45.151px -1.371px / 177.244% 100.931% no-repeat",

                          }}
                          className=" rounded-4 rounded-bottom-0" />
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "var(--primary-color)",
                            zIndex: "100",
                            background: "white",
                          }}
                          className=" p-2 rounded-circle"
                        >
                          {data?.type.toLowerCase() === "video" ? (
                            <>
                              <PlayCircleOutlineIcon />
                            </>
                          ) : data?.type.toLowerCase() === "audio" ? (
                            <>
                              <HeadsetIcon />
                            </>
                          ) : data?.type.toLowerCase() === "podcast" ? (
                            <>
                              <AutoStoriesOutlinedIcon />
                            </>
                          ) : "All"}
                        </div>
                        <p
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            borderRadius: "104.851px",
                            background: "rgba(0, 0, 0, 0.66)",
                            backdropFilter: "blur(43.56548309326172px)",
                            color: "white",
                            padding: "5px",
                          }}
                        >
                          {data?.data?.duration}
                        </p>

                      </div>
                      <div className="p-3">
                        <span
                          style={{
                            background: "#F6F0FF",
                            color: "var(--primary-color)",
                            fontSize: "12px",
                          }}
                          className=" text-center px-4 py-2 rounded-pill fw-normal"
                        >
                          {data?.data?.club?.name}
                        </span>
                        <div className="d-flex align-items-center justify-content-between mt-2">
                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "500",
                              color: "#000",
                            }}
                            className="m-0"
                          >
                            {data?.data?.title}
                          </p>
                          <Checkbox
                            {...label}
                            checked={data.is_selected}
                            onChange={() => handleCheckboxClick(index)}
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>)}
         
        </DialogContent>
        <DialogTitle
          style={{ backgroundColor: "#F8F8F8" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Button
            sx={{
              width: "120px",
              backgroundColor: "transparent",
            }}
            className="PrimaryCTA "
            onClick={handleClose}
            id="added_content"
          >

            Done
          </Button>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default RecommendContentAdd;
