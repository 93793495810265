import React from "react";

// MUI
import { Skeleton } from "@mui/material";
function FollowUpNoteSkeleton() {
  return (
    <div className="gap-4 my-1 d-flex">
      {Array.from(new Array(2)).map((_, index) => (
        <div
          style={{ backgroundColor: "rgb(0,0,0,0.07)" }}
          className="rounded rounded-3 p-2 w-100 d-flex flex-column gap-2"
        >
          <div className="d-flex justify-content-between align-items-center ">
            <Skeleton
              width="40%"
              height={25}
              variant="rounded"
              className="rounded-3"
            />
            <Skeleton
              width="20%"
              height={25}
              variant="rounded"
              className="rounded-3"
            />
          </div>
          <Skeleton
            variant="rounded"
            height="100px"
            className="rounded rounded-3"
          />
          <Skeleton
            variant="rounded"
            height="50px"
            className="rounded rounded-3"
          />
        </div>
      ))}
    </div>
  );
}

export default FollowUpNoteSkeleton;
