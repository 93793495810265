import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { InputBase, IconButton, Checkbox } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

//icons
import { AccessTime, Star } from "@mui/icons-material";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";



const HistoryProgram = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [historyData, setHistoryData] = useState([]);
  useEffect(() => {
    if (data?.length > 0) {
      const flattenedData = data.flatMap(innerArray => {
        return innerArray.flatMap(subArray => {
          return subArray;
        });
      });

      setHistoryData(flattenedData);
    }
  }, [data])



  const handleChangePage = (event, newPage) => {
    if (newPage >= 0 && newPage <= Math.ceil(historyData.length / rowsPerPage)) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };


  const handleClickOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  function formatDate(date) {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    // Function to get the ordinal suffix for the day
    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th"; // for 4th to 20th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}` ===
      "NaNth Invalid Date, NaN"
      ? formatDate(new Date())
      : `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  }
  function removeHtmlTags(text) {
    return text?.replace(/<[^>]*>/g, '');
  }
  return (
    <div>
      <button
        style={{
          background: "var(--primary-accent-color)",
          color: "var(--primary-color)",
          height: "40px",
        }}
        className="d-flex align-items-center p-2 px-4 gap-2 rounded-2 border-0"
        onClick={handleClickOpen}
      >
        <AccessTime />
        History
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="recommender-history-dialog"
      >
        <DialogTitle
          className="d-flex align-items-center justify-content-between"
          style={{
            borderRadius: "10px 10px 0px 0px",
            border: "1px solid #E6E6E9",
            background: "#F8F8F8",
          }}
        >
          <p className="fw-bold">
            Recommend History <span className="fw-semibold">(Program)</span>
          </p>
          {/* <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px",
              margin: "10px 0",
              width: 300,
            }}
            className="col-6"
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search through Contents"
              value={inputValue}
              onChange={handleChange}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <KeyboardVoiceOutlinedIcon />
            </IconButton>
          </Paper> */}
        </DialogTitle>
        {historyData?.length < 1 ? <DialogContent className="d-flex align-items-center justify-content-center font-inter fw-semibold">
          No History Data Available
        </DialogContent>
          : <DialogContent className="my-4">
            {historyData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((item, index) => (
              <div
                key={index}
                className="py-3 px-1 lh-lg"
                style={{
                  boxShadow: "0px 1px 0px 0px #E6E6E9, 0px -1px 0px 0px #E6E6E9",
                }}
              >
                <p className="fw-normal">
                  {" "}
                  Recommended on:{" "}
                  <span className="fw-semibold">
                    {formatDate(new Date(item?.recommended_date))}
                  </span> by{" "}
                  <span className="fw-semibold">{item?.recommended_by}</span>
                  {item?.expertType && `(
                ${item?.expertType})`}
                </p>
                <div
                  className="d-flex gap-4 p-2 rounded-1"
                  style={{ border: "1px solid #E6E6E9" }}
                >
                  <img
                    src={item?.website_thumbnail_url}
                    alt=""
                    style={{
                      width: "100px",
                      borderRadius: "6px",
                    }}
                  />
                  <div className="">
                    <div
                      className="d-flex align-items-center gap-2 fw-semibold"
                      style={{ fontSize: "18px" }}
                    >
                      {item?.name} <Star sx={{ color: "#FCAF23" }} />{" "}
                      {item?.rating}
                    </div>
                    <div style={{ color: "#807C75" }}>
                      {item?.club}
                    </div>
                    <div>{item?.duration}</div>
                  </div>
                </div>  <div className="my-2 p-1 lh-lg">
                  <p
                    style={{
                      color: "#080807",
                    }}
                    className="my-2 fw-normal"
                  >
                    Note From Expert :{" "}
                  </p>
                  <div
                    className="d-flex gap-2 p-2 rounded-1"
                    style={{
                      border: "1px solid #E6E6E9",
                    }}
                  >
                    {removeHtmlTags(item.note_from_expert)?.length > 0 ? removeHtmlTags(item.note_from_expert)?.replace("&nbsp;", "") : "No Note"}
                  </div>
                </div>
                {item.followedUp ? (
                  <>
                    <div className="my-2">
                      <p
                        style={{
                          color: "#080807",
                        }}
                        className="my-2 fw-normal"
                      >
                        Enrolled Details{" "}
                      </p>
                      <div
                        style={{
                          borderRadius: "6px",
                          border: "1px solid #E6E6E9",
                          color: "#807C75",
                        }}
                        className="p-2 fw-normal"
                      >
                        <p>
                          Start Date:{" "}
                          <span style={{ color: "#080807" }}>
                            {item.startDate}
                          </span>
                        </p>
                        <p>
                          Completion Date:{" "}
                          <span style={{ color: "#080807" }}>
                            {data.completionDate}
                          </span>{" "}
                        </p>
                        <p>
                          Progress:{" "}
                          <span style={{ color: "#080807" }}>
                            {item.progress}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <div style={{ color: "#080807" }} className="my-2">
                    Followed up{" "}
                    <span
                      style={{
                        borderRadius: "1000px",
                        background: "#E9E8E7",
                        color: "#4E4E4E",
                      }}
                      className="p-2"
                    >
                      No
                    </span>
                  </div>
                )}
              </div>
            ))}

            <div>
              <TablePagination
                component="div"
                count={historyData?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </DialogContent>}
      </Dialog>
    </div>
  );
};

export default HistoryProgram;
