import React, { useContext } from "react";
import { AppContext } from "../../../context";
import { useSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";
import { InsertRagSessionData } from "./ClinicalNotesAPI/ClinicalNoteAPIManager";
import { current } from "immer";

function CNReviewBar({ section_, key_ }) {
  const { enqueueSnackbar } = useSnackbar();

  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  const updateSectionState = (section, key, action, value) => {
    let updatedSection = { ...expertClinicalNotesData.data[section][key] };

    if (action === "accepted") {
      updatedSection = {
        ...updatedSection,
        accepted: "true",
        dislike: "false",
        regenerate: "false",
        is_edited: "true",
      };
      expertClinicalNotesData.socketRef.current.send(
        JSON.stringify({
          action_type: "accepted",
          details: {
            field_name:
              key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, " "),
          },
          user_id: expertClinicalNotesData?.headerData?.user_id,
          expert_id: expertClinicalNotesData?.headerData?.expert_id,
        })
      );
    } else if (action === "dislike") {
      updatedSection = {
        ...updatedSection,
        is_edited: null,
        accepted: "false",
        dislike: "true",
        regenerate: "true", // Setting regenerate to true when dislike is true
        show_feedback: "true",
        feedback: "",
        display: "false",
      };
      expertClinicalNotesData.socketRef.current.send(
        JSON.stringify({
          action_type: "disliked",
          details: {
            field_name:
              key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, " "),
          },
          user_id: expertClinicalNotesData?.headerData?.user_id,
          expert_id: expertClinicalNotesData?.headerData?.expert_id,
        })
      );
    } else if (action === "regenerate") {
      updatedSection = {
        ...updatedSection,
        total: 0,
        current: 0,
        completed: "false",
        is_edited: null,
        accepted: "false",
        dislike: "false",
        regenerate: "true",
        show_feedback: "true",
        feedback: "",
        display: "false",
      };
      expertClinicalNotesData.socketRef.current.send(
        JSON.stringify({
          action_type: "regenerated",
          details: {
            field_name:
              key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, " "),
          },
          user_id: expertClinicalNotesData?.headerData?.user_id,
          expert_id: expertClinicalNotesData?.headerData?.expert_id,
        })
      );
    } else if (action === "cross") {
      updatedSection = {
        ...updatedSection,
        text: "", // Clearing the text
        accepted: "false",
        dislike: "false",
        regenerate: "false",
        show_feedback: "false",
      };
      expertClinicalNotesData.socketRef.current.send(
        JSON.stringify({
          action_type: "cleared",
          details: {
            field_name:
              key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, " "),
          },
          user_id: expertClinicalNotesData?.headerData?.user_id,
          expert_id: expertClinicalNotesData?.headerData?.expert_id,
        })
      );
    } else if (action === "review") {
      updatedSection = {
        ...updatedSection,
        accepted: "false",
        dislike: "false",
        regenerate: "false",
        feedback: value,
        show_feedback: "false",
      };
      enqueueSnackbar("Thank you for your feedback.", { variant: "success" });
    }
    setExpertClinicalNotesData((prevState) => {
      // Return the updated state
      return {
        ...prevState,
        askStellaConversation: action === "accepted" ? "init" : "end",
        data: {
          ...prevState.data,
          [section]: {
            ...prevState.data[section],
            [key]: { ...updatedSection, regenerate: "false" }, // Correctly updates the specific key within the section
          },
        },
      };
    });
    const data = JSON.stringify({
      header: expertClinicalNotesData.headerData,
      session_data: {
        ...expertClinicalNotesData.data,
        [section]: {
          ...expertClinicalNotesData.data[section],
          [key]: {
            ...expertClinicalNotesData.data[section][key],
            ...updatedSection,
          },
        },
      },
    });
    if (action !== "accepted") {
      // console.log("Regen data", data);
      expertClinicalNotesData.socketRef.current.send(data);
    } else if (action === "accepted") {
      InsertRagSessionData(data).then((res) => {
        console.log("Data sent to server");
        setExpertClinicalNotesData((prevData) => ({
          ...prevData,
          askStellaConversation: "end",
        }));
      });
    }
  };

  const handleAccept = () => {
    updateSectionState(section_, key_, "accepted");
  };

  const handleDislike = () => {
    updateSectionState(section_, key_, "dislike");
  };

  const handleRegenerate = () => {
    updateSectionState(section_, key_, "regenerate");
  };
  const handleCross = () => {
    updateSectionState(section_, key_, "cross");
  };

  return (
    <div>
      {!expertClinicalNotesData?.data?.submitted && (
        <div className="p-2 d-flex flex-wrap  align-items-center">
          <div
            style={{ backgroundColor: "white" }}
            className="p-1 my-2 rounded-2 d-inline mx-2"
          >
            <Tooltip
              TransitionComponent={Zoom}
              title="Accept"
              arrow
              placement="top"
            >
              <IconButton
                className="accept border-0 mx-1"
                style={{ backgroundColor: "white" }}
                id={`accept_${section_}_${key_}`}
                onClick={handleAccept}
                disabled={
                  expertClinicalNotesData?.data?.submitted ||
                  expertClinicalNotesData?.editTemplate
                }
              >
                <img src="/assets/img/elements/typcn_tick.png" />
              </IconButton>
            </Tooltip>

            <Tooltip
              TransitionComponent={Zoom}
              title="Dislike"
              arrow
              placement="top"
            >
              <IconButton
                id={`dislike_${section_}_${key_}`}
                className="dislike border-0 mx-1"
                onClick={handleDislike}
                style={{ backgroundColor: "white" }}
                disabled={
                  expertClinicalNotesData?.data?.submitted ||
                  expertClinicalNotesData?.editTemplate
                }
              >
                <img src="/assets/img/elements/mdi_thumbs-down.png" />
              </IconButton>
            </Tooltip>

            <Tooltip
              TransitionComponent={Zoom}
              title="Regenerate"
              arrow
              placement="top"
            >
              <IconButton
                id={`regenerate_${section_}_${key_}`}
                className="regenerate border-0 mx-1"
                onClick={handleRegenerate}
                style={{ backgroundColor: "white" }}
                disabled={
                  expertClinicalNotesData?.data?.submitted ||
                  expertClinicalNotesData?.editTemplate
                }
              >
                <img src="/assets/img/elements/tabler_refresh.png" />
              </IconButton>
            </Tooltip>
            <Tooltip
              TransitionComponent={Zoom}
              title="Clear"
              arrow
              placement="top"
            >
              <IconButton
                id={`cleared_${section_}_${key_}`}
                className="cleared border-0 mx-1"
                style={{ backgroundColor: "white" }}
                onClick={handleCross}
                disabled={
                  expertClinicalNotesData?.data?.submitted ||
                  expertClinicalNotesData?.editTemplate
                }
              >
                <img src="/assets/img/elements/carbon_close-filled.png" />
              </IconButton>
            </Tooltip>
          </div>
          {/* hidden for development */}
          {expertClinicalNotesData?.data?.[section_]?.[key_]?.show_feedback ===
            "true" && (
            <div className=" d-flex gap-3 flex-wrap border rounded-2 p-2">
              <p className="m-0">Did we do a better job this time?</p>
              <div className="d-flex align-items-center gap-2">
                <button
                  id={`better_${section_}_${key_}`}
                  style={{
                    backgroundColor: "var(--primary-accent-color)",
                    color: "var(--primary-color)",
                  }}
                  onClick={() =>
                    updateSectionState(section_, key_, "review", "better")
                  }
                  className="better border border-1 rounded-2 "
                  disabled={
                    expertClinicalNotesData?.data?.submitted ||
                    expertClinicalNotesData?.editTemplate
                  }
                >
                  <span className=" font-w500 p-1 px-2 ">Better</span>
                </button>
                <button
                  id={`worse_${section_}_${key_}`}
                  onClick={() =>
                    updateSectionState(section_, key_, "review", "worse")
                  }
                  className="worse border border-1 rounded-2 "
                  style={{
                    backgroundColor: "var(--primary-accent-color)",
                    color: "var(--primary-color)",
                  }}
                  disabled={
                    expertClinicalNotesData?.data?.submitted ||
                    expertClinicalNotesData?.editTemplate
                  }
                >
                  <span className=" font-w500  p-1 px-2">Worse</span>
                </button>
                <button
                  id={`same_${section_}_${key_}`}
                  onClick={() =>
                    updateSectionState(section_, key_, "review", "same")
                  }
                  disabled={
                    expertClinicalNotesData?.data?.submitted ||
                    expertClinicalNotesData?.editTemplate
                  }
                  className="same border border-1 rounded-2 "
                  style={{
                    backgroundColor: "var(--primary-accent-color)",
                    color: "var(--primary-color)",
                  }}
                >
                  <span className=" font-w500  p-1 px-2">Same</span>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CNReviewBar;
