import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

function TextEditor({
  initialText,
  hideToolbar,
  placeholder,
  htmlText,
  plainText,
  disabled,
}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    const currentHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    if (currentHtml === initialText) return;
    const listPattern = /(?:^|\n)(\s*\d+\.\s*|\s*-\s*)([^\n]*)/g;

    const modifiedText = initialText
      ? `<${initialText.startsWith("1.") ? "ol" : "ul"}>${initialText
          .replace(listPattern, (_, p1, p2) => `<li>${p2.trim()}</li>`)
          .trim()}</${initialText.startsWith("1.") ? "ol" : "ul"}>`
      : "";
    const blocksFromHtml = convertFromHTML(modifiedText);
    // const blocksFromHtml = convertFromHTML(`<p>${initialText || ""}</p>`);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
  }, [initialText]);

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);

    const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    if (typeof htmlText === "function") {
      htmlText(html);
    }
    const plainTextContent = newEditorState.getCurrentContent().getPlainText();
    if (typeof plainText === "function") {
      plainText(plainTextContent);
    }
  };

  return (
    <div
      style={{ background: "white", overflowY: "auto" }}
      className="border rounded-3 p-2"
    >
      <Editor
        editorStyle={{ height: placeholder? "150px" : "250px" }}
        placeholder={placeholder}
        editorState={editorState}
        readOnly={disabled}
        onEditorStateChange={handleEditorChange}
        toolbarHidden={hideToolbar}
        toolbar={{
          options: ["inline", "list"],
        }}
      />
    </div>
  );
}

export default TextEditor;
