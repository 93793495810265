import React, { useState, useContext, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Pagination from "@mui/material/Pagination";
import ClientDetailsViewMoreModal from "../modals/ClientDetailsViewMoreModal";
import Paper from "@mui/material/Paper";
import { InputBase, IconButton } from "@mui/material";
import MicOutlinedIcon from "@mui/icons-material/MicOutlined";
import { AppContext } from "../../../context";
import jwtDecode from "jwt-decode";

const NoteFromClinician = ({ title }) => {
  const { expertClinicalNotesData } =
    useContext(AppContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [historicalNotes, setHistoricalNotes] = useState([]);


  let token = localStorage.getItem("Authorization").split(" ")[1];

  const handleRowClick = (row) => {
    setSelectedRowData(row);
    setIsModalOpen(true);
  };

  const [page, setPage] = useState(1); // Assuming page numbering starts at 1
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const totalItems = historicalNotes?.length || 0;
  const totalPages = Math.ceil(totalItems / rowsPerPage);
  const startItemIndex = historicalNotes?.length > 0 ? (page - 1) * rowsPerPage + 1 : 0;
  const endItemIndex = Math.min(startItemIndex + rowsPerPage - 1, totalItems);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    // Format hours for 12-hour format
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "P.M." : "A.M.";
    hours = hours % 12;
    hours = hours ? hours.toString().padStart(2, "0") : "12"; // the hour '0' should be '12'

    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
  }

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_EXPERT_BASE_URL}jwt-clinicalnotes/list-past?client_id=${expertClinicalNotesData?.headerData?.user_id}&count=10&page=1&current_session_id=${expertClinicalNotesData?.headerData?.session_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: token,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const resp = await response.json();
        setHistoricalNotes(jwtDecode(resp.data));
      } catch (error) {
        console.log(error);
      }
    };
    fetchNotes();
  }, [expertClinicalNotesData?.headerData?.session_id, expertClinicalNotesData?.headerData?.user_id, token]);
  const handleBrokenImage = (imgLink) => {

    const imgLinkSplit = imgLink.split("https://");
    if (imgLinkSplit.length > 1) {
      const updatedLink = "https://" + imgLinkSplit[imgLinkSplit.length - 1];
      return updatedLink;
    } else {
      return "https://images.unitedwecare.com/odoo%2Fopen%2Fprofile-vector.webp"
    }
  }
  return (
    <div className="mt-2">
      <div className="d-flex justify-content-between align-items-center my-3 ">
        <h5 className="font-w700">{title}</h5>

      </div>
      <TableContainer
        component={Paper}
        style={{ borderRadius: "10px 10px 0px 0px" }}
      >
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          style={{ borderRadius: "10px 10px 0px 0px" }}
        >
          <TableHead>
            <TableRow
              style={{
                border: "1px solid #E6E6E9",
                background: "#F8F8F8",
              }}
            >
              <TableCell
                className="font-w600 font-inter lh-basex"
                style={{ fontSize: "18px" }}
              >
                Updated by
              </TableCell>
              <TableCell
                className="font-w600 font-inter lh-basex"
                style={{ fontSize: "18px" }}
              >
                Date
              </TableCell>
              <TableCell
                className="font-w600 font-inter lh-basex"
                style={{ fontSize: "18px" }}
              >
                Session Overview
              </TableCell>
              {/* <TableCell
                className="font-w600 font-inter lh-basex"
                style={{ fontSize: "18px" }}
              ></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {historicalNotes?.length > 0 ? (
              historicalNotes?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    className="font-w400 lh-base"
                    style={{ fontSize: "16px" }}
                  >
                    <img
                      src={row?.expert?.image}
                      onError={(e) => {
                        const newLink = handleBrokenImage(row?.expert?.image);
                        e.target.src = newLink
                      }}
                      width={24}
                      height={24}
                      className="rounded-circle"
                      alt=""
                    />
                    {row?.expert?.name}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="font-w400 lh-base"
                    style={{ fontSize: "16px" }}
                  >
                    {row?.session_date}
                  </TableCell>
                  <TableCell
                    className="font-w400 lh-base"
                    style={{ fontSize: "16px" }}
                  >
                    {row?.session_overview}
                  </TableCell>

                  {/* <TableCell>
                    <span
                      role="button"
                      onClick={() => handleRowClick(row)}
                      style={{ color: "#6C46A0", background: "#6C46A01A" }}
                      className="p-2 rounded-pill "
                    >
                      {" "}
                      View
                      <img
                        width={25}
                        className="ms-2"
                        src="/assets/img/elements/eye.png"
                        alt=""
                      />
                    </span>
                  </TableCell> */}
                  {/* <TableCell>
                    <ClientDetailsViewMoreModal />
                  </TableCell> */}
                </TableRow>
              ))
            ) : (

              <TableRow>
                <TableCell></TableCell>
                <TableCell><div className="mx-auto font-inter fw-semibold lh-lg text-center" style={{ fontSize: "18px" }}>No Historical Data Available.</div></TableCell>
                <TableCell></TableCell>
              </TableRow>

            )}
          </TableBody>
          {historicalNotes?.length > 0 && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={1000} className="p-2">
                  <div className="d-flex justify-content-between align-items-center w-100 p-2">
                    <p className="Table-Footer-p">
                      Showing {startItemIndex} to {endItemIndex} of {totalItems}
                    </p>
                    <Pagination
                      count={totalPages} // Use totalPages calculated from totalItems and rowsPerPage
                      page={page} // Use state variable 'page'
                      onChange={handleChangePage} // Make sure this handler is implemented
                      variant="outlined"
                      shape="rounded"
                      className="Pagination"
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>

      <ClientDetailsViewMoreModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        rowData={selectedRowData}
      />
    </div>
  );
};

export default NoteFromClinician;
