import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context";
import { expertTypeList } from "./ClinicalNotesAPI/DropdownData";

// Components
import RecommendationExpertAdd from "./Dialogs/RecommendationExpertAdd";
import RecommendationAssessmentAdd from "./Dialogs/RecommendationAssessmentAdd";
import RecommendContentAdd from "./Dialogs/RecommendContentAdd";
import RecommendationProgramAdd from "./Dialogs/RecommendationProgramAdd";
import HistoryExpert from "./Dialogs/HistoryExpert";
import HistoryProgram from "./Dialogs/HistoryProgram";
import HistoryAssessment from "./Dialogs/HistoryAssessments";
import HistoryContent from "./Dialogs/HistoryContent";
import RecommendationTabSkeleton from "./Skeletons/RecommendationTabSkeleton";
import RecommendExpertListView from "./Components/RecommendExpertListView";
import RecommendedProgramListView from "./Components/RecommendedProgramListView";
import RecommendedAssessmentListView from "./Components/RecommendedAssessmentListView";
import RecommendedContentListView from "./Components/RecommendedContentListView";

// APIs
import {
  FetchRecommendedAssessmentHistoryList,
  FetchRecommendedExpertHistoryList,
  FetchRecommendedHistoryAdviseList,
  FetchRecommendedHistoryContentList,
  FetchRecommendedProgramHistoryList,
} from "./ClinicalNotesAPI/ClinicalNoteAPIManager";

// MUI
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import jwtDecode from "jwt-decode";
import { InfoOutlined } from "@mui/icons-material";

function CNRecommendation() {
  const [expert, setExpert] = useState("");
  const [program, setProgram] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);

  useEffect(() => {
    if (!expertClinicalNotesData?.headerData?.user_id) {
      return;
    }

    const sessionId = expertClinicalNotesData?.headerData?.session_id;
    const fetchFunctions = [
      FetchRecommendedExpertHistoryList,
      FetchRecommendedProgramHistoryList,
      FetchRecommendedAssessmentHistoryList,
      FetchRecommendedHistoryContentList,
      FetchRecommendedHistoryAdviseList,
    ];

    Promise.all(fetchFunctions.map((fetchFn) => fetchFn(sessionId)))
      .then(
        ([
          expertHistory,
          programHistory,
          assessmentHistory,
          contentHistory,
          adviseHistory,
        ]) => {
          setExpertClinicalNotesData((prevData) => ({
            ...prevData,
            recommendationExpertHistory: jwtDecode(expertHistory),
            recommendationProgramHistory: jwtDecode(programHistory),
            recommendationAssesmentHistory: jwtDecode(assessmentHistory),
            recommendationContentHistory: jwtDecode(contentHistory),
            recommendationadviceHistory: jwtDecode(adviseHistory),
          }));
        }
      )
      .catch((err) => {
        console.error(err); // Optionally log the error internally here as well
      });
  }, [expertClinicalNotesData?.headerData?.user_id]);

  useEffect(() => {
    if ((expertClinicalNotesData?.headerData?.session_id || {}).length !== 0) {
      setLoading(false);
    }
  }, [expertClinicalNotesData?.headerData?.session_id]);

  const updateRecommendedData = (data, key, updateFn) => {
    setExpertClinicalNotesData((prev) => {
      // Initialize if the key does not exist
      if (!prev?.recommendation?.[key]) {
        prev = {
          ...prev,
          recommendation: {
            ...prev.recommendation,
            [key]: [],
          },
        };
      }

      const existingTitles = new Set(
        prev.recommendation[key].map(
          (item) => item.Name || item.name || item.title || item.data?.title
        )
      );

      const newItems = data.filter(
        (item) =>
          !existingTitles.has(
            item.Name || item.name || item.title || item.data?.title
          )
      );

      const updatedRecommendedData = updateFn(prev, newItems);
      return {
        ...prev,
        recommendation: {
          ...prev.recommendation,
          [key]: updatedRecommendedData,
        },
      };
    });
  };
  // console.log(expertClinicalNotesData);

  return (
    <div className="px-lg-4">
      {loading ? (
        <>
          {[
            "Recommend Expert",
            "Recommend Program",
            "Recommend Assessment",
            "Recommend Content",
          ].map((title, idx) => (
            <RecommendationTabSkeleton heading={title} key={idx} />
          ))}
        </>
      ) : (
        <>
          <div className="rounded-3 my-3  border">
            <div className="p-lg-4 p-2">
              <div className="d-flex justify-content-between gap-3">
                <p className="fw-bold ">
                  Recommend Expert{" "}
                  <Tooltip
                    arrow
                    title={
                      <p
                        className="m-0"
                        style={{ fontSize: "14px", color: "white" }}
                      >
                        Suggest a specialist clinician for your patient to see.
                        You can pick an expert of your desired specialisation
                        from our platform, or tell the patient to see one of
                        their choice.
                      </p>
                    }
                    placement="top-start"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "common.black",
                          "& .MuiTooltip-arrow": {
                            color: "common.black",
                          },
                          color: "common.white",
                          padding: "10px",
                        },
                      },
                    }}
                  >
                    <InfoOutlined sx={{ fontSize: "16px", color: "black" }}/>
                  </Tooltip>
                </p>{" "}
                <HistoryExpert
                  data={expertClinicalNotesData?.recommendationExpertHistory}
                />
              </div>
              {!expertClinicalNotesData?.data?.submitted && (
                <div className="d-flex mt-2 flex-column flex-lg-row align-items-lg-center gap-3">
                  <FormControl
                    sx={{ background: "white" }}
                    className="col-md-4"
                  >
                    <InputLabel id="demo-simple-select-label">
                      Type of expert
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={expert}
                      label="Type of expert"
                      onChange={(e) => setExpert(e.target.value)}
                      disabled={
                        expertClinicalNotesData?.data?.submitted ||
                        expertClinicalNotesData?.editTemplate
                      }
                    >
                      {expertTypeList?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <RecommendationExpertAdd
                    expertType={expert}
                    dataRecived={(data) => {
                      setExpert("");
                      updateRecommendedData(
                        data,
                        "recommended_expert",
                        (prev, newItems) => [
                          ...prev.recommendation.recommended_expert,
                          ...newItems,
                        ]
                      );
                    }}
                  />
                </div>
              )}
            </div>

            <RecommendExpertListView />
          </div>

          <div className="rounded-3 my-3  border">
            <div className="p-lg-4 p-2">
              <div className="d-flex  justify-content-between gap-3">
                <p className="fw-bold ">
                  Recommend Program{" "}
                  <Tooltip
                    arrow
                    title={
                      <p
                        className="m-0"
                        style={{ fontSize: "14px", color: "white" }}
                      >
                        Suggest one of our clinical programs to the patient.
                      </p>
                    }
                    placement="top-start"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "common.black",
                          "& .MuiTooltip-arrow": {
                            color: "common.black",
                          },
                          color: "common.white",
                          padding: "10px",
                        },
                      },
                    }}
                  >
                    <InfoOutlined sx={{ fontSize: "16px", color: "black" }}/>
                  </Tooltip>
                </p>

                <HistoryProgram
                  data={expertClinicalNotesData?.recommendationProgramHistory}
                />
              </div>
              {!expertClinicalNotesData?.data?.submitted && (
                <div className="d-flex mt-2 flex-column flex-lg-row align-items-lg-center gap-3">
                  <FormControl
                    sx={{ background: "white" }}
                    className="col-md-4"
                  >
                    <InputLabel id="demo-simple-select-label">
                      Type of Program
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={program}
                      label="Type of Program"
                      onChange={(e) => setProgram(e.target.value)}
                      disabled={
                        expertClinicalNotesData?.data?.submitted ||
                        expertClinicalNotesData?.editTemplate
                      }
                    >
                      <MenuItem value={44}>Relationship Issues</MenuItem>
                      <MenuItem value={36}>Mindfulness</MenuItem>
                      <MenuItem value={30}>Meditation/Dhyana</MenuItem>
                      <MenuItem value={52}>
                        Personality & Behavioural Issues
                      </MenuItem>
                      <MenuItem value={35}>Stress</MenuItem>
                      <MenuItem value={41}>Anxiety</MenuItem>
                      <MenuItem value={33}>Weight Management</MenuItem>
                      <MenuItem value={42}>Grief, Loss and PTSD</MenuItem>
                      <MenuItem value={49}>Addiction</MenuItem>
                      <MenuItem value={32}>Depression</MenuItem>
                      <MenuItem value={56}>Parenting</MenuItem>
                    </Select>
                  </FormControl>{" "}
                  <RecommendationProgramAdd
                    program={program}
                    expert_info={expertClinicalNotesData?.expert_info}
                    dataRecived={(data) => {
                      setProgram("");
                      updateRecommendedData(
                        data,
                        "recommended_program",
                        (prev, newItems) => [
                          ...prev.recommendation.recommended_program,
                          ...newItems,
                        ]
                      );
                    }}
                  />
                </div>
              )}
            </div>{" "}
            <RecommendedProgramListView />
          </div>

          <div className="rounded-3 my-3 border">
            <div className="d-flex  p-lg-4 p-2 justify-content-between gap-3">
              <p className="fw-bold ">
                Recommend Assessment{" "}
                <Tooltip
                  arrow
                  title={
                    <p
                      className="m-0"
                      style={{ fontSize: "14px", color: "white" }}
                    >
                      Assign one of our self-assessments to your patient.
                    </p>
                  }
                  placement="top-start"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "common.black",
                        "& .MuiTooltip-arrow": {
                          color: "common.black",
                        },
                        color: "common.white",
                        padding: "10px",
                      },
                    },
                  }}
                >
                  <InfoOutlined sx={{ fontSize: "16px", color: "black" }}/>
                </Tooltip>
              </p>

              <HistoryAssessment
                data={expertClinicalNotesData?.recommendationAssesmentHistory}
              />
            </div>
            {!expertClinicalNotesData?.data?.submitted && (
              <div className=" px-4 pb-4">
                <RecommendationAssessmentAdd
                  disabled={
                    expertClinicalNotesData?.data?.submitted ||
                    expertClinicalNotesData?.editTemplate
                  }
                  expert_info={expertClinicalNotesData?.expert_info}
                  dataRecived={(data) => {
                    updateRecommendedData(
                      data,
                      "recommended_assessment",
                      (prev, newItems) => [
                        ...prev.recommendation.recommended_assessment,
                        ...newItems,
                      ]
                    );
                  }}
                />
              </div>
            )}

            <RecommendedAssessmentListView />
          </div>

          <div className="rounded-3 p-lg-4 p-2 my-3 border">
            <div className="d-flex   justify-content-between gap-3">
              <p className="fw-bold ">
                Recommend Content{" "}
                <Tooltip
                  arrow
                  title={
                    <p
                      className="m-0"
                      style={{ fontSize: "14px", color: "white" }}
                    >
                      Show the patient some of our exclusive self-help content.
                    </p>
                  }
                  placement="top-start"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "common.black",
                        "& .MuiTooltip-arrow": {
                          color: "common.black",
                        },
                        color: "common.white",
                        padding: "10px",
                      },
                    },
                  }}
                >
                  {" "}
                  <InfoOutlined sx={{ fontSize: "16px", color: "black" }}/>{" "}
                </Tooltip>{" "}
              </p>

              <HistoryContent
                data={expertClinicalNotesData?.recommendationContentHistory}
              />
            </div>
            {!expertClinicalNotesData?.data?.submitted && (
              <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3">
                <FormControl className="col-md-4">
                  <InputLabel id="demo-simple-select-label">
                    Type of Content
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={content}
                    label="Type of Content"
                    onChange={(e) => setContent(e.target.value)}
                    disabled={
                      expertClinicalNotesData?.data?.submitted ||
                      expertClinicalNotesData?.editTemplate
                    }
                  >
                    <MenuItem value={"audio"}>Audio</MenuItem>
                    <MenuItem value={"video"}>Video</MenuItem>
                    <MenuItem value={"podcast"}>Podcast</MenuItem>
                    <MenuItem value={"blog"}>Blog</MenuItem>
                  </Select>
                </FormControl>
                <RecommendContentAdd
                  expert_info={expertClinicalNotesData?.expert_info}
                  contents={content}
                  dataRecived={(data) => {
                    setContent("");
                    updateRecommendedData(
                      data,
                      "recommended_content",
                      (prev, newItems) => [
                        ...prev.recommendation.recommended_content,
                        ...newItems,
                      ]
                    );
                  }}
                />
              </div>
            )}

            <RecommendedContentListView />
          </div>
        </>
      )}
    </div>
  );
}

export default CNRecommendation;
