import {
    ArchiveRounded,
    SaveRounded,
    SendRounded,
    VisibilityRounded,
    VisibilityOffRounded,
    UploadFileRounded,
    EditRounded,
    DownloadRounded,
    ShareRounded,
    RefreshRounded,
    AddRounded,
    AssignmentTurnedInRounded,
    AssignmentRounded,
    DraftsRounded,
    AddAlertRounded,
    BlockRounded,
    ThumbDownRounded,
    AutoAwesomeRounded,
    ClearRounded,
    CheckRounded,
    LocalPharmacyRounded,  // Icon for recommend medicine
    RemoveCircleRounded,  // Icon for remove medicine
    StarRounded  // Icon for recommended
} from "@mui/icons-material";

const iconStyle = {
    color: "var(--primary-color)", // The single color to be used for all icons
    fontSize: "1.5em" // Adjust this value to increase the thickness (size)
};

export const template = [
    {
        "name": "started",
        "path": <AddAlertRounded style={iconStyle} />
    },
    {
        "name": "visibility_changed",
        "path": <VisibilityRounded style={iconStyle} />
    },
    {
        "name": "uploaded",
        "path": <UploadFileRounded style={iconStyle} />
    },
    {
        "name": "field_changed",
        "path": <EditRounded style={iconStyle} />
    },
    {
        "name": "prescription_downloaded",
        "path": <DownloadRounded style={iconStyle} />
    },
    {
        "name": "prescription_shared",
        "path": <ShareRounded style={iconStyle} />
    },
    {
        "name": "alert_regenerated",
        "path": <RefreshRounded style={iconStyle} />
    },
    {
        "name": "new",
        "path": <AddRounded style={iconStyle} />
    },
    {
        "name": "alert_acknowledged",
        "path": <AssignmentTurnedInRounded style={iconStyle} />
    },
    {
        "name": "follow-up",
        "path": <AssignmentRounded style={iconStyle} />
    },
    {
        "name": "visible",
        "path": <VisibilityRounded style={iconStyle} />
    },
    {
        "name": "hidden",
        "path": <VisibilityOffRounded style={iconStyle} />
    },
    {
        "name": "draft",
        "path": <DraftsRounded style={iconStyle} />
    },
    {
        "name": "note_saved",
        "path": <SaveRounded style={iconStyle} />
    },
    {
        "name": "note_submitted",
        "path": <SendRounded style={iconStyle} />
    },
    {
        "name": "archived",
        "path": <ArchiveRounded style={iconStyle} />
    },
    {
        "name": "alert_ignored",
        "path": <BlockRounded style={iconStyle} />
    },
    {
        "name": "accepted",
        "path": <CheckRounded style={iconStyle} />  // Icon for accepted
    },
    {
        "name": "disliked",
        "path": <ThumbDownRounded style={iconStyle} />  // Icon for disliked
    },
    {
        "name": "regenerated",
        "path": <AutoAwesomeRounded style={iconStyle} />  // Icon for regenerated
    },
    {
        "name": "cleared",
        "path": <ClearRounded style={iconStyle} />  // Icon for cleared
    },
    {
        "name": "edited",
        "path": <EditRounded style={iconStyle} />  // Icon for edited (already imported)
    },
    {
        "name": "note_downloaded",
        "path": <DownloadRounded style={iconStyle} />  // Icon for note downloaded
    },
    {
        "name": "medication_added",
        "path": <LocalPharmacyRounded style={iconStyle} />  // Icon for recommend medicine
    },
    {
        "name": "medication_deleted",
        "path": <RemoveCircleRounded style={iconStyle} />  // Icon for remove medicine
    },
    {
        "name": "recommended",
        "path": <StarRounded style={iconStyle} />  // Icon for recommended
    }
];
