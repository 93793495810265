import React, { useContext, useEffect, useState } from "react";
import { generateRandomId } from "../../../core/commonFun";
import { AppContext } from "../../../../context";
import { useSnackbar } from "notistack";

// Components
import AddMedicationSkeleton from "../Skeletons/AddMedicationSkeleton";

// API
import {
  HandleBlurForAssistant,
  fetchRecommendedMedicines,
} from "../ClinicalNotesAPI/ClinicalNoteAPIManager";

// Dropdown data
import {
  dosageForm,
  frequencyList,
  routeList,
  takenWithList,
} from "../ClinicalNotesAPI/DropdownData";

// MUI
import {
  Autocomplete,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import MedicationAddDialog from "../Dialogs/MedicationAddDialog";

function PrescriptionMedicineUpdate() {
  const { expertClinicalNotesData, setExpertClinicalNotesData } =
    useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [recommendedMedicines, setRecommendedMedicines] = useState([]);
  const [sendData, setSendData] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState("");
  const [openDialog, setOpenDialog] = useState({ open: false, data: null });
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  useEffect(() => {
    if (
      Object.keys(expertClinicalNotesData?.data?.progress_note || {}).length > 0
    ) {
      setIsLoading(false);
    }
  }, [expertClinicalNotesData?.data?.progress_note]);
  useEffect(() => {
    if (
      !expertClinicalNotesData?.headerData?.session_id ||
      expertClinicalNotesData?.data?.submitted
    ) {
      return;
    }
    handleBlur();
  }, []);
  useEffect(() => {
    if (sendData) {
      const data = JSON.stringify({
        header: expertClinicalNotesData?.headerData,
        session_data: {
          ...expertClinicalNotesData?.data,
          progress_note: {
            ...expertClinicalNotesData?.data?.progress_note,
            medicines: {
              ...expertClinicalNotesData?.data?.progress_note?.medicines,
              prescribed_medicines:
                expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines?.filter(
                  (item) =>
                    item?.drug !== "" &&
                    item?.drug != null &&
                    item?.drug !== undefined
                ),
            },
          },
          lab_test: {
            ...expertClinicalNotesData?.data?.lab_test,
            prescribed_lab_test:
              expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.filter(
                (item) =>
                  item?.test_name !== "" &&
                  item?.test_name != null &&
                  item?.test_name !== undefined
              ),
          },
        },
      });
      expertClinicalNotesData?.socketRef?.current?.send(data);
      setExpertClinicalNotesData({
        ...expertClinicalNotesData,
        data: {
          ...expertClinicalNotesData?.data,
          progress_note: {
            ...expertClinicalNotesData?.data?.progress_note,
            medicines: {
              ...expertClinicalNotesData?.data?.progress_note?.medicines,
              prescribed_medicines:
                expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines?.filter(
                  (item) =>
                    item?.drug !== "" &&
                    item?.drug != null &&
                    item?.drug !== undefined
                ),
            },
          },
          lab_test: {
            ...expertClinicalNotesData?.data?.lab_test,
            prescribed_lab_test:
              expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test?.filter(
                (item) =>
                  item?.test_name !== "" &&
                  item?.test_name != null &&
                  item?.test_name !== undefined
              ),
          },
        },
      });
      setSendData(false);
    }
  }, [sendData]);
  useEffect(() => {
    if (!searchTerm) return;
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm && searchType === "medicine") {
      fetchRecommendedMedicines(debouncedSearchTerm)
        .then((response) => {
          if (response.status === "success") {
            setRecommendedMedicines(response?.data);
          } else {
            setRecommendedMedicines([]);
          }
        })
        .catch((err) => {
          console.error(err); // Optionally log the error internally here as well
        });
    }
  }, [debouncedSearchTerm]);

  const handleDeleteRow = (rowIndex, row) => {
    const updatedPrescribedMedicines =
      expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines?.filter(
        (_, index) => index !== rowIndex
      );
    setExpertClinicalNotesData((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        progress_note: {
          ...(prevData.data.progress_note || {}),
          medicines: {
            ...(prevData.data.progress_note.medicines || {}),
            prescribed_medicines: updatedPrescribedMedicines,
          },
        },
      },
    }));
    expertClinicalNotesData.socketRef.current.send(
      JSON.stringify({
        action_type: "medication_deleted",
        details: {
          medication_name: row?.drug,
        },
        user_id: expertClinicalNotesData?.headerData?.user_id,
        expert_id: expertClinicalNotesData?.headerData?.expert_id,
      })
    );
    setSendData(true);
  };

  const handleInputChange = (rowIndex, field, value, cims) => {
    setRecommendedMedicines([]);
    const updatedRows =
      expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines?.map(
        (row, index) =>
          index === rowIndex
            ? {
                ...row,
                [field]: value,
                cims: cims?.length > 0 ? cims?.split(",") : row?.cims,
              }
            : row
      );
    if (field === "drug") {
      setSearchTerm(value);
      setSearchType("medicine");
    }
    setExpertClinicalNotesData((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        progress_note: {
          ...(prevData.data.progress_note || {}),
          medicines: {
            ...(prevData.data.progress_note.medicines || {}),
            prescribed_medicines: updatedRows,
          },
        },
      },
    }));
  };
  const handleBlur = async () => {
    const payload = {
      ...expertClinicalNotesData?.headerData,
      assistant: expertClinicalNotesData?.data?.assistant,
      drugs:
        expertClinicalNotesData?.data?.progress_note?.medicines
          ?.prescribed_medicines,
      labtest: expertClinicalNotesData?.data?.lab_test?.prescribed_lab_test,
      disease: "",
    };

    HandleBlurForAssistant(payload)
      .then((res) => {
        if (res?.error) {
          enqueueSnackbar(res?.message, { variant: "info" });
          return;
        }
      })
      .catch((err) => {
        console.error(err); // Optionally log the error internally here as well
      });
    setSendData(true);
  };
  const handleAddRow = (newRow) => {
    setExpertClinicalNotesData((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        progress_note: {
          ...prevData.data.progress_note,
          medicines: {
            ...(prevData.data.progress_note.medicines || {}),
            prescribed_medicines: [
              ...(prevData.data.progress_note.medicines.prescribed_medicines ||
                []),
              newRow,
            ],
          },
        },
      },
    }));
    expertClinicalNotesData.socketRef.current.send(
      JSON.stringify({
        action_type: "medication_added",
        details: {
          medication_name: newRow?.drug,
        },
        user_id: expertClinicalNotesData?.headerData?.user_id,
        expert_id: expertClinicalNotesData?.headerData?.expert_id,
      })
    );
    setSendData(true);
  };
  const handleEditRow = (rowIndex, data) => {
    const updatedPrescribedMedicines =
      expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines?.map(
        (med, index) => (index === rowIndex - 1 ? (med = data) : med)
      );
    console.log(data);

    setExpertClinicalNotesData((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        progress_note: {
          ...(prevData.data.progress_note || {}),
          medicines: {
            ...(prevData.data.progress_note.medicines || {}),
            prescribed_medicines: updatedPrescribedMedicines,
          },
        },
      },
    }));
    setSendData(true);
  };
  return (
    <div style={{ overflowX: "auto" }} className="p-lg-2 ">
      {isLoading ? (
        <AddMedicationSkeleton />
      ) : (
        <div>
          {expertClinicalNotesData?.data?.progress_note?.medicines
            ?.prescribed_medicines?.length > 0 && (
            <TableContainer>
              <Table aria-label="medication table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sl.No</TableCell>
                    <TableCell>Medication Details</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {expertClinicalNotesData?.data?.progress_note?.medicines?.prescribed_medicines.map(
                    (row, index) => (
                      <TableRow hover key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              row?.cims?.length > 0 && (
                                <div className="d-flex flex-wrap gap-2">
                                  {row?.cims?.map((item, idx) => (
                                    <span
                                      key={idx}
                                      style={{
                                        fontSize: "12px",
                                        background: "#DFF5D6",
                                      }}
                                      className="rounded-pill px-2 py-1"
                                    >
                                      {item}
                                    </span>
                                  ))}
                                </div>
                              )
                            }
                            placement="top-start"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: "common.white",
                                  "& .MuiTooltip-arrow": {
                                    color: "common.white",
                                  },
                                  color: "common.black",
                                  padding: "10px",
                                },
                              },
                            }}
                          >
                            {`${row?.drug || ""} ${row?.strength || ""} ${
                              row?.dosage_form || ""
                            }, ${row?.quantity || ""} to be Taken ${
                              row?.route || ""
                            } ${row?.frequency || ""}${
                              row?.start_date ? ` from ${row?.start_date}` : ""
                            }${row?.end_date ? ` until ${row?.end_date}` : ""}${
                              row?.dispensation
                                ? `, Dispense ${row?.dispensation}`
                                : ""
                            }${
                              row?.refills
                                ? ` with ${row?.refills} Refills`
                                : ""
                            }${
                              row?.instructions ? `. ${row?.instructions}` : "."
                            }`}
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {!expertClinicalNotesData?.data?.submitted && (
                            <div className="d-flex align-items-center gap-1">
                              <IconButton
                                sx={{ minWidth: "max-content" }}
                                onClick={() =>
                                  setOpenDialog({
                                    open: true,
                                    data: row,
                                    index: index + 1,
                                  })
                                }
                                disabled={
                                  expertClinicalNotesData?.data?.submitted ||
                                  expertClinicalNotesData?.editTemplate
                                }
                              >
                                <Edit className="text-primary" />
                              </IconButton>
                              <IconButton
                                sx={{ minWidth: "max-content" }}
                                onClick={() => handleDeleteRow(index, row)}
                                disabled={
                                  expertClinicalNotesData?.data?.submitted ||
                                  expertClinicalNotesData?.editTemplate
                                }
                              >
                                <Delete className="text-danger" />
                              </IconButton>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <MedicationAddDialog
            openDialog={openDialog.open}
            closeDialog={() => setOpenDialog({ open: false, data: null })}
            handleData={(data) => handleAddRow(data)}
            label={"Add medication"}
            data={openDialog.data}
            index={openDialog?.index}
            handleEdit={(id, data) => handleEditRow(id, data)}
          />
          {!expertClinicalNotesData?.data?.submitted && (
            <Button
              onClick={() => setOpenDialog({ open: true, data: null })}
              sx={{ marginTop: "20px" }}
              className="PrimaryCTA"
              disabled={
                expertClinicalNotesData?.data?.submitted ||
                expertClinicalNotesData?.editTemplate
              }
              id="add_new_medication"
            >
              Add New Medicine
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default PrescriptionMedicineUpdate;
