import { Grid, Skeleton } from "@mui/material";
import React from "react";

function RecommendedAssessmentCardSkeleton() {
  return (
    <div>
      {" "}
      <Grid container spacing={2} className="mt-2  ">
        {Array.from(new Array(9))?.map((data, index) => (
          <Grid item xs={12} sm={6} className="  ">
            <div className={`d-flex flex-column h-100  rounded-3  p-3 `}>
              <div className="d-flex flex-row">
                <Skeleton
                  variant="rounded"
                  width="114px"
                  height="145px"
                  className="rounded-2"
                />
                <div className=" p-2 d-flex flex-column gap-1">
                  <Skeleton
                    variant="rounded"
                    width="78px"
                    height="14x"
                    className="rounded-3"
                  />
                  <Skeleton
                    variant="rounded"
                    width="78px"
                    height="14x"
                    className="rounded-3"
                  />

                  <Skeleton
                    variant="rounded"
                    width="150px"
                    height="14x"
                    className="rounded-3"
                  />
                  <Skeleton
                    variant="rounded"
                    width="150px"
                    height="14x"
                    className="rounded-3"
                  />
                  <Skeleton
                    variant="rounded"
                    width="150px"
                    height="14x"
                    className="rounded-3"
                  />
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-end">
                <Skeleton
                  variant="rounded"
                  width="24px"
                  height="24px"
                  className="rounded-2"
                />
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default RecommendedAssessmentCardSkeleton;
