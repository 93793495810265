import { Grid, Skeleton } from "@mui/material";
import React from "react";

function RecommendedContentCardSkeleton() {
  return (
    <div>
      {" "}
      <Grid container spacing={2} className="mt-4">
        {Array.from(new Array(6))?.map((data, index) => (
          <Grid key={index} item xs={12} sm={6} >
            <div className=" rounded-4 h-100">
              <div className=" rounded-4 position-relative">
                <Skeleton
                  variant="rounded"
                  height="180px"
                  className="rounded-4"
                />
              </div>
              <div className="p-3">
                <Skeleton
                  variant="rounded"
                  width="150px"
                  height="14x"
                  className="rounded-3"
                />
                <div className="d-flex align-items-center justify-content-between my-2">
                  <Skeleton
                    variant="rounded"
                    width="150px"
                    height="14x"
                    className="rounded-3"
                  />
                  <Skeleton
                    variant="rounded"
                    width="24px"
                    height="24px"
                    className="rounded-2"
                  />
                </div>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default RecommendedContentCardSkeleton;
